import { API } from './core/api-core.service'

export const sellerService = {
  /**
   * Get orders from a store between 2 dates
   * @param dateFrom
   * @param dateTo
   * @param stores
   */
  getAllSellers() {
    return API.request('get', 'sso/sellers')
  }
}
