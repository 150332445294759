<template>
  <!--<v-expansion-panel>
    <v-expansion-panel-content>
      <template v-slot:header>
        <h3>{{ $t('customs.topProductsBoard.topSoldProducts') }}</h3>
      </template>-->
  <v-card class="elevation-4 border-radius6">
    <v-card-title>
      <div class="title">{{ $t('customs.topProductsBoard.topSoldProducts') }}</div>
    </v-card-title>

    <!-- Top products board -->
    <v-card-text class="pa-0">
      <template>
        <v-data-table
          :headers="headers"
          :items="topProducts"
          :loading="loading"
          :footer-props="{
            'items-per-page-options': rowsPerPage,
            'items-per-page-text': $t('customs.salesBoard.rowsPerPage')
          }"
          class="elevation-0 table-striped border-radius6 top-products-table"
        >
          <template v-slot:item="{ item }">
            <!-- For each product line -->
            <tr>
              <td class="text-center">{{ item.barcode_product }}</td>
              <td class="text-center">{{ item.variant_id_product }}</td>
              <td class="text-center">
                {{ formatPrice(item.price, item.code_currency) }}
                <template v-if="item.old_price_product_no_discount_include_tax_sub_basket_line">
                  ({{
                    formatPrice(item.old_price_product_no_discount_include_tax_sub_basket_line, item.old_code_currency)
                  }})
                </template>
              </td>
              <td class="text-center">{{ item.title_product }}</td>
              <td class="text-center">{{ item.purchases_number }}</td>
            </tr>
          </template>
        </v-data-table>
      </template>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
  <!--</v-expansion-panel-content>
  </v-expansion-panel>-->
</template>

<script>
  import { Helpers } from '@/mixins/Helpers.js'

  const _ = require('lodash')

  export default {
    name: 'TopProductsList',
    props: {
      sales: Array,
      loading: Boolean
    },
    mixins: [Helpers],
    data() {
      return {
        topProducts: [],
        rowsPerPage: [10, 20, 30, 40, 50, { text: 'All', value: -1 }],
        headers: [
          {
            text: this.$t('customs.topProductsBoard.barcode'),
            value: 'barcode_product',
            sortable: false,
            align: 'center'
          },
          {
            text: this.$t('customs.topProductsBoard.variantId'),
            value: 'variant_id_product',
            sortable: false,
            align: 'center'
          },
          {
            text: this.$t('customs.topProductsBoard.price'),
            value: 'price',
            sortable: false,
            align: 'center'
          },
          {
            text: this.$t('customs.topProductsBoard.title'),
            value: 'title_product',
            sortable: false,
            align: 'center'
          },
          {
            text: this.$t('customs.topProductsBoard.purchasesNumber'),
            value: 'purchases_number',
            sortable: false,
            align: 'center'
          }
        ]
      }
    },
    watch: {
      sales(newSales) {
        let products = []
        let sortedProducts = []
        for (let sale of newSales) {
          if (sale.validate_sale) {
            for (let subBasket of sale.basket.subs_baskets) {
              for (let line of subBasket.sub_basket_lines) {
                if (line.product.variant_id_product !== this.$store.state.currentPartnerConfig.shippingCostsVariantId) {
                  let product = _.cloneDeep(line.product)
                  product.price = line.price_product_no_discount_include_tax_sub_basket_line
                  product.quantity_purchased = line.quantity_sub_basket_line
                  if (sale.basket.currency.hasOwnProperty('code_currency')) {
                    product.code_currency = sale.basket.currency.code_currency
                  } else {
                    product.code_currency = 'EUR'
                  }
                  if (line.old_price_product_no_discount_include_tax_sub_basket_line) {
                    product.old_price_product_no_discount_include_tax_sub_basket_line =
                      line.old_price_product_no_discount_include_tax_sub_basket_line
                    product.old_code_currency = sale.basket.currency.old_code_currency
                  }
                  products.push(product)
                }
              }
            }
          }
        }
        for (let p of products) {
          let productFound = false
          let currentProduct = {}
          for (let sp of sortedProducts) {
            if (sp.barcode_product === p.barcode_product && sp.variant_id_product === p.variant_id_product) {
              productFound = true
              sp.purchases_number += p.quantity_purchased
              break
            }
          }
          if (!productFound) {
            currentProduct = _.cloneDeep(p)
            currentProduct.purchases_number = p.quantity_purchased
            sortedProducts.push(currentProduct)
          }
        }
        sortedProducts.sort((a, b) => {
          return b.purchases_number - a.purchases_number
        })
        this.topProducts = _.cloneDeep(sortedProducts)
      }
    }
  }
</script>

<style>
  .top-products-table .v-table thead tr {
    height: 48px;
  }

  p {
    margin: 0 !important;
  }
</style>
