<template>
  <div>
    <v-card-text class="pa-0">
      <v-data-table
        :footer-props="{
          'items-per-page-options': rowsPerPage,
          'items-per-page-text': $t('customs.salesBoard.rowsPerPage')
        }"
        :headers="headers"
        :items="reportingList"
        :loading="loading"
        class="elevation-0 table-striped border-radius6 plain-order-table"
        item-key="id_reporting_mail"
      >
        <template v-slot:top>
          <v-dialog v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
              <v-row>
                <v-col class="text-right" cols="12">
                  <v-btn v-bind="attrs" v-on="on" class="mt-2 mb-2 mr-2" color="primary" dark>
                    {{ $t('customs.reporting.create') }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="formReporting">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.email"
                          :rules="emailRules"
                          label="Entrer une adresse email à ajouter"
                          prepend-icon="mdi-email"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="editedItem.id_store"
                          :hint="$t('customs.ssoUsers.usersTabModal.userStoresHint')"
                          :items="storeList"
                          :label="$t('customs.ssoUsers.usersTabModal.userStoresLabel')"
                          :no-data-text="$t('customs.ssoUsers.usersTabModal.noStoresAvailable')"
                          :rules="storeRules"
                          append-icon="arrow_drop_down"
                          chips
                          item-text="name_store"
                          item-value="id_store"
                          prepend-icon="store"
                          required
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> {{ $t('customs.reporting.close') }}</v-btn>
                <v-btn color="blue darken-1" text @click="save"> {{ $t('customs.reporting.save') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="headline">{{ $t('customs.reporting.delete') }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">{{ $t('customs.reporting.close') }}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
                  $t('customs.reporting.deleteBtn')
                }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)"> mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-snackbar v-if="snackbar.display" v-model="snackbar.display" :color="snackbar.type" bottom multi-line right>
      <v-icon class="mr-2" color="white">{{ snackbar.icon }}</v-icon>
      {{ snackbar.msg }}
      <v-btn icon @click="snackbar.display = false">
        <v-icon color="white">close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { reportingService } from '@/services/reporting.service'

  export default {
    name: 'ReportingList',
    props: {
      reportingList: Array,
      loading: Boolean
    },
    data() {
      return {
        emailRules: [
          v => !!v || this.$t('validation.email.required'),
          v =>
            /^$|(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || this.$t('validation.email.email')
        ],
        storeRules: [v => !!v || this.$t('validation.store.required')],
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          email: '',
          id_store: ''
        },
        defaultItem: {
          email: '',
          id_store: ''
        },
        rowsPerPage: [10, 20, 30, 40, 50, { text: 'All', value: -1 }],
        headers: [
          {
            text: this.$t('customs.reporting.email'),
            value: 'email',
            align: 'center'
          },
          {
            text: this.$t('customs.reporting.store'),
            value: 'store.name_store',
            align: 'center'
          },
          { text: this.$t('customs.reporting.actions'), value: 'actions', sortable: false }
        ],
        snackbar: {
          display: false,
          type: '',
          msg: '',
          icon: ''
        }
      }
    },
    computed: {
      formTitle() {
        return this.editedIndex === -1
          ? this.$t('customs.reporting.linkStoreToEmail')
          : this.$t('customs.reporting.update')
      },
      storeList() {
        let storeList = _.cloneDeep(this.$store.state.allStores)
        storeList.sort((a, b) => {
          if (a.name_store > b.name_store) {
            return 1
          }
          if (b.name_store > a.name_store) {
            return -1
          }
        })
        return storeList
      }
    },
    watch: {
      dialog(val) {
        val || this.close()
      },
      dialogDelete(val) {
        val || this.closeDelete()
      }
    },
    methods: {
      editItem(item) {
        this.editedIndex = this.reportingList.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem(item) {
        this.editedIndex = this.reportingList.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      close() {
        this.dialog = false
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.formReporting.reset()
      },
      closeDelete() {
        this.dialogDelete = false
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.formReporting.reset()
      },
      async save() {
        let valid = this.$refs.formReporting.validate()
        if (valid) {
          if (this.editedIndex === -1) {
            let newReporting = await reportingService.addReporting(this.editedItem)
            if (newReporting.status === 200) {
              // eslint-disable-next-line vue/no-mutating-props
              this.reportingList.push(newReporting.body)
              this.showSnackbar(true, 'success', this.$t('customs.reporting.globalSuccess.createSuccess'), 'success')
            } else {
              this.showSnackbar(true, 'error', this.$t('customs.reporting.globalErrors.createError'), 'error')
            }
            this.close()
          } else {
            const reporting = await reportingService.updateReporting(this.editedItem)
            if (reporting.status === 200) {
              this.showSnackbar(true, 'success', this.$t('customs.reporting.globalSuccess.updateSuccess'), 'success')
              Object.assign(this.reportingList[this.editedIndex], reporting.body)
            } else {
              this.showSnackbar(true, 'error', this.$t('customs.reporting.globalErrors.updateError'), 'error')
            }
            this.close()
          }
        }
      },
      async deleteItemConfirm() {
        const result = await reportingService.deleteReporting(this.editedItem.id_reporting_mail)
        if (result.status === 200) {
          this.showSnackbar(true, 'success', this.$t('customs.reporting.globalSuccess.deleteSuccess'), 'success')
          // eslint-disable-next-line vue/no-mutating-props
          this.reportingList.splice(this.editedIndex, 1)
        } else {
          this.showSnackbar(true, 'error', this.$t('customs.reporting.globalErrors.deleteError'), 'error')
        }
        this.closeDelete()
      },
      showSnackbar(display, type, msg, icon) {
        this.snackbar.display = display
        this.snackbar.type = type
        this.snackbar.msg = msg
        this.snackbar.icon = icon
      }
    }
  }
</script>

<style></style>
