<template>
  <v-container>
    <h3>{{ $t('customs.inventory.import_csv') }}</h3>
    <v-file-input v-model="file" label="Choisir un fichier" @change="sendCsv"></v-file-input>
  </v-container>
</template>

<script>
  export default {
    name: 'FileInput',
    data() {
      return {
        file: []
      }
    },
    methods: {
      sendCsv() {
        if (this.file.length > 0) {
          let reader = new FileReader()
          reader.onload = () => this.$emit('load', this.file[0])
          reader.readAsText(this.file[0], 'UTF-8')
        }
      }
    }
  }
</script>
