import { API } from './core/api-core.service'

export const customerService = {
  /**
   * Get configuration
   */
  async getAllCustomersCreations(params) {
    let query = {
      storeIds: params.selectedStores
    }
    if (params.dateRangeStart) {
      query.startDate = params.dateRangeStart
    }
    if (params.dateRangeEnd) {
      query.endDate = params.dateRangeEnd
    }
    if (params.previousPeriodStartDate) {
      query.startDate = params.previousPeriodStartDate
    }
    if (params.previousPeriodEndDate) {
      query.endDate = params.previousPeriodEndDate
    }
    if (params.selectedSellersIds && params.selectedSellersIds.length > 0) {
      query.sellerIds = params.selectedSellersIds
    }

    return await API.request('GET', 'sso/customers/creations', query)
  }
}
