import { API } from './core/api-core.service'
import store from '../store'

export const usersService = {
  /**
   * Call FusionAuth Server to request an access token to the application (login the user)
   * More information:
   * https://fusionauth.io/docs/v1/tech/apis
   *
   * @returns { json } return token and clientId
   */
  async requestToken(code) {
    return await API.request('POST', 'sso/oauth2/token', null, code)
  },

  async login(code) {
    await store.dispatch('resetPersistedState')
    let token = await this.requestToken({
      client_id: process.env.VUE_APP_SSO_APP_ID,
      code,
      grant_type: 'authorization_code',
      scope: 'offline_access',
      redirect_uri: process.env.VUE_APP_SSO_REDIRECT_URL
    })
    if (typeof token === 'object' || !JSON.parse(token).error) {
      await store.dispatch('setFusionAuthToken', token)
    } else {
      await this.logout()
    }
  },

  /**
   * Call FusionAuth Server to validate the current token
   * More information:
   * https://fusionauth.io/docs/v1/tech/apis
   *
   * @returns Object
   */
  async validateToken() {
    if (store.state.fusionAuthToken && store.state.fusionAuthToken.access_token) {
      let jwt =
        store.state.fusionAuthToken && store.state.fusionAuthToken.access_token
          ? `JWT ${store.state.fusionAuthToken.access_token}`
          : ''
      let tokenCheck
      try {
        tokenCheck = await API.request('GET', 'sso/jwt/validate', null, { jwt })
        if (tokenCheck.jwt.exp < Date.now() / 1000) {
          // Refresh token
          let refreshToken =
            store.state.fusionAuthToken && store.state.fusionAuthToken.refresh_token
              ? store.state.fusionAuthToken.refresh_token
              : null
          if (refreshToken) {
            let newToken = await this.requestToken({
              client_id: process.env.VUE_APP_SSO_APP_ID,
              grant_type: 'refresh_token',
              refresh_token: refreshToken,
              access_token: store.state.fusionAuthToken.access_token
            })
            if (typeof newToken === 'object' || !JSON.parse(newToken).error) {
              await store.dispatch('setFusionAuthToken', newToken)
            }
            this.validateToken()
          } else {
            await this.logout()
          }
        }
      } catch (e) {
        await this.logout()
      }
    } else {
      let url = new URL(window.location.href)
      if (url.searchParams.get('code')) {
        await this.login(url.searchParams.get('code'))
        window.location.replace(url.origin)
      } else {
        window.location.replace(
          process.env.VUE_APP_SSO_API_URL +
            '/oauth2/authorize?client_id=' +
            process.env.VUE_APP_SSO_APP_ID +
            '&scope=offline_access' +
            '&response_type=code&redirect_uri=' +
            encodeURIComponent(process.env.VUE_APP_SSO_REDIRECT_URL)
        )
      }
    }
  },

  /**
   * Call FusionAuth Server to logout a user
   * More information:
   * https://fusionauth.io/docs/v1/tech/apis
   *
   * @returns { json } return token and clientId
   */
  async logout() {
    await store.dispatch('resetPersistedState')
    document.cookie = 'JSESSIONID=; expires = Thu, 01 Jan 1970 00:00:00 GMT'
    document.cookie = 'fusionauth.locale=; expires = Thu, 01 Jan 1970 00:00:00 GMT'
    window.location.replace(
      process.env.VUE_APP_SSO_API_URL +
        '/oauth2/logout?client_id=' +
        process.env.VUE_APP_SSO_APP_ID +
        '&post_logout_redirect_uri=' +
        encodeURIComponent(process.env.VUE_APP_SSO_REDIRECT_URL)
    )
  },

  async logUser() {
    let user = await usersService.getUserById(store.state.fusionAuthToken.userId)

    for (let index = 0; index < user.user.registrations.length; index++) {
      if (user.user.registrations[index].applicationId === process.env.VUE_APP_SSO_APP_ID) {
        if (user.user.registrations[index].roles.includes('backoffice-admin')) {
          await store.dispatch('setIsAdmin', true)
          break
        } else if (user.user.registrations[index].roles.includes('octipas-admin')) {
          await store.dispatch('setIsAdmin', true)
          await store.dispatch('setIsOctipasAdmin', true)
          await store.dispatch('setUserHasMultiGroups', true)
          break
        }
      }
    }

    await store.dispatch('setCurrentUser', user.user)
  },

  /**
   * Call FusionAuth Server to get a single user
   * The list of user can only be users from the same group as the user who asked the request
   * More information:
   * https://fusionauth.io/docs/v1/tech/apis
   */
  async getUserById(id) {
    return await API.request('GET', `sso/user/${id}`)
  },

  async getUserByEmail(email) {
    return await API.request('GET', 'sso/user', `email=${email}`)
  },

  /**
   * Call FusionAuth Server to get a single group
   * More information:
   * https://fusionauth.io/docs/v1/tech/apis
   */
  async getGroupById(id) {
    return await API.request('GET', `sso/group/${id}`)
  },

  /**
   * Call FusionAuth Server to create a user
   * More information:
   * https://fusionauth.io/docs/v1/tech/apis
   */
  async createUser(user) {
    return await API.request('POST', 'sso/user/registration', null, user)
  },

  /**
   * Call FusionAuth Server to update a user
   * More information:
   * https://fusionauth.io/docs/v1/tech/apis
   */
  async updateUser(user) {
    let response1 = await this.updateRegistration(user)
    if (response1.hasOwnProperty('registration')) {
      return await API.request('PUT', `sso/user/${user.user.id}`, null, user)
    } else {
      return false
    }
  },

  async updateRegistration(user) {
    return await API.request('PUT', `sso/user/registration/${user.user.id}`, null, user)
  },

  /**
   * Call FusionAuth Server to delete a user
   * More information:
   * https://fusionauth.io/docs/v1/tech/apis
   */
  async deleteUserById(id) {
    return await API.request('DELETE', `sso/user/${id}`)
  },

  /**
   * Call FusionAuth Server to get a list of user
   * More information:
   * https://fusionauth.io/docs/v1/tech/apis
   */
  async getUserList(groupId) {
    return await API.request('GET', `sso/user/search`, { groupId })
  }
}
