const customs = {
  _loader: {
    waitingText: 'Veuillez patienter quelques instants'
  },
  menu: {
    homepage: "Page d'accueil",
    dashboard: 'Tableau de bord',
    administration: 'Administration des utilisateurs',
    inventory: 'Inventaire',
    valorization: 'Règles de valorisation de panier',
    reporting: 'Reporting',
    releaseNotes: 'Notes de version',
    groups: 'Groupes',
    validate: 'Valider',
    actualize: 'Actualiser',
    close: 'Fermer',
    errorOccurred: 'Une erreur est survenue',
    moreFilters: '+ De filtres',
    lessFilters: '- De filtres',
    dayD: 'J',
    weekW: 'S',
    monthM: 'M',
    yearY: 'A',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    convertSales: 'Convertir',
    status: 'Statut',
    currency: 'Récupérer par devises',
    country: 'Pays'
  },
  toolbar: {
    expand: 'Agrandir',
    collapse: 'Réduire',
    profil: 'Profil',
    signOut: 'Se déconnecter',
    changeGroup: 'Changer de groupe'
  },
  login: {
    signInToAccount: 'Connectez-vous à votre compte',
    login: 'Se connecter',
    pleaseEnterEmail: 'Veuillez entrer votre email',
    pleaseEnterPassword: 'Veuillez entrer votre mot de passe',
    emailMustBeValid: "L'email doit être valide",
    passwordTooShort: 'Le mot de passe doit faire 4 caractères ou plus',
    email: 'Email',
    password: 'Mot de passe'
  },
  daterange: {
    startLabel: 'Date de début',
    endLabel: 'Date de fin',
    separatorLabel: "jusqu'au"
  },
  dashboard: {
    stores: 'Magasins',
    sellers: 'Vendeurs',
    selectAll: 'Tout sélectionner',
    totalSales: 'Prix total des ventes',
    totalSalesDetails: 'Détails du prix total des ventes',
    totalSalesNumber: 'Nombre total des ventes',
    transferredSalesNumber: 'Nombre de ventes transférées en caisse',
    averageBasket: 'Panier moyen',
    totalSoldProducts: 'Total des produits vendus',
    distributionOriginSales: "Répartition de l'origine des transactions",
    distributionTypeSales: 'Répartition du type des transactions',
    productsPerSaleAvg: 'Produits par vente en moyenne',
    previousPeriod: 'Période précédente :',
    vsPreviousPeriod: 'VS période précédente',
    unavailableDataForPreviousPeriod: 'Données indisponibles pour la période précédente',
    unavailableData: 'Données indisponibles',
    revenues: 'Revenus',
    tablet: 'Tablette',
    checkout_counter: 'Caisse',
    nbTransactions: '{count} transaction | {count} transactions',
    orders: 'Commandes',
    takeawaySales: 'Ventes à emporter',
    mixedSales: 'Ventes mixtes',
    typeInToFindSeller: 'Écrivez ici pour trouver un vendeur',
    customersCreations: 'Nombre de clients créés',
    allSales: 'Toutes les ventes',
    salesBySeller: 'Ventes par vendeur',
    salesByStore: 'Ventes par magasin',
    day: 'Jour',
    week: 'Semaine',
    month: 'Mois',
    year: 'Année',
    scale: 'Echelle',
    noStoresAssigned:
      "Aucun magasin n'est associé à votre compte, veuillez vous rendre sur la page d'administration des utilisateurs afin d'associer des magasins à votre compte ou veuillez demander à votre administrateur de vous en associer."
  },
  inventory: {
    create_inventory: 'Créer un inventaire',
    inventory_in_progess: 'Inventaire en cours',
    history: 'Historique',
    stat: 'Statistiques',
    choose_store: 'Choisissez le(s) magasin(s)',
    choose_date: 'Choisissez la date',
    name_inventory: 'Nom',
    search_product: 'Rechercher un produit',
    select_category: 'Sélection des catégories',
    store_selected: 'Magasin(s) sélectionné(s)',
    import_csv: 'Importer un csv',
    loading_catolog: 'Chargement du catalogue',
    select_product_category: 'Sélectionnez des produits ou catégories',
    sku: 'SKU',
    next: 'Suivant',
    previous: 'Précédent',
    category: 'Catégorie',
    category_selected: 'Catégorie(s) et/ou produit(s) sélectionné(s)'
  },
  reporting: {
    email: 'Email',
    store: 'Magasin',
    actions: 'Actions',
    create: 'Créer',
    close: 'Annuler',
    save: 'Sauvegarder',
    linkStoreToEmail: 'Lier un magasin à un email',
    update: 'Mettre à jour un reporting',
    incorrectEmail: 'Adresse mail incorrect',
    delete: 'Êtes-vous sûr de vouloir supprimer ce reporting ?',
    deleteBtn: 'Supprimer',
    sendReportingInfo:
      'Envoyer un reporting à une ou plusieurs adresses mail en sélectionnant une période et un ou plusieurs magasins.',
    globalErrors: {
      createError: 'Une erreur est survenue à la création',
      updateError: 'Une erreur est survenue pendant la mise à jour',
      deleteError: 'Une erreur est survenue pendant la suppression'
    },
    globalSuccess: {
      createSuccess: 'Reporting créé avec succès',
      updateSuccess: 'Reporting modifié avec succès',
      deleteSuccess: 'Reporting supprimé avec succès'
    }
  },
  salesBoard: {
    sales: 'Ventes',
    searchByRef: 'Chercher par référence',
    searchByStatus: 'Chercher par statut',
    searchByPrice: 'Chercher par prix',
    searchByDate: 'Chercher par date',
    searchBySeller: 'Chercher par vendeur',
    searchByStore: 'Chercher par magasin',
    filters: 'Filtres',
    ref: 'Référence',
    refOcti: 'Référence Octipas',
    status: 'Statut',
    price: 'Prix',
    totalPrice: 'Prix total',
    takeawayPrice: 'Prix emporté',
    orderPrice: 'Prix commandé',
    date: 'Date',
    store: 'Magasin',
    seller: 'Vendeur',
    allStatus: 'Tous',
    notFinishedStatus: 'Non validée',
    validatedStatus: 'Validée',
    rowsPerPage: 'Lignes par page',
    name: 'Nom',
    firstname: 'Prénom',
    paymentOrigin: 'Origine du paiement',
    sellerName: 'Nom du vendeur',
    currency: 'Devise',
    totalPriceWithoutTaxWithoutDiscount: 'Prix total sans taxe/sans promo',
    totalPriceWithoutTaxWithDiscount: 'Prix total sans taxe/avec promo',
    totalPriceWithTaxWithoutDiscount: 'Prix total avec taxe/sans promo',
    totalPriceWithTaxWithDiscount: 'Prix total avec taxe/avec promo',
    deliveryType: 'Type de livraison',
    quantity: 'Quantité',
    productBarcode: 'Code barre du produit',
    productName: 'Nom du produit',
    productVariantId: 'ID variant du produit',
    productPrice: 'Prix produit',
    order: 'Commande',
    takeaway: 'À emporter',
    ship_from_store: 'Expédié par le magasin',
    subBasket: 'Sous-panier',
    customerName: 'Nom du client',
    customerId: 'ID du client',
    ok: 'Ok',
    cancel: 'Annuler',
    exportAsCSV: 'Exporter au format CSV',
    noDataToExportAsCSV: 'Pas de données à exporter',
    deviceName: "Nom de l'appareil utilisé pour la vente",
    deviceSerial: "Numéro de série de l'appareil utilisé pour la vente",
    salesNumber: 'Nombre de ventes',
    averageBasket: 'Panier moyen',
    totalSoldProducts: 'Nombre total de produits vendus',
    samples: 'Échantillons',
    paymentTypeTable: 'Différents types de paiement',
    paymentType: 'Type de paiement',
    referencePayment: 'Référence du paiement',
    paymentAmount: 'Montant'
  },
  topProductsBoard: {
    topSoldProducts: 'Top des produits vendus',
    barcode: 'Code barre',
    price: 'Prix',
    imageLink: "Lien de l'image",
    size: 'Taille',
    title: 'Titre',
    variantId: 'ID variant',
    purchasesNumber: "Nombre d'achats"
  },
  scannedProducts: {
    barcode: 'Code barre',
    scanNumber: 'Nombre de scans',
    scannedProducts: 'Produits scannés',
    scannedProductsPerSeller: 'Produits scannés par vendeur',
    scannedProductsPerStore: 'Produits scannés par magasin',
    purchasedScannedProducts: 'Produits scannés achetés',
    store: 'Magasin',
    seller: 'Vendeur'
  },
  originPayment: {
    tablet: 'Tablette vendeur',
    checkout_counter: 'Caisse'
  },
  snackbarMessages: {
    youDidntChooseClient: "Vous n'avez pas choisi de client",
    errorOccurred: 'Une erreur est survenue',
    notConnectedToSSO: 'Veuillez vous reconnecter'
  },
  // SSO Administration Users
  ssoUsers: {
    titles: {
      titleNavbar: 'Utilisateurs',
      titleTabs: {
        usersTab: 'Utilisateurs',
        groupsTab: 'Groupes',
        rolesTab: 'Rôles'
      }
    },
    globalErrors: {
      serverNotResponding: 'La connexion a échoué, veuillez réessayer plus tard',
      userModifiedUnknownStore:
        "La liste des magasins d'un utilisateur a été modifiée car un ou plusieurs magasins sont inconnus"
    },
    usersTab: {
      description: 'Créer, mettre à jour, gérer ou supprimer les utilisateurs',
      searchPlaceholder: 'Rechercher',
      createUserBtn: 'Créer un utilisateur',
      noStoresAsigned: 'Aucun magasin assigné',
      noGroupsAsigned: 'Aucun groupe assigné',
      noRolesAsigned: 'Aucun role assigné',
      others: 'autres',
      userTable: {
        name: 'Nom complet',
        username: "Nom d'utilisateur",
        email: 'Email',
        store: 'Magasins',
        groups: 'Groupe',
        roles: 'Rôle',
        active: 'Actif',
        actions: 'Actions'
      },
      snackbar: {
        userCreated: "L'utilisateur a bien été créé",
        userUpdated: "L'utilisateur a bien été mis à jour",
        userAlreadyExist: 'Cet email est déjà utilisée',
        userCreatedErrorGroup:
          "L'utilisateur a été créé mais le(s) groupe(s) n'a pas pu être assigné à cause d'une erreur interne",
        userDeleted: "L'utilisateur a bien été supprimé",
        error: 'Une erreur est survenue'
      }
    },
    usersTabModal: {
      firstNameLabel: 'Prénom',
      firstNameValidationMsg: 'Un prénom est obligatoire',
      lastNameLabel: 'Nom',
      lastNameValidationMsg: 'Un nom est obligatoire',
      usernameLabel: "Nom d'utilisateur",
      usernameValidationMsg: "Un nom d'utilisateur est obligatoire",
      emailLabel: 'Email',
      emailValidationMsg: 'Un email est obligatoire',
      passwdLabel: 'Mot de passe',
      passwdHint: 'au moins 8 caractères',
      passwdValidationMsg: 'Un mot de passe est obligatoire',
      passwdNotEnoughCarac: 'Pas assez de caractères',
      passwdConfirmLabel: 'Confirmation mot de passe',
      passwdConfirmHint: 'au moins 8 caractères',
      passwdConfirmValidationMsg: 'Un mot de passe est obligatoire',
      passwdConfirmNotEnoughCarac: 'Pas assez de caractères',
      passwdConfirmNotSamePasswd: 'Les mots de passe de correspondent pas',
      userStoresLabel: 'Magasins',
      userStoresHint: 'Sélectionner un ou plusieurs magasins',
      noStoresAvailable: 'Aucun magasin disponible',
      userStoresPlaceholder: 'Choisissez un ou plusieurs magasins',
      userStoresSelectAll: 'Sélectionner tous les magasins',
      userStoresRemoveAll: 'Désélectionner tous les magasins',
      adminCheckboxLabel: 'Rôle Administrateur',
      adminCheckboxTooltip:
        'Un administrateur peut ajouter ou supprimer des utilisateurs et leur attribués des magasins',
      incorrectInput: 'Le format est incorrect',
      groupsLabel: 'Groupes',
      groupsHint: 'Sélectionner un ou plusieurs groupes',
      noGroupAvailable: 'Aucun groupe disponible',
      rolesLabel: 'Rôles',
      rolesHint: 'Sélectionner un ou plusieurs rôles',
      noRoleAvailable: 'Aucun role disponible',
      deleteUserBtn: "Supprimer l'utilisateur",
      createUserBtn: 'Confirmer',
      cancelUserBtn: 'Annuler',
      confirmDeleteModal: {
        modalTitle: 'Êtes-vous sûr de vouloir supprimer cet utilisateur?',
        modalText: 'Il sera définitivement supprimé',
        cancelBtn: 'Annuler',
        confirmBtn: 'Supprimer'
      }
    },
    groupsTab: {
      description: 'Créer, mettre à jour ou supprimer les groupes',
      searchPlaceholder: 'Rechercher',
      createGroupBtn: 'Créer un groupe',
      groupTable: {
        name: 'Nom',
        actions: 'Actions'
      },
      snackbar: {
        groupCreated: 'Le groupe a bien été créé',
        groupDeleted: 'Le groupe a bien été suprimmé',
        groupUpdated: 'Le groupe a bien été mis à jour',
        error: 'Une erreur est survenue'
      }
    },
    groupsTabModal: {
      groupNameLabel: 'Nom du groupe',
      groupNameValidationMsg: 'Un nom de groupe est obligatoire',
      groupDescription: 'Description du groupe',
      NoGroupDescription: 'Aucune description',
      incorrectInput: 'Le format est incorrect',
      deleteGroupBtn: 'Supprimer le groupe',
      createGroupBtn: 'Confirmer',
      cancelGroupBtn: 'Annuler',
      confirmDeleteModal: {
        modalTitle: 'Êtes-vous sûr de vouloir supprimer ce groupe ?',
        modalText: 'Il sera supprimé définitivement',
        cancelBtn: 'Annuler',
        confirmBtn: 'Supprimer'
      }
    },
    rolesTab: {
      description: 'Créer, mettre à jour ou supprimer les rôles',
      searchPlaceholder: 'Rechercher',
      createRoleBtn: 'Créer un nouveau rôle',
      roleTable: {
        name: 'Nom du rôle',
        actions: 'Actions'
      },
      snackbar: {
        roleCreated: 'Le role a bien été créé',
        roleDeleted: 'Le role a bien été suprimmé',
        roleUpdated: 'Le role a bien été mis à jour',
        error: 'Une erreur est survenue'
      }
    },
    rolesTabModal: {
      roleNameLabel: 'Nom du rôle',
      roleNameValidationMsg: 'Un nom du rôle est obligatoire',
      roleDescription: 'Description du rôle',
      NoRoleDescription: 'Aucune description',
      incorrectInput: 'Le format est incorrect',
      toolTipText: "Il est impossible de changer le nom d'un rôle déjà créé",
      deleteRoleBtn: 'Supprimer le rôle',
      createRoleBtn: 'Confirmer',
      cancelRoleBtn: 'Annuler',
      confirmDeleteModal: {
        modalTitle: 'Êtes-vous sûr de vouloir supprimer ce rôle?',
        modalText: 'Il sera supprimé définitivement',
        cancelBtn: 'Annuler',
        confirmBtn: 'Confirmer'
      },
      roleCreateModal: {
        modalTitle: 'Attention',
        modalText: 'Une fois créé, le nom du rôle ne pourra pas être changer'
      }
    }
  },
  valorization: {
    basketRules: 'Règles de valorisation de panier',
    addRule: 'Ajouter une règle',
    deleteRuleQuestion: 'Êtes-vous sûr de vouloir supprimer cette règle ?',
    cancel: 'Annuler',
    ok: 'Ok',
    disabled: 'Désactivée',
    enabled: 'Activée',
    noData: 'Aucune règles disponibles',
    name: 'Nom',
    status: 'Statut',
    actions: 'Actions',
    general: 'Général',
    ruleName: 'Nom de la règle',
    rulePriority: 'Priorité de la règle',
    conditions: 'Conditions',
    ruleCoupon: 'Coupon (Optionnel)',
    ruleMaxUseCustomer: "Nombre d'utilisation max par utilisateur",
    ruleFromDate: "Date de début d'activation",
    ruleToDate: "Date de fin d'activation",
    ruleConditionType: 'Type de condition',
    ruleProductsInList: 'Produits dans la liste',
    ruleMinAmount: 'Montant minimum (produits dans la liste)',
    ruleBasketMinAmount: 'Montant minimum du panier',
    specificStores: 'Magasins spécifiques',
    loyaltyPoints: 'Points de fidélité',
    basketAmount: 'Montant du panier',
    minProductsInList: "Minimum d'articles dans la liste",
    ruleSkusList: 'SKUs séparés par des virgules (vide pour tous)',
    or: 'ou',
    fileSelected: 'Fichier sélectionné',
    selectFile: 'Sélectionner un fichier',
    specificStoresInput: "Liste d'ids magasins séparés par des virgules",
    ruleAddCondition: 'Ajouter une condition',
    effects: 'Effets',
    rulePercentDiscountProduct: 'Pourcentage de remise sur les produits concernés',
    ruleFixDiscountProduct: 'Remise sur les produits concernés',
    ruleDiscountPercentProductPrice: 'Remise en pourcentage du prix du produit',
    ruleDiscountFixedAmountBasket: "Remise d'un montant fixe pour l'ensemble du panier",
    ruleBuyXProductGetTheCheapestAtYPercent: 'Achetez X produits, obtenir le moins cher à Y% de réduction',
    ruleBuyXProductsGetSpecificProductFree: 'Achetez X produits, obtenir un produit Y gratuit',
    ruleBuyXProductsGetFreeProductInList: 'Achetez X produits, obtenir un produit gratuit dans une liste',
    discount: 'Remise',
    ruleQtyForDiscount: 'Quantité pour obtenir la remise (Achetez-en X)',
    ruleMaxProductDiscountCount: 'Nombre maximum de produits remisés',
    applyProductsRulesLabel: 'Appliquer aux produits respectant les conditions suivantes:',
    productsOption: 'Option du produit',
    productInfo: 'Information du produit',
    key: 'Clé',
    value: 'Valeur',
    true: 'Vrai',
    false: 'Faux',
    ruleBasketDiscount: 'Remise sur le panier',
    ruleBasketPercentDiscount: 'Pourcentage de remise sur le panier',
    ruleMaxDiscount: 'Remise maximum (Optionnel)',
    seeDetails: 'Voir le détail',
    ruleInvalidRuleName: 'Nom de règle invalide',
    points: 'Points',
    submit: 'Envoyer',
    infoRulesApplyOnStagingAndProd:
      'Les règles de valorisation de panier présentent ci-dessous ou que vous allez créer sont/seront utilisables sur les environnements de staging ET de production.'
  },
  releaseNote: {
    title: 'Mise à jour',
    noReleaseNote: 'Aucun mise à jour disponible'
  },
  devices: {
    totalConnectedDevices: "Nombre total d'appareils connectés"
  }
}

export default customs
