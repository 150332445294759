<template>
  <v-card>
    <v-card-title>
      <div class="text-h6 mb-2">{{ $t('customs.dashboard.distributionTypeSales') }}</div>
    </v-card-title>
    <v-divider />
    <v-card-text v-if="dataToDisplay.length > 0">
      <pie-chart class="type-sales-chart" :options="options" :data="salesTypeData" />
    </v-card-text>
    <v-card-text v-else>
      <v-row align="center" justify="center">
        <div class="text-h6">{{ $t('customs.dashboard.unavailableData') }}</div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import PieChart from '@/components/ChartJs/PieChart'

  export default {
    name: 'SalesTypeIndicator',
    components: {
      PieChart
    },
    props: {
      sales: Array
    },
    computed: {
      options() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'right'
          },
          tooltips: {
            enabled: true,
            callbacks: {
              label: (tooltipItems, data) => {
                return (
                  data.labels[tooltipItems.index] +
                  ' : ' +
                  this.$tc(
                    'customs.dashboard.nbTransactions',
                    data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
                  )
                )
              }
            }
          }
        }
      },
      salesTypeData() {
        return {
          labels: this.labelsToDisplay,
          datasets: [
            {
              data: this.dataToDisplay,
              borderWidth: 0,
              backgroundColor: ['#cca256', '#e9bd70', '#ffd98a']
            }
          ]
        }
      },
      chartData() {
        let data = {}
        for (let sale of this.sales) {
          let isOrder = false
          let isTakeaway = false
          for (let subBasket of sale.basket.subs_baskets) {
            if (subBasket.types_delivery.name_type_delivery === 'order') {
              isOrder = true
            } else if (subBasket.types_delivery.name_type_delivery === 'takeaway') {
              isTakeaway = true
            }
          }
          if (isOrder && !isTakeaway) {
            if (data.hasOwnProperty(this.$t('customs.dashboard.orders'))) {
              data[this.$t('customs.dashboard.orders')]++
            } else {
              data[this.$t('customs.dashboard.orders')] = 1
            }
          } else if (!isOrder && isTakeaway) {
            if (data.hasOwnProperty(this.$t('customs.dashboard.takeawaySales'))) {
              data[this.$t('customs.dashboard.takeawaySales')]++
            } else {
              data[this.$t('customs.dashboard.takeawaySales')] = 1
            }
          } else if (isOrder && isTakeaway) {
            if (data.hasOwnProperty(this.$t('customs.dashboard.mixedSales'))) {
              data[this.$t('customs.dashboard.mixedSales')]++
            } else {
              data[this.$t('customs.dashboard.mixedSales')] = 1
            }
          }
        }
        return data
      },
      labelsToDisplay() {
        let labels = []
        for (let key in this.chartData) {
          labels.push(key)
        }
        return labels
      },
      dataToDisplay() {
        let data = []
        for (let key in this.chartData) {
          data.push(this.chartData[key])
        }
        return data
      }
    }
  }
</script>

<style scoped>
  .type-sales-chart {
    height: 25vh !important;
  }
</style>
