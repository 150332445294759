<template>
  <v-container mt-10 class="createInventoryContent">
    <v-row>
      <v-col class="titleCreateInventory">
        <h1>{{ $t('customs.inventory.create_inventory') }}</h1>
      </v-col>
    </v-row>

    <v-container v-show="part1">
      <v-container>
        <v-row justify="center">
          <v-col lg="4">
            <v-container class="inputNameInventory">
              <h3>{{ $t('customs.inventory.name_inventory') }}*</h3>
              <v-text-field label="Nom de l'inventaire" v-model="inventoryName"></v-text-field>
            </v-container>
          </v-col>

          <v-col lg="4">
            <h3 class="h3_date">{{ $t('customs.inventory.choose_date') }}*</h3>

            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field v-model="date" label="Date" prepend-icon="event" readonly v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">{{ $t('customs.salesBoard.cancel') }} </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">{{ $t('customs.salesBoard.ok') }}</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row justify="center">
          <v-col lg="4" md="6" sm="12" xs="12">
            <h3>{{ $t('customs.inventory.choose_store') }}*</h3>
            <v-expansion-panels>
              <v-expansion-panel v-for="country in storeListCountry" :key="country.country">
                <v-expansion-panel-header> {{ country.country }} </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels class="cityExpansionPanel">
                    <v-expansion-panel v-for="cityStore in country.store" :key="cityStore.city">
                      <v-expansion-panel-header> {{ cityStore.city }} </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-container class="storeCheckbox" v-for="store in cityStore.store" :key="store.id">
                          <input v-model="storeSelected" type="checkbox" :id="store.id" :value="store" />
                          {{ store.label }}
                        </v-container>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>

          <v-col lg="4" md="6" sm="12" xs="12">
            <span> {{ $t('customs.inventory.store_selected') }}: </span> <br />
            <v-container v-for="store in storeSelected" :key="store.id">
              <span> {{ store.label }} </span> <br />
            </v-container>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row justify="center">
          <v-col lg="8">
            <FileInput v-if="part1" @load="csv = $event"></FileInput>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container class="buttonsContent">
      <v-row justify="center">
        <v-col>
          <button class="suivant" v-if="part1" @click="part1 = false">{{ $t('customs.inventory.next') }}</button>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="buttonsContent">
      <v-row justify="center">
        <v-col>
          <button class="precedent" v-if="!part1" @click="part1 = true">{{ $t('customs.inventory.previous') }}</button>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-show="!part1">
      <v-container class="loading" v-if="loading">
        <v-row justify="center">
          <v-progress-circular indeterminate color="primary">
            {{ $t('customs.inventory.loading_catolog') }}</v-progress-circular
          >
        </v-row>
      </v-container>

      <v-container v-else>
        <v-container>
          <v-row justify="center">
            <v-col lg="10">
              <h3>{{ $t('customs.inventory.select_product_category') }}</h3>
              <v-text-field
                label="Recherche Produit"
                placeholder="Nom/sku"
                outline
                v-model="search"
                @input="fillResult"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-container v-if="search.length >= 3">
          <v-row justify="center">
            <v-col v-for="product in listResult" :key="product.id" lg="3" md="4" sm="6" cols="12">
              <ProductCard :product="product" @checked="addProduct" @unchecked="delProduct"></ProductCard>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row justify="center">
            <v-col lg="5">
              <v-container>
                <h3>{{ $t('customs.inventory.select_category') }}</h3>
                <v-container>
                  <v-expansion-panels>
                    <v-expansion-panel v-for="category in categoryList" :key="category.id">
                      <v-expansion-panel-header>
                        <v-container>
                          {{ category.names[0].value }}
                        </v-container>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-expansion-panels>
                          <v-expansion-panel v-for="child in category.childs" :key="child.id">
                            <v-expansion-panel-header>
                              <v-container>
                                <input v-model="categorySelected" type="checkbox" :id="child.id" :value="child" />
                                {{ child.label }}
                              </v-container>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content></v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-container>
              </v-container>
            </v-col>

            <v-col lg="5">
              <v-container>
                <span> {{ $t('customs.inventory.category_selected') }}:* </span> <br />
                <v-container v-for="category in categorySelected" :key="category.id">
                  <v-container v-if="category.childs">
                    <span> {{ $t('customs.inventory.category') }} {{ category.names[0].value }}</span>
                    <v-icon @click="popBycategorieId(category.id)" :small="true" class="cancelCross">cancel</v-icon>
                    <br />
                  </v-container>

                  <v-container v-else>
                    <v-container v-if="category.label">
                      <span> {{ $t('customs.inventory.category') }} {{ category.label }}</span>
                      <v-icon @click="popBycategorieId(category.id)" :small="true" class="cancelCross">cancel</v-icon>
                      <br />
                    </v-container>
                    <v-container v-else>
                      <span> {{ category.names[0].value }} </span>
                      <v-icon @click="popBycategorieId(category.id)" :small="true" class="cancelCross">cancel</v-icon>
                      <br />
                    </v-container>
                  </v-container>
                </v-container>
              </v-container>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="buttonCreate">
          <v-row justify="center">
            <button @click="createInventory" :disabled="!displaySubmit">
              {{ $t('customs.inventory.create_inventory') }}
            </button>
          </v-row>
        </v-container>
      </v-container>
    </v-container>
  </v-container>
</template>
<script>
  import { generalService } from '../../services/general.service'
  import ProductCard from '../../components/Inventory/ProductCard'
  import FileInput from '../../components/Inventory/FileInput'

  export default {
    name: 'CreateInventory',
    data() {
      return {
        date: '',
        inventory_name: '',
        listStore: '',
        inventoryName: '',
        picker: new Date().toISOString().substr(0, 10),
        landscape: false,
        reactive: true,
        search: '',
        menu: '',
        loading: true,
        data: [],
        storeSelected: [],
        cityList: [],
        countryList: [],
        products: [],
        storeListCountry: [],
        categoryList: [],
        listResult: [],
        itemList: [],
        part1: true,
        categorySelected: [],
        validationCsv: false,
        csv: null,
        createdTime: 0
      }
    },
    async created() {
      await this.fillData()
      this.loading = true
      // sort array
      this.cityList.sort(function (a, b) {
        var nameA = a
        var nameB = b
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
      await this.getAllCategory()
      await this.getAllProduct()
    },
    components: {
      ProductCard,
      FileInput
    },
    computed: {
      displaySubmit() {
        let display = false
        if (this.inventoryName !== '' && this.storeSelected.length > 0 && this.categorySelected.length > 0) {
          display = true
        }
        return display
      }
    },
    methods: {
      popBycategorieId(id) {
        for (let category of this.categorySelected) {
          if (category.id === id) {
            this.categorySelected.splice(this.categorySelected.indexOf(category), 1)
          }
        }
      },
      addProduct(event) {
        let product = {}
        product.product_id = event.id
        product.names = event.variants[0].name
        product.type = 'product'
        this.categorySelected.push(product)
      },
      delProduct(event) {
        this.categorySelected.splice(this.categorySelected.indexOf(event), 1)
      },
      fillResult(event) {
        event = event.toUpperCase()
        if (this.search.length >= 3) {
          this.listResult = this.products
            .filter(product => {
              if (product.variants[0].name[0].value.includes(event) || product.sku.includes(event)) {
                return true
              }
            })
            .slice(0, 5)
        } else {
          this.listResult = []
        }
      },
      async getAllProduct() {
        this.products = await generalService.getAllProduct()
        this.loading = false
      },
      async getAllCategory() {
        this.categoryList = await generalService.getAllCategory()
        this.categoryList = this.categoryList.filter(item => {
          if (item.childs.length > 0) {
            return true
          }
        })
      },
      createInventory() {
        let inv = {}
        inv.name = this.inventoryName
        inv.date = this.picker
        // Store
        inv.stores = []
        for (let store of this.storeSelected) {
          inv.stores.push({
            id: store.id,
            label: store.label
          })
        }
        // Product and categories
        inv.items = []
        for (let item of this.categorySelected) {
          if (!item.product_id) {
            inv.items.push({
              product_id: item.id
            })
            this.createdTime++
          } else {
            inv.items.push(item)
          }
        }

        inv.csv = this.csv
        generalService.createInventory(inv)
        this.$router.push({ name: 'Inventory', params: { created: this.createdTime } })
      },
      // Check if city is already inside  tab
      alreadyInside(element, tab) {
        let res = false
        for (let el of tab) {
          if (element === el) {
            res = true
          }
        }
        return res
      },
      // Fill data tab
      async fillData() {
        // Get all Store from DB
        let stores = await generalService.getAllStores()
        // Init All Store and country and city
        for (let store of stores) {
          if (store.name !== '') {
            this.data.push({
              label: store.name,
              id: store.id_store,
              country: store.country,
              city: store.city
            })
          }
          if (!this.alreadyInside(store.country, this.countryList)) {
            this.countryList.push(store.country)
          }

          if (!this.alreadyInside(store.city, this.cityList)) {
            this.cityList.push(store.city)
          }
        }
        let storeListCity = []

        // Create StoreList by city
        for (let store of this.data) {
          if (!this.alreadyInsideCity(store.city, storeListCity)) {
            storeListCity.push({
              city: store.city,
              country: store.country,
              store: [store]
            })
          } else {
            this.pushTabDouble(store, 'city', storeListCity)
          }
        }

        // Use store list by city to create store list by country
        for (let store of storeListCity) {
          if (!this.alreadyInsideCountry(store.country, this.storeListCountry)) {
            this.storeListCountry.push({
              country: store.country,
              store: [store]
            })
          } else {
            this.pushTabDouble(store, 'country', this.storeListCountry)
          }
        }
        for (let item of this.storeListCountry) {
          item.store.sort(function (a, b) {
            var nameA = a.city
            var nameB = b.city
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          })
        }
      },
      // Check if city already inside list
      alreadyInsideCity(element, tab) {
        let res = false
        for (let el of tab) {
          if (element === el['city']) {
            res = true
          }
        }
        return res
      },
      // Check if country already inside list
      alreadyInsideCountry(element, tab) {
        let res = false
        for (let el of tab) {
          if (element === el['country']) {
            res = true
          }
        }
        return res
      },
      // Push in tab with a key
      pushTabDouble(element, index, tab) {
        for (let el of tab) {
          if (element[index] === el[index]) {
            el['store'].push(element)
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .createInventoryContent {
    .h3_date {
      font-size: 16px;
    }

    .buttonsContent {
      .suivant {
        margin: 0 0 0 40%;
        font-size: 20px;
      }

      .precedent {
        margin: 0% 0 0 5%;
        font-size: 20px;
      }
    }

    .cancelCross {
      color: red;
    }

    .buttonCreate {
      font-size: 140%;
    }

    .v-expansion-panel {
      width: 100%;
      margin: 3% 0 3% 0%;
    }
    .v-expansion-panel__header {
      padding: 16px 40px;
    }
    .cityExpansionPanel {
      width: 60%;
      margin: 0 0 0 20%;
    }
    .storeCheckbox {
      padding: 3% 0px 3% 25%;
    }
    h3 {
      margin: 3% 0 3% 0;
    }
    h1 {
      padding: 0 0 0 30%;
    }
    button {
      margin: 5% 0 0 0;
      font-size: 85%;
    }
    .titleCreateInventory {
      width: 100%;
      border-bottom: solid 1px #deb357;
      font-size: 1em;
    }
  }
</style>
