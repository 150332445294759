<template>
  <v-container>
    <template>
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="5"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': rowsPerPage,
          'items-per-page-text': $t('customs.salesBoard.rowsPerPage')
        }"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-icon @click="download(item.refInterne, item.csv)">get_app</v-icon>
              <span> {{ item.refInterne }} </span>
            </td>
            <td>
              <span> {{ item.name }} </span>
            </td>
            <td>{{ formatDateCreate(item.date) }}</td>
            <td>{{ formatStatus(item.status) }}</td>
            <td>{{ item.itemTotalTAmount }}</td>
            <td>{{ item.itemTotalCAmount }}</td>
            <td>
              <span v-for="store in item.stores" :key="store.id">{{ store.label }} </span>
            </td>
            <td v-if="isHistory">
              <span> {{ formatprocessDate(item.processingDate) }} </span>
            </td>
            <!-- <td v-if="isHistory">
            <span > {{item.seller }} </span>
          </td> -->
          </tr>
        </template>
      </v-data-table>
    </template>
  </v-container>
</template>
<script>
  import moment from 'moment'

  export default {
    name: 'InventoryList',
    props: {
      list: Array,
      isHistory: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        headers: [
          { text: 'Référence', align: 'left', sortable: true, value: 'refInterne' },
          { text: 'Nom Inventaire', align: 'left', sortable: true, value: 'name' },
          { text: 'Date de création', value: 'date', sortable: true },
          { text: 'Status', value: 'status', sortable: true },
          { text: 'Nombre de pièce théoriques', value: 'itemTotalTAmount', sortable: false },
          { text: 'Nombre de pièce comptés', value: 'itemTotalCAmount', sortable: false },
          { text: 'Magasin(s)', value: 'stores', sortable: true }
        ],
        url: 'data:text/csv;charset=utf-8,',
        rowsPerPage: [10, 20, 30, 40, 50, { text: 'All', value: -1 }]
      }
    },
    created() {
      if (this.isHistory) {
        this.headers.push(
          { text: 'Date de traitement', value: 'processingDate', sortable: true }
          // { text: 'Code Vendeur',
          //   value: 'seller',
          //   sortable: false
          // }
        )
      }
    },
    methods: {
      // Force download inventory detail
      download(filename, content) {
        var element = document.createElement('a')

        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(content))
        element.setAttribute('download', filename)
        element.style.display = 'none'

        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
      },
      formatDateCreate(date) {
        return moment(date).format('DD/MM/YYYY')
      },
      formatprocessDate(date) {
        return moment(date).format('DD/MM/YYYY HH:mm')
      },
      formatStatus(status) {
        let statusDisplay = ''
        if (status === 'New') {
          statusDisplay = 'Planifiés'
        }
        if (status === 'NotDone') {
          statusDisplay = 'En cours'
        }
        if (status === 'Done') {
          statusDisplay = 'Valider'
        }
        return statusDisplay
      }
    }
  }
</script>

<style scoped></style>
