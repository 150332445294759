const customs = {
  _loader: {
    waitingText: 'Please wait'
  },
  menu: {
    homepage: 'Homepage',
    dashboard: 'Dashboard',
    administration: 'Users administration',
    inventory: 'Inventory',
    valorization: 'Basket valorization rules',
    releaseNotes: 'Release notes',
    groups: 'Groups',
    validate: 'Validate',
    actualize: 'Actualize',
    close: 'Close',
    errorOccurred: 'An error has occurred',
    moreFilters: '+ Filters',
    lessFilters: '- Filters',
    dayD: 'D',
    weekW: 'W',
    monthM: 'M',
    yearY: 'Y',
    startDate: 'Start date',
    endDate: 'End date',
    convertSales: 'Convert sales',
    status: 'Status',
    currency: 'Retrieved by currency',
    country: 'Country'
  },
  toolbar: {
    expand: 'Expand',
    collapse: 'Collapse',
    profil: 'Profile',
    signOut: 'Sign out',
    changeGroup: 'Change group'
  },
  login: {
    signInToAccount: 'Sign in to your account',
    login: 'Login',
    pleaseEnterEmail: 'Please enter email',
    pleaseEnterPassword: 'Please enter password',
    emailMustBeValid: 'Email must be valid',
    passwordTooShort: 'Password must be of 4 characters or more',
    email: 'Email',
    password: 'Password'
  },
  daterange: {
    startLabel: 'Start date',
    endLabel: 'End date',
    separatorLabel: 'to'
  },
  dashboard: {
    stores: 'Stores',
    sellers: 'Sellers',
    selectAll: 'Select all',
    totalSales: 'Total sales price',
    totalSalesDetails: 'Total sales price details',
    totalSalesNumber: 'Total sales number',
    transferredSalesNumber: 'Transferred to checkout sales number',
    averageBasket: 'Average basket',
    totalSoldProducts: 'Total sold products',
    distributionOriginSales: 'Distribution of the origin of the transactions',
    distributionTypeSales: 'Distribution of the type of the transactions',
    productsPerSaleAvg: 'Products per sale average',
    previousPeriod: 'Previous period :',
    vsPreviousPeriod: 'VS previous period',
    unavailableDataForPreviousPeriod: 'Unavailable data for previous period',
    unavailableData: 'Unavailable data',
    revenues: 'Revenues',
    tablet: 'Tablet',
    checkout_counter: 'Checkout counter',
    nbTransactions: '{count} transaction | {count} transactions',
    orders: 'Orders',
    takeawaySales: 'Takeaway sales',
    mixedSales: 'Mixed sales',
    typeInToFindSeller: 'Type in here to find a seller',
    customersCreations: 'Number of customers created',
    allSales: 'All sales',
    salesBySeller: 'Sales by seller',
    salesByStore: 'Sales by store',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    scale: 'Scale',
    noStoresAssigned:
      'No stores are assigned to your account, please go to the users administration page to assign stores to your account or please ask your administrator to assign stores to your account.'
  },
  reporting: {
    email: 'Email',
    store: 'Store',
    actions: 'Actions',
    create: 'Create',
    close: 'Cancel',
    save: 'Save',
    linkStoreToEmail: 'Link store to email',
    update: 'Update reporting',
    incorrectEmail: 'Incorrect Email',
    delete: 'Are you sure you want to delete this reporting ?',
    deleteBtn: 'Delete',
    sendReportingInfo: 'Send a report to one or more email addresses by selecting a period and one or more stores.',
    globalErrors: {
      createError: 'An error occurred during creation',
      updateError: 'An error occurred during the update',
      deleteError: 'An error occurred while deleting'
    },
    globalSuccess: {
      createSuccess: 'Reporting successfully created',
      updateSuccess: 'Reporting successfully updated',
      deleteSuccess: 'Reporting successfully deleted'
    }
  },
  salesBoard: {
    sales: 'Sales',
    searchByRef: 'Search by reference',
    searchByStatus: 'Search by status',
    searchByPrice: 'Search by price',
    searchByDate: 'Search by date',
    searchBySeller: 'Search by seller',
    searchByStore: 'Search by store',
    filters: 'Filters',
    ref: 'Reference',
    refOcti: 'Octipas reference',
    status: 'Status',
    price: 'Price',
    totalPrice: 'Total price',
    takeawayPrice: 'Total takeaway',
    orderPrice: 'Total order',
    date: 'Date',
    store: 'Store',
    seller: 'Seller',
    allStatus: 'All',
    notFinishedStatus: 'Not finished',
    validatedStatus: 'Validated',
    rowsPerPage: 'Rows per page',
    name: 'Name',
    firstname: 'First name',
    paymentOrigin: 'Payment origin',
    sellerName: 'Seller name',
    currency: 'Currency',
    totalPriceWithoutTaxWithoutDiscount: 'Total price without tax/without discount',
    totalPriceWithoutTaxWithDiscount: 'Total price without tax/with discount',
    totalPriceWithTaxWithoutDiscount: 'Total price with tax/without discount',
    totalPriceWithTaxWithDiscount: 'Total price with tax/with discount',
    deliveryType: 'Delivery type',
    quantity: 'Quantity',
    productBarcode: 'Product barcode',
    productName: 'Product name',
    productVariantId: 'Product variant ID',
    productPrice: 'Product price',
    order: 'Order',
    takeaway: 'Takeaway',
    ship_from_store: 'Ship from store',
    subBasket: 'Sub basket',
    customerName: 'Customer name',
    customerId: 'Customer ID',
    ok: 'Ok',
    cancel: 'Cancel',
    exportAsCSV: 'Export as CSV',
    noDataToExportAsCSV: 'No data to export',
    deviceName: 'Name of the device used for the sale',
    deviceSerial: 'Serial number of the device used for the sale',
    salesNumber: 'Sales number',
    averageBasket: 'Average basket',
    totalSoldProducts: 'Total sold products',
    samples: 'Samples',
    paymentTypeTable: 'Different type of payment',
    paymentType: 'Payment type',
    referencePayment: 'Payment reference',
    paymentAmount: 'Amount'
  },
  topProductsBoard: {
    topSoldProducts: 'Top sold products',
    barcode: 'Barcode',
    price: 'Price',
    imageLink: 'Image link',
    size: 'Size',
    title: 'Title',
    variantId: 'Variant ID',
    purchasesNumber: 'Purchases number'
  },
  scannedProducts: {
    barcode: 'Barcode',
    scanNumber: 'Scans number',
    scannedProducts: 'Scanned products',
    scannedProductsPerSeller: 'Scanned products per seller',
    scannedProductsPerStore: 'Scanned products per store',
    purchasedScannedProducts: 'Purchased scanned products',
    store: 'Store',
    seller: 'Seller'
  },
  originPayment: {
    tablet: 'Seller tablet',
    checkout_counter: 'Checkout counter'
  },
  snackbarMessages: {
    youDidntChooseClient: "You didn't choose a client",
    errorOccurred: 'An error has occurred',
    notConnectedToSSO: 'Please login again'
  },
  // SSO Users administration
  ssoUsers: {
    titles: {
      titleNavbar: 'Users',
      titleTabs: {
        usersTab: 'Users',
        groupsTab: 'Groups',
        rolesTab: 'Roles'
      }
    },
    globalErrors: {
      serverNotResponding: 'Connexion timed out, please try later',
      userModifiedUnknownStore: 'An user stores list has been modified because one or more store are unknown'
    },
    usersTab: {
      description: 'Create, update, manage or delete users',
      searchPlaceholder: 'Search by name',
      createUserBtn: 'Create A New User',
      noGroupsAsigned: 'No group assigned',
      noRolesAsigned: 'No role assigned',
      others: 'others',
      userTable: {
        name: 'Name',
        email: 'Email',
        store: 'Stores',
        username: 'Username',
        groups: 'Groups',
        roles: 'Roles',
        active: 'Active',
        actions: 'Actions'
      },
      snackbar: {
        userCreated: 'The user has been created',
        userUpdated: 'The user has been updated',
        userAlreadyExist: 'this email is already registered',
        userCreatedErrorGroup:
          "The user has been created but the group(s) wasn't assigned to him/her do to an internal error",
        userDeleted: 'The user has been deleted',
        error: 'An error has occured'
      }
    },
    usersTabModal: {
      firstNameLabel: 'Firstname',
      firstNameValidationMsg: 'Firstname is required',
      lastNameLabel: 'Lastname',
      lastNameValidationMsg: 'Lastname is required',
      usernameLabel: 'Username',
      usernameValidationMSg: 'A username is required',
      emailLabel: 'Email',
      emailValidationMsg: 'Email is required',
      passwdLabel: 'Password',
      passwdHint: 'at least 8 characters',
      passwdValidationMsg: 'A password is required',
      passwdNotEnoughCarac: 'Not enough characters',
      passwdConfirmLabel: 'Password confirmation',
      passwdConfirmHint: 'at least 8 characters',
      passwdConfirmValidationMsg: 'A password is required',
      passwdConfirmNotEnoughCarac: 'Not enough characters',
      passwdConfirmNotSamePasswd: "The passwords doesn't match",
      userStoresLabel: 'Stores',
      userStoresHint: 'Select one or multiple stores',
      noStoresAvailable: 'No store available',
      userStoresPlaceholder: 'Select one or multiple stores',
      userStoresSelectAll: 'Select all stores',
      userStoresRemoveAll: 'Remove all stores',
      adminCheckboxLabel: 'Administrator Role',
      adminCheckboxTooltip: 'An admin can create or delete users and give them stores to access',
      incorrectInput: 'The format is incorrect',
      groupsLabel: 'Groups',
      groupsHint: 'Select one or several groups',
      noGroupAvailable: 'No group available',
      rolesLabel: 'Roles',
      rolesHint: 'Select one or several roles',
      noRoleAvailable: 'No role available',
      deleteUserBtn: 'Delete User',
      createUserBtn: 'Submit',
      cancelUserBtn: 'Close',
      confirmDeleteModal: {
        modalTitle: 'Are you sure you want to delete this user?',
        modalText: 'This action cannot be undone',
        cancelBtn: 'Cancel',
        confirmBtn: 'Delete'
      }
    },
    groupsTab: {
      description: 'Create, update, manage or delete groups',
      searchPlaceholder: 'Search by name',
      createGroupBtn: 'Create A New Group',
      groupTable: {
        name: 'Name',
        actions: 'Actions'
      },
      snackbar: {
        groupCreated: 'The group has been created',
        groupDeleted: 'The group has been deleted',
        groupUpdated: 'The group has been updated',
        error: 'An error has occured'
      }
    },
    groupsTabModal: {
      groupNameLabel: 'Group Name',
      groupNameValidationMsg: 'A group name is require',
      groupDescription: 'Group Description',
      NoGroupDescription: 'There is no description for this group',
      incorrectInput: 'The format is incorrect',
      deleteGroupBtn: 'Delete Group',
      createGroupBtn: 'Submit',
      cancelGroupBtn: 'Close',
      confirmDeleteModal: {
        modalTitle: 'Are you sure you want to delete this group?',
        modalText: 'This action cannot be undone',
        cancelBtn: 'Cancel',
        confirmBtn: 'Delete'
      }
    },
    rolesTab: {
      description: 'Create, update, manage or delete roles',
      searchPlaceholder: 'Search by name',
      createRoleBtn: 'Create A New Role',
      roleTable: {
        name: 'Name',
        actions: 'Actions'
      },
      snackbar: {
        roleCreated: 'The role has been created',
        roleDeleted: 'The role has been deleted',
        roleUpdated: 'The role has been updated',
        error: 'Une erreur est survenue'
      }
    },
    rolesTabModal: {
      roleNameLabel: 'Role Name',
      roleNameValidationMsg: 'A group name is require',
      roleDescription: 'Role Description',
      NoGroupDescription: 'There is no description for this role',
      incorrectInput: 'The format is incorrect',
      toolTipText: 'Cannot change the role name once created',
      deleteRoleBtn: 'Delete Role',
      createRoleBtn: 'Submit',
      cancelRoleBtn: 'Close',
      confirmDeleteModal: {
        modalTitle: 'Are you sure you want to delete this role?',
        modalText: 'This action cannot be undone',
        cancelBtn: 'Cancel',
        confirmBtn: 'Delete'
      },
      roleCreateModal: {
        modalTitle: 'Caution',
        modalText: 'Once created, it will be impossible to change the name of the role'
      }
    }
  },
  valorization: {
    basketRules: 'Basket valorization rules',
    addRule: 'Add rule',
    deleteRuleQuestion: 'Are you sure you want to delete this rule ?',
    cancel: 'Cancel',
    ok: 'Ok',
    disabled: 'Disabled',
    enabled: 'Enabled',
    noData: 'No rules available',
    name: 'Name',
    status: 'Status',
    actions: 'Actions',
    general: 'General',
    ruleName: 'Rule name',
    rulePriority: "Rule's priority",
    conditions: 'Conditions',
    ruleCoupon: 'Coupon code (Optional)',
    ruleMaxUseCustomer: 'Max use by customer',
    ruleFromDate: 'Activation start date',
    ruleToDate: 'Activation end date',
    ruleConditionType: 'Condition type',
    ruleProductsInList: 'Products in list',
    ruleMinAmount: 'Min amount (specified products)',
    ruleBasketMinAmount: 'Min amount in basket',
    specificStores: 'Specific stores',
    loyaltyPoints: 'Loyalty points',
    basketAmount: 'Basket amount',
    minProductsInList: 'Min products in list',
    ruleSkusList: 'SKUs separetd by comma (empty for all products)',
    or: 'or',
    fileSelected: 'File selected',
    selectFile: 'Select a file',
    specificStoresInput: 'Store ids separated by commas',
    ruleAddCondition: 'Add a condition',
    effects: 'Effects',
    rulePercentDiscountProduct: 'Discount percent on concerned products',
    ruleFixDiscountProduct: 'Discount on concerned products',
    ruleDiscountPercentProductPrice: 'Discount as a percentage of the product price',
    ruleDiscountFixedAmountBasket: 'Rule of a fixed amount on the whole basket',
    ruleBuyXProductGetTheCheapestAtYPercent: 'Buy X products, get the cheapest one at Y% off',
    ruleBuyXProductsGetSpecificProductFree: 'Buy X products, get a specifc product for free',
    ruleBuyXProductsGetFreeProductInList: 'Buy X products, get a product for free in the following list',
    discount: 'Discount',
    ruleQtyForDiscount: 'Quantity to get discount (Buy X)',
    ruleMaxProductDiscountCount: 'Max number of discounted products',
    applyProductsRulesLabel: 'Apply to products matching the following rules:',
    productsOption: 'product option',
    productInfo: 'product additional info',
    key: 'Key',
    value: 'Value',
    true: 'True',
    false: 'False',
    ruleBasketDiscount: 'Discount on basket',
    ruleBasketPercentDiscount: 'Discount percent on basket',
    ruleMaxDiscount: 'Max discount amount (Optional)',
    seeDetails: 'See details',
    ruleInvalidRuleName: 'Invalid rule name',
    points: 'Points',
    submit: 'Submit',
    infoRulesApplyOnStagingAndProd:
      'Valorization basket rules which are displayed below or which you will create will be applied for both staging AND production environment'
  },
  releaseNote: {
    title: 'Release notes',
    noReleaseNote: 'No release note available'
  },
  devices: {
    totalConnectedDevices: 'Total connected devices'
  }
}

export default customs
