import { API } from './core/api-core.service'
import store from '../store'
import { usersService } from './sso-users.service'
import { storeService } from './store.service'
import { sellerService } from './seller.service'

export const generalService = {
  /**
   * Get configuration
   */
  async getPartnerConfig() {
    let configuration = await API.request('GET', 'sso/configuration')
    store.dispatch('setCurrentPartnerConfig', configuration)
    return configuration
  },

  async getAPIPartnerInfos() {
    if (!store.state.currentGroup) {
      let group = await usersService.getGroupById(store.state.currentUser.memberships[0].groupId)
      await store.dispatch('setCurrentGroup', group.group)
    }

    if (!store.state.currentPartnerConfig) {
      // Get and set partner config
      await store.dispatch('setPartnerId', require('../config/partnerIds.json')[store.state.currentGroup.name])
      await this.getPartnerConfig()
      await store.dispatch('setUserHasChosenGroup', true)
    }

    if (
      store.state.currentPartnerConfig &&
      store.state.currentPartnerConfig.hasOwnProperty('apiLink') &&
      store.state.currentPartnerConfig.apiLink !== null &&
      store.state.currentPartnerConfig.apiLink !== ''
    ) {
      if (store.state.allStores.length === 0) {
        let stores = await storeService.getAllStores()
        await store.dispatch('setAllStores', stores)
      }

      if (store.state.allSellers.length === 0) {
        let sellers = await sellerService.getAllSellers()
        await store.dispatch('setAllSellers', sellers)
      }
    }
  },

  /**
   * Get all Store
   */
  async getAllStores() {
    try {
      let res = await API.requestMongoServer('GET', 'Stores')
      return res
    } catch (e) {
      alert(e)
    }
    return null
  },

  /**
   * Get all Product
   */
  async getAllProduct() {
    try {
      let res = await API.requestMongoServer('GET', 'Products')
      console.log('Product chargé !')
      return res
    } catch (e) {
      alert(e)
    }
    return null
  },

  /**
   * Get all Category
   */
  async getAllCategory() {
    try {
      let res = await API.requestMongoServer('GET', 'Categories')
      return res
    } catch (e) {
      alert(e)
    }
    return null
  },

  /**
   * Create Inventory
   */
  async createInventory(inv) {
    try {
      let res = await API.requestMongoServer('POST', 'CreateInventory', null, inv)
      return res
    } catch (e) {
      alert(e)
    }
    return null
  },

  /**
   * Get All Inventory
   */
  async getAllInventory() {
    try {
      let res = await API.requestMongoServer('GET', 'InventoryForms')
      return res
    } catch (e) {
      alert(e)
    }
    return null
  }
}
