import { API } from './core/api-core.service'

export const reportingService = {
  /**
   * Send Reporting via SSO and V3 API
   * @param { array } emailsList list of email that will receive the reporting
   * @param { array } storesList list of stores that will be get to send the reporting (one reporting for each store)
   * @param { string } startDate The date where begin the reporting
   * @param { string } endDate The date where end the reporting
   */
  sendReporting(emailsList, storesList, startDate, endDate) {
    return API.request('post', 'sso/sendReporting', null, {
      emailsList,
      storesList,
      startDate,
      endDate
    })
  },
  async getReporting() {
    return API.request('get', 'sso/getReporting')
  },
  async addReporting(body) {
    return API.request('post', 'sso/reporting', null, body)
  },
  async updateReporting(body) {
    return API.request('put', `sso/reporting/${body.id_reporting_mail}`, null, body)
  },
  async deleteReporting(id) {
    return API.request('delete', `sso/reporting/${id}/delete`)
  }
}
