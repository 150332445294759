<template>
  <v-card>
    <v-card-title>
      <div class="title mb-2">{{ $t('customs.dashboard.distributionOriginSales') }}</div>
    </v-card-title>
    <v-divider />
    <v-card-text v-if="dataToDisplay.length > 0">
      <pie-chart class="origin-sales-chart" :options="options" :data="salesOriginData" />
    </v-card-text>
    <v-card-text v-else>
      <v-row align="center" justify="center">
        <div class="title">{{ $t('customs.dashboard.unavailableData') }}</div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import PieChart from '@/components/ChartJs/PieChart'

  export default {
    name: 'SalesOriginIndicator',
    components: {
      PieChart
    },
    props: {
      sales: Array
    },
    computed: {
      options() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'right'
          },
          tooltips: {
            enabled: true,
            callbacks: {
              label: (tooltipItems, data) => {
                return (
                  data.labels[tooltipItems.index] +
                  ' : ' +
                  this.$tc(
                    'customs.dashboard.nbTransactions',
                    data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
                  )
                )
              }
            }
          }
        }
      },
      salesOriginData() {
        return {
          labels: this.labelsToDisplay,
          datasets: [
            {
              data: this.dataToDisplay,
              borderWidth: 0,
              backgroundColor: ['#cca256', '#e9bd70', '#ffd98a']
            }
          ]
        }
      },
      chartData() {
        let data = {}
        for (let sale of this.sales) {
          if (sale.origin_payment && sale.origin_payment.name_origin_payment) {
            if (!data.hasOwnProperty(this.$t('customs.dashboard.' + sale.origin_payment.name_origin_payment))) {
              data[this.$t('customs.dashboard.' + sale.origin_payment.name_origin_payment)] = 1
            } else {
              data[this.$t('customs.dashboard.' + sale.origin_payment.name_origin_payment)]++
            }
          }
        }
        return data
      },
      labelsToDisplay() {
        let labels = []
        for (let key in this.chartData) {
          labels.push(key)
        }
        return labels
      },
      dataToDisplay() {
        let data = []
        for (let key in this.chartData) {
          data.push(this.chartData[key])
        }
        return data
      }
    }
  }
</script>

<style scoped>
  .origin-sales-chart {
    height: 25vh !important;
  }
</style>
