<template>
  <div>
    <div
      class="text-center"
      v-if="
        !this.$store.state.currentUser.data ||
        !this.$store.state.currentUser.data.dataBackoffice ||
        !this.$store.state.currentUser.data.dataBackoffice.userStores ||
        this.$store.state.currentUser.data.dataBackoffice.userStores.length === 0
      "
    >
      <v-dialog v-model="noStoresAssigned" width="500">
        <v-card>
          <v-card-subtitle class="text-h6 pt-3">
            {{ $t('customs.dashboard.noStoresAssigned') }}
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="$router.push({ name: 'Users' })">{{
              $t('customs.menu.administration')
            }}</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="$router.push({ name: 'Home' })">
              {{ $t('customs.menu.homepage') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-container v-else fluid grid-list-xl>
      <!-- Date filter -->
      <v-row align="end" justify="center">
        <!-- Select Period filter -->
        <v-col lg="3" md="6" sm="10" cols="11" style="display: flex" class="choosePeriod">
          <v-col lg="3" md="3" sm="3" cols="3" class="pa-0">
            <v-btn
              :disabled="chartLoading"
              min-width="100%"
              :color="dayPeriod ? 'primary' : 'grey lighten-1'"
              :class="dayPeriod ? 'periodSelected' : 'period'"
              :elevation="0"
              @click="setDate('d')"
              tile
            >
              {{ $t('customs.menu.dayD') }}
            </v-btn>
          </v-col>
          <v-col lg="3" md="3" sm="3" cols="3" class="pa-0">
            <v-btn
              :disabled="chartLoading"
              min-width="100%"
              :color="weekPeriod ? 'primary' : 'grey lighten-1'"
              :class="weekPeriod ? 'periodSelected' : 'period'"
              :elevation="0"
              @click="setDate('w')"
              tile
            >
              {{ $t('customs.menu.weekW') }}
            </v-btn>
          </v-col>
          <v-col lg="3" md="3" sm="3" cols="3" class="pa-0">
            <v-btn
              :disabled="chartLoading"
              min-width="100%"
              :color="monthPeriod ? 'primary' : 'grey lighten-1'"
              :class="monthPeriod ? 'periodSelected' : 'period'"
              :elevation="0"
              @click="setDate('m')"
              tile
            >
              {{ $t('customs.menu.monthM') }}
            </v-btn>
          </v-col>
          <v-col lg="3" md="3" sm="3" cols="3" class="pa-0">
            <v-btn
              :disabled="chartLoading"
              min-width="100%"
              :color="yearPeriod ? 'primary' : 'grey lighten-1'"
              :class="yearPeriod ? 'periodSelected' : 'period'"
              :elevation="0"
              @click="setDate('y')"
              tile
            >
              {{ $t('customs.menu.yearY') }}
            </v-btn>
          </v-col>
        </v-col>

        <!-- Store filter -->
        <v-col v-if="$store.state.allStores.length > 0" cols="12" lg="4" md="8" sm="12">
          <v-autocomplete
            v-model="selectedStores"
            :items="stores"
            item-text="name_store"
            item-value="id_store"
            :label="$t('customs.dashboard.stores')"
            multiple
            chips
            prepend-icon="mdi-store"
            :disabled="chartLoading"
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="storeSelectToggle">
                <v-list-item-action>
                  <v-icon>{{ storeSelectIcon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('customs.dashboard.selectAll') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2" />
            </template>
            <template slot="selection" slot-scope="{ item, index }">
              <v-chip v-if="index < 4" outlined color="primary lighten-1">
                <span>{{ item.name_store }}</span>
              </v-chip>
              <span v-if="index === 4" class="grey--text text-caption"
                >(+{{ selectedStores.length - 4 }} {{ $t('customs.ssoUsers.usersTab.others') }})</span
              >
            </template>
          </v-autocomplete>
        </v-col>

        <!-- Seller filter -->
        <v-col v-if="$store.state.allSellers.length > 0" cols="12" lg="4" md="8" sm="12">
          <v-autocomplete
            v-model="selectedSellers"
            :search-input.sync="searchSellersValue"
            :items="foundSellers"
            :item-text="
              item =>
                item.user
                  ? item.user.name_user + ' ' + item.user.firstname_user + ' [' + item.store.name_store + ']'
                  : ''
            "
            return-object
            :label="$t('customs.dashboard.sellers')"
            multiple
            chips
            no-filter
            prepend-icon="mdi-face"
            :loading="searchSellersLoading"
            :disabled="chartLoading"
            :placeholder="$t('customs.dashboard.typeInToFindSeller')"
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="sellerSelectToggle">
                <v-list-item-action>
                  <v-icon>{{ storeSelectIcon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('customs.dashboard.selectAll') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2" />
            </template>

            <template slot="selection" slot-scope="{ item, index }">
              <v-chip v-if="index < 3" outlined color="primary lighten-1">
                <span>{{ item.user.name_user }} {{ item.user.firstname_user }}</span>
              </v-chip>
              <span v-if="index === 3" class="grey--text text-caption"
                >(+{{ selectedSellers.length - 3 }} {{ $t('customs.ssoUsers.usersTab.others') }})</span
              >
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row align="start" justify="center" class="filter-row">
        <v-col cols="11" lg="2" md="6" sm="6" align-self="start" class="more_filters pa-0">
          <!-- Show more filters Btn -->
          <v-col cols="12" lg="12" md="12" sm="12" align-self="start">
            <v-btn
              :disabled="chartLoading"
              color="primary"
              @click="expandedFilters = !expandedFilters"
              class="more_filters_btn"
            >
              <span v-if="expandedFilters">{{ $t('customs.menu.lessFilters') }}</span>
              <span v-else>{{ $t('customs.menu.moreFilters') }}</span>
            </v-btn>
          </v-col>
          <!-- Refresh Btn -->
          <v-col cols="12" lg="12" md="12" sm="12" align-self="start">
            <v-btn color="primary" @click="actualizeData" :loading="chartLoading" class="refresh_btn">
              <v-icon left>mdi-reload</v-icon>
              {{ $t('customs.menu.actualize') }}
            </v-btn>
          </v-col>
        </v-col>

        <v-col cols="12" lg="9" md="10" sm="12" class="filters" :class="{ 'expand-filters': expandedFilters }">
          <v-row align="end" justify="center">
            <!-- Date picker -->
            <v-col lg="3" md="3" sm="6" cols="6" class="py-0">
              <v-menu
                ref="menu"
                v-model="startDateWindowOpened"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateRangeStartFormatted"
                    :label="$t('customs.menu.startDate')"
                    readonly
                    :disabled="chartLoading"
                    prepend-icon="mdi-calendar-arrow-right"
                    v-on="on"
                  />
                </template>
                <v-date-picker :locale="navigatorLang" v-model="dateRange.start" no-title scrollable>
                  <v-spacer />
                  <v-btn text color="primary" @click="startDateWindowOpened = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="startDateWindowOpened = false">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col lg="3" md="3" sm="6" cols="6" class="py-0">
              <v-menu
                ref="menu"
                v-model="endDateWindowOpened"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateRangeEndFormatted"
                    :label="$t('customs.menu.endDate')"
                    readonly
                    :disabled="chartLoading"
                    prepend-icon="mdi-calendar-arrow-left"
                    v-on="on"
                  />
                </template>
                <v-date-picker :locale="navigatorLang" v-model="dateRange.end" no-title scrollable>
                  <v-spacer />
                  <v-btn text color="primary" @click="endDateWindowOpened = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="endDateWindowOpened = false">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <!-- Convert filter -->
            <v-col lg="2" md="2" sm="4" cols="4" class="py-0">
              <v-select
                v-model="selectedConvert"
                :items="currencies"
                :item-text="item => item.name_currency + ' (' + item.symbol_currency + ')'"
                item-value="code_currency"
                :label="$t('customs.menu.convertSales')"
                :disabled="chartLoading"
                prepend-icon="fa-coins"
              />
            </v-col>

            <!-- Status filter -->
            <v-col lg="2" md="2" sm="4" cols="4" class="py-0">
              <v-select
                v-model="salesFilters.statusFilter"
                :items="statuses"
                :label="$t('customs.menu.status')"
                :disabled="chartLoading"
                prepend-icon="mdi-check-circle-outline"
              />
            </v-col>

            <!-- Currency filter -->
            <v-col lg="2" md="2" sm="4" cols="4" class="py-0">
              <v-select
                v-model="selectedCurrencies"
                :items="currencies"
                :item-text="item => item.name_currency + ' (' + item.symbol_currency + ')'"
                item-value="id_currency"
                :label="$t('customs.menu.currency')"
                :disabled="chartLoading"
                prepend-icon="mdi-currency-eur"
                multiple
              />
            </v-col>

            <!-- Country filter -->
            <v-col lg="2" md="2" sm="4" cols="4" class="py-0">
              <v-autocomplete
                v-model="selectedCountries"
                :items="countries"
                :item-text="item => item.name_country + ' (' + item.iso_code_country + ')'"
                item-value="id_country"
                :label="$t('customs.menu.country')"
                :disabled="chartLoading"
                prepend-icon="mdi-map-marker-outline"
                multiple
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Total sales indicator -->
      <v-row fill-height align="start">
        <v-row class="px-3">
          <v-col lg="3" md="4" sm="12">
            <revenues-indicator
              :total-revenues="indicatorsValues.totalRevenues"
              :total-revenues-previous="indicatorsValues.totalRevenuesPrevious"
              :total-revenues-per-store="indicatorsValues.totalRevenuesPerStore"
              :start-date="dateRange.start"
              :end-date="dateRange.end"
              :currency="indicatorsValues.currency"
            />
          </v-col>
          <v-col lg="9" md="8" sm="12">
            <revenues-details-indicator :sales="sales" :date-range="dateRangeForCharts" />
          </v-col>
        </v-row>
        <v-row class="px-3">
          <v-col lg="3" md="4" sm="12">
            <sales-number-indicator
              :total-sales-number="indicatorsValues.totalSalesNumber"
              :total-sales-number-previous="indicatorsValues.totalSalesNumberPrevious"
            />
          </v-col>
          <v-col lg="3" md="4" sm="12">
            <average-basket-indicator
              :average-basket="indicatorsValues.averageBasket"
              :average-basket-previous="indicatorsValues.averageBasketPrevious"
              :currency="indicatorsValues.currency"
            />
          </v-col>
          <v-col lg="3" md="4" sm="12">
            <total-sold-products-indicator
              :total-sold-products="indicatorsValues.totalSoldProducts"
              :total-sold-products-previous="indicatorsValues.totalSoldProductsPrevious"
            />
          </v-col>
          <v-col lg="3" md="4" sm="12">
            <products-per-sale-average-indicator
              :product-per-sale-average="indicatorsValues.productPerSaleAverage"
              :product-per-sale-average-previous="indicatorsValues.productPerSaleAveragePrevious"
            />
          </v-col>
          <v-col lg="3" md="4" sm="12">
            <transferred-sales-number-indicator
              :transferred-sales-number="indicatorsValues.transferredSalesNumber"
              :transferred-sales-number-previous="indicatorsValues.transferredSalesNumberPrevious"
            />
          </v-col>
          <v-col lg="3" md="4" sm="12">
            <customers-creations-indicator
              :customers-creations-number="customersCreations.length"
              :customers-creations-number-previous="previousCustomersCreations.length"
            />
          </v-col>
        </v-row>
      </v-row>

      <v-divider class="mt-5 mb-5" />

      <!-- Sales list -->
      <v-row>
        <v-col lg="12" md="12" sm="12">
          <v-card class="elevation-4 border-radius6">
            <v-tabs v-model="currentSalesListTab">
              <v-tab v-for="tab in salesListTabs" :key="tab">
                <h3>{{ tab }}</h3>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="currentSalesListTab">
              <v-tab-item>
                <sales-list
                  ref="salesList"
                  :formatted-sales="sales"
                  :loading="chartLoading"
                  :start-date="dateRange.start"
                  :end-date="dateRange.end"
                />
              </v-tab-item>
              <v-tab-item>
                <sales-list-by-seller
                  ref="salesListBySeller"
                  :formatted-sales="sales"
                  :loading="chartLoading"
                  :start-date="dateRange.start"
                  :end-date="dateRange.end"
                />
              </v-tab-item>
              <v-tab-item>
                <sales-list-by-store
                  ref="salesListByStore"
                  :formatted-sales="sales"
                  :loading="chartLoading"
                  :start-date="dateRange.start"
                  :end-date="dateRange.end"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>

      <v-divider class="mt-5 mb-5" />

      <!-- Pie charts for origins and types of sales, top products board -->
      <v-row align="start">
        <v-col lg="4" md="4" sm="12">
          <v-col class="pt-0">
            <sales-origin-indicator :sales="sales" />
          </v-col>
          <v-col>
            <sales-type-indicator :sales="sales" />
          </v-col>
        </v-col>
        <v-col lg="8" md="8" sm="12">
          <top-products-list ref="topProductsList" :sales="sales" :loading="chartLoading" />
        </v-col>
      </v-row>

      <v-divider class="mt-5 mb-5" />

      <!-- Scanned products indicators -->
      <v-row>
        <v-col cols="12" lg="4" md="4" sm="12">
          <v-col class="pt-0 pr-0 pl-0" style="height: 50%; max-height: 45vh">
            <total-scanned-products-indicator
              :scanned-products="formattedScannedProducts"
              :start-date="dateRange.start"
              :end-date="dateRange.end"
            />
          </v-col>
          <v-col class="pb-0 pr-0 pl-0" style="height: 50%; max-height: 45vh">
            <total-purchased-scanned-products-indicator :sales="sales" />
          </v-col>
        </v-col>
        <v-col cols="12" lg="8" md="8" sm="12">
          <v-card class="elevation-4 border-radius6">
            <v-tabs v-model="currentScannedProductsTab">
              <v-tab v-for="tab in scannedProductsTabs" :key="tab">
                <h3>{{ tab }}</h3>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="currentScannedProductsTab">
              <v-tab-item>
                <scanned-products-list-indicator
                  ref="scannedProductsIndicator"
                  :scanned-products="formattedScannedProducts"
                  :start-date="dateRange.start"
                  :end-date="dateRange.end"
                  :loading="chartLoading"
                />
              </v-tab-item>
              <v-tab-item>
                <scanned-products-per-seller-indicator
                  ref="scannedProductsPerSellerIndicator"
                  :scanned-products="formattedScannedProducts"
                  :start-date="dateRange.start"
                  :end-date="dateRange.end"
                  :loading="chartLoading"
                />
              </v-tab-item>
              <v-tab-item>
                <scanned-products-per-store-indicator
                  ref="scannedProductsPerStoreIndicator"
                  :scanned-products="formattedScannedProducts"
                  :start-date="dateRange.start"
                  :end-date="dateRange.end"
                  :loading="chartLoading"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4" md="4" sm="12">
          <connected-devices-indicator :connectedDevices="connectedDevices" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import { saleService } from '../../services/sale.service'
  import { productService } from '../../services/product.service.js'
  import { currencyService } from '../../services/currency.service.js'
  import { countryService } from '../../services/country.service.js'
  import { customerService } from '@/services/customer.service'
  import { connectedDevicesService } from '../../services/connectedDevices.service'
  import { Helpers } from '../../mixins/Helpers.js'
  import { mapMutations } from 'vuex'
  // Components
  import SalesList from '../../components/SalesList'
  import SalesListBySeller from '@/components/SalesListBySeller'
  import SalesListByStore from '@/components/SalesListByStore'
  import TopProductsList from '../../components/TopProductsList'
  import RevenuesIndicator from '../../components/Indicators/RevenuesIndicator'
  import SalesNumberIndicator from '../../components/Indicators/SalesNumberIndicator'
  import AverageBasketIndicator from '../../components/Indicators/AverageBasketIndicator'
  import TotalSoldProductsIndicator from '../../components/Indicators/TotalSoldProductsIndicator'
  import ProductsPerSaleAverageIndicator from '../../components/Indicators/ProductsPerSaleAverageIndicator'
  import RevenuesDetailsIndicator from '../../components/Indicators/RevenuesDetailsIndicator'
  import SalesOriginIndicator from '../../components/Indicators/SalesOriginIndicator'
  import SalesTypeIndicator from '../../components/Indicators/SalesTypeIndicator'
  import ScannedProductsListIndicator from '../../components/Indicators/ScannedProductsListIndicator'
  import ScannedProductsPerSellerIndicator from '@/components/Indicators/ScannedProductsPerSellerIndicator'
  import ScannedProductsPerStoreIndicator from '@/components/Indicators/ScannedProductsPerStoreIndicator'
  import TotalScannedProductsIndicator from '../../components/Indicators/TotalScannedProductsIndicator'
  import TotalPurchasedScannedProductsIndicator from '../../components/Indicators/TotalPurchasedScannedProductsIndicator'
  import TransferredSalesNumberIndicator from '../../components/Indicators/TransferredSalesNumberIndicator'
  import CustomersCreationsIndicator from '@/components/Indicators/CustomersCreationsIndicator'
  import ConnectedDevicesIndicator from '../../components/Indicators/ConnectedDevicesIndicator.vue'

  const _ = require('lodash')
  const moment = require('moment')

  export default {
    name: 'Dashboard',
    mixins: [Helpers],
    components: {
      SalesList,
      SalesListBySeller,
      SalesListByStore,
      TopProductsList,
      RevenuesIndicator,
      SalesNumberIndicator,
      AverageBasketIndicator,
      TotalSoldProductsIndicator,
      ProductsPerSaleAverageIndicator,
      RevenuesDetailsIndicator,
      SalesOriginIndicator,
      SalesTypeIndicator,
      ScannedProductsListIndicator,
      ScannedProductsPerSellerIndicator,
      ScannedProductsPerStoreIndicator,
      TotalScannedProductsIndicator,
      TotalPurchasedScannedProductsIndicator,
      TransferredSalesNumberIndicator,
      CustomersCreationsIndicator,
      ConnectedDevicesIndicator
    },
    data() {
      return {
        scannedProducts: [],
        stores: [],
        allSellers: [],
        availableSellers: [],
        sales: [],
        formattedScannedProducts: [],
        previousSales: [],
        selectedStores: [],
        selectedSellers: [],
        startDateWindowOpened: false,
        endDateWindowOpened: false,
        dateRangeStartFormatted: moment().subtract(1, 'w').format('DD/MM/YYYY'),
        dateRangeEndFormatted: moment().format('DD/MM/YYYY'),
        dateRange: {
          start: moment().subtract(1, 'w').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD')
        },
        dateRangeForCharts: {
          start: moment().subtract(1, 'w').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD')
        },
        chartLoading: true,
        indicatorsValues: {
          currency: 'EUR',
          totalRevenues: 0,
          totalRevenuesPerStore: [],
          totalRevenuesPrevious: 0,
          totalSalesNumber: 0,
          totalSalesNumberPrevious: 0,
          averageBasket: 0,
          averageBasketPrevious: 0,
          totalSoldProducts: 0,
          totalSoldProductsPrevious: 0,
          productPerSaleAverage: 0,
          productPerSaleAveragePrevious: 0,
          transferredSalesNumber: 0,
          transferredSalesNumberPrevious: 0
        },
        expandedFilters: false,
        salesFilters: {
          statusFilter: null,
          priceFilter: [0, 0]
        },
        statuses: [
          {
            text: this.$t('customs.salesBoard.allStatus'),
            value: null,
            color: 'box-green-500-glow'
          },
          {
            text: this.$t('customs.salesBoard.validatedStatus'),
            value: 1,
            color: 'box-green-500-glow'
          },
          {
            text: this.$t('customs.salesBoard.notFinishedStatus'),
            value: 0,
            color: 'box-red-500-glow'
          }
        ],
        currencies: [],
        selectedCurrencies: [],
        selectedConvert: 'EUR',
        countries: [],
        selectedCountries: [],
        foundSellers: [],
        searchSellersValue: null,
        searchSellersLoading: false,
        debouncedSearchSellers: null,
        customersCreations: [],
        previousCustomersCreations: [],
        salesListTabs: [
          this.$t('customs.dashboard.allSales'),
          this.$t('customs.dashboard.salesBySeller'),
          this.$t('customs.dashboard.salesByStore')
        ],
        scannedProductsTabs: [
          this.$t('customs.scannedProducts.scannedProducts'),
          this.$t('customs.scannedProducts.scannedProductsPerSeller'),
          this.$t('customs.scannedProducts.scannedProductsPerStore')
        ],
        currentSalesListTab: '',
        currentScannedProductsTab: '',
        connectedDevices: 0,
        noStoresAssigned: false
      }
    },
    computed: {
      storeSelectIcon() {
        if (this.selectedStores.length > 0) return 'mdi-close-box'
        return 'mdi-checkbox-blank-outline'
      },
      navigatorLang() {
        return navigator.language
      },
      dayPeriod() {
        if (
          this.dateRange.start === moment().format('YYYY-MM-DD') &&
          this.dateRange.end === moment().format('YYYY-MM-DD')
        ) {
          return true
        }
        return false
      },
      weekPeriod() {
        if (
          this.dateRange.start === moment().subtract(1, 'weeks').format('YYYY-MM-DD') &&
          this.dateRange.end === moment().format('YYYY-MM-DD')
        ) {
          return true
        }
        return false
      },
      monthPeriod() {
        if (
          this.dateRange.start === moment().subtract(1, 'months').format('YYYY-MM-DD') &&
          this.dateRange.end === moment().format('YYYY-MM-DD')
        ) {
          return true
        }
        return false
      },
      yearPeriod() {
        if (
          this.dateRange.start === moment().subtract(1, 'years').format('YYYY-MM-DD') &&
          this.dateRange.end === moment().format('YYYY-MM-DD')
        ) {
          return true
        }
        return false
      }
    },
    methods: {
      ...mapMutations(['setAppLoader']),
      async actualizeData() {
        if (this.selectedStores.length > 0) {
          await this.initiateGetSalesPreviousAndCurrentPeriod(
            this.dateRange.start,
            this.dateRange.end,
            this.selectedStores,
            this.selectedSellers.map(seller => seller.id_user),
            this.salesFilters.statusFilter
          )
        } else {
          this.scannedProducts = []
          this.sales = []
          this.previousSales = []
        }
        await this.getConnectedDevices()
      },
      storeSelectToggle() {
        this.$nextTick(() => {
          if (this.selectedStores.length > 0) {
            this.selectedStores = []
          } else {
            this.selectedStores = this.stores.map(store => store.id_store)
          }
        })
      },
      sellerSelectToggle() {
        this.$nextTick(() => {
          if (this.selectedSellers.length > 0) {
            this.selectedSellers = []
          } else {
            this.selectedSellers = this.foundSellers.map(seller => {
              if (seller) return seller.id_user
            })
          }
        })
      },
      formatScannedProducts(newScannedProducts) {
        this.formattedScannedProducts = _.cloneDeep(newScannedProducts)
        for (let product of this.formattedScannedProducts) {
          if (product.user) {
            product.user.fullname_user = product.user.firstname_user + ' ' + product.user.name_user
          }
        }
      },
      async initiateGetSalesPreviousAndCurrentPeriod(
        dateRangeStart,
        dateRangeEnd,
        selectedStores,
        selectedSellersIds,
        selectedStatus
      ) {
        this.scannedProducts = []
        this.formattedScannedProducts = []
        this.sales = []
        this.previousSales = []
        this.indicatorsValues = _.cloneDeep(this.$options.data.call(this).indicatorsValues)
        this.chartLoading = true

        try {
          let scannedProductsParams = {
            dateRangeStart,
            dateRangeEnd,
            selectedStores,
            selectedSellersIds
          }
          if (this.expandedFilters) {
            scannedProductsParams.selectedCountries = this.selectedCountries
          }
          let scannedProducts = await productService.getScannedProducts(scannedProductsParams)

          let duration = moment.duration(moment(dateRangeEnd).diff(moment(dateRangeStart)))
          let previousPeriodStartDate = moment(dateRangeStart).subtract(duration).subtract(1, 'd').format('YYYY-MM-DD')
          let previousPeriodEndDate = moment(dateRangeEnd).subtract(duration).subtract(1, 'd').format('YYYY-MM-DD')
          let params = {
            dateRangeStart,
            dateRangeEnd,
            selectedStores,
            selectedSellersIds
          }
          let paramsPrevious = {
            previousPeriodStartDate,
            previousPeriodEndDate,
            selectedStores,
            selectedSellersIds
          }
          if (this.expandedFilters) {
            params.selectedCurrencies = this.selectedCurrencies
            paramsPrevious.selectedCurrencies = this.selectedCurrencies
            params.selectedStatus = selectedStatus
            paramsPrevious.selectedStatus = selectedStatus
            params.selectedCountries = this.selectedCountries
            paramsPrevious.selectedCountries = this.selectedCountries
            params.newCurrency = this.selectedConvert
            paramsPrevious.newCurrency = this.selectedConvert
          }
          let salesStats = await saleService.getSalesStats(params)
          let previousSalesStats = await saleService.getSalesStats(paramsPrevious)

          this.sales = await saleService.getSales(params)
          this.indicatorsValues.currency = this.selectedConvert
          let customersCreations = await customerService.getAllCustomersCreations(params)
          let previousCustomersCreations = await customerService.getAllCustomersCreations(paramsPrevious)
          this.scannedProducts = scannedProducts
          this.formatScannedProducts(this.scannedProducts)
          this.customersCreations = customersCreations
          this.previousCustomersCreations = previousCustomersCreations

          this.indicatorsValues.totalRevenues = salesStats.totalRevenues
          this.indicatorsValues.totalSalesNumber = salesStats.totalSalesNumber
          this.indicatorsValues.averageBasket = salesStats.averageBasket
          this.indicatorsValues.totalSoldProducts = salesStats.totalSoldProducts
          this.indicatorsValues.productPerSaleAverage = salesStats.productPerSaleAverage
          this.indicatorsValues.transferredSalesNumber = salesStats.transferredSalesNumber

          this.indicatorsValues.totalRevenuesPrevious = previousSalesStats.totalRevenues
          this.indicatorsValues.totalSalesNumberPrevious = previousSalesStats.totalSalesNumber
          this.indicatorsValues.averageBasketPrevious = previousSalesStats.averageBasket
          this.indicatorsValues.totalSoldProductsPrevious = previousSalesStats.totalSoldProducts
          this.indicatorsValues.productPerSaleAveragePrevious = previousSalesStats.productPerSaleAverage
          this.indicatorsValues.transferredSalesNumberPrevious = previousSalesStats.transferredSalesNumber

          this.dateRangeForCharts = this.dateRange
          this.chartLoading = false
        } catch (e) {
          console.error(e)
          this.chartLoading = false
        }
      },
      setDate(period) {
        switch (period) {
          case 'd':
            this.dateRange.start = moment().format('YYYY-MM-DD')
            break
          case 'w':
            this.dateRange.start = moment().subtract(1, 'weeks').format('YYYY-MM-DD')
            break
          case 'm':
            this.dateRange.start = moment().subtract(1, 'months').format('YYYY-MM-DD')
            break
          case 'y':
            this.dateRange.start = moment().subtract(1, 'years').format('YYYY-MM-DD')
            break
        }
        this.dateRange.end = moment().format('YYYY-MM-DD')
        this.initiateGetSalesPreviousAndCurrentPeriod(
          this.dateRange.start,
          this.dateRange.end,
          this.selectedStores,
          this.selectedSellers.map(seller => seller.id_user),
          this.salesFilters.statusFilter
        )
        this.getConnectedDevices()
      },
      searchSellers(query) {
        this.searchSellersLoading = true
        this.foundSellers = this.availableSellers.filter(seller => {
          return query
            .toLowerCase()
            .split(' ')
            .every(word => {
              return `${seller.user.name_user}${seller.user.firstname_user}`.toLowerCase().includes(word)
            })
        })
        this.foundSellers = [...this.foundSellers, ...this.selectedSellers]
        this.searchSellersLoading = false
      },
      async getConnectedDevices() {
        let connectedDevices = await connectedDevicesService.getAllConnectedDevices(this.dateRange)
        this.connectedDevices = connectedDevices.devices
      }
    },
    async mounted() {
      this.setAppLoader(true)
      // get currencies
      this.currencies = await currencyService.getAllCurrencies()

      // get countries
      this.countries = await countryService.getAllCountries()

      // Set all sellers, sort them,
      if (this.$store.state.allSellers.length > 0) {
        this.allSellers = this.$store.state.allSellers
        this.allSellers.sort((a, b) => {
          if (a.user.name_user > b.user.name_user) {
            return 1
          }
          if (b.user.name_user > a.user.name_user) {
            return -1
          }
        })
        for (let seller of this.allSellers) {
          Object.freeze(seller)
        }
      }

      if (
        this.$store.state.currentUser.data &&
        this.$store.state.currentUser.data.dataBackoffice &&
        this.$store.state.currentUser.data.dataBackoffice.userStores
      ) {
        this.stores = this.$store.state.currentUser.data.dataBackoffice.userStores
        this.stores.sort((a, b) => {
          if (a.name_store > b.name_store) {
            return 1
          }
          if (b.name_store > a.name_store) {
            return -1
          }
        })
      } else {
        this.stores = []
        this.noStoresAssigned = true
      }
      this.selectedStores = this.stores.map(store => {
        return store.id_store
      })
      this.actualizeData()

      this.debouncedSearchSellers = _.debounce(this.searchSellers, 1000)
      this.setAppLoader(false)
    },
    watch: {
      selectedStores() {
        let availableSellers = []
        for (let storeId of this.selectedStores) {
          for (let seller of this.allSellers) {
            if (storeId === seller.id_store) {
              availableSellers.push(seller)
            }
          }
        }
        this.availableSellers = availableSellers
      },
      'dateRange.start'(newValue) {
        this.dateRangeStartFormatted = moment(newValue).format('DD/MM/YYYY')
      },
      'dateRange.end'(newValue) {
        this.dateRangeEndFormatted = moment(newValue).format('DD/MM/YYYY')
      },
      searchSellersValue(query) {
        this.searchSellersLoading = true
        if (query) {
          this.debouncedSearchSellers(query)
        } else {
          this.foundSellers = [...this.selectedSellers]
        }
        this.searchSellersLoading = false
      }
    }
  }
</script>
<style lang="scss">
  .card--flex-toolbar {
    margin-top: -64px;
  }

  .filters {
    overflow: hidden;
    max-height: 0;
    transition-property: max-height;
    transition-duration: 0.5s;
    padding: 0 !important;
  }

  .expand-filters {
    height: auto;
    max-height: 400px;
    min-height: 150px;
    overflow-y: auto;
    transition-property: max-height;
    transition-duration: 0.1s;
    padding-top: 12px !important;
  }

  .choosePeriod {
    align-self: center;
    text-align: center;
  }

  .more_filters {
    text-align: center;

    .more_filters_btn {
      width: 100%;
    }
    .refresh_btn {
      width: 100%;
      text-align: center;
    }
  }

  .filter-row {
    margin-top: 30px;
  }
</style>
