import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/styles/styles.sass'
import { store } from '@/store'
import fr from 'vuetify/es5/locale/fr'
import en from 'vuetify/es5/locale/en'
import 'font-awesome/css/font-awesome.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  iconfont: 'md fa4',
  theme: {
    themes: {
      light: {
        primary: store.state.swatch.colorScheme.primary,
        /* secondary: colors.pink.base,
        accent: store.state.swatch.colorScheme.primary,
        error: colors.red.accent4,
        info: colors.blue.lighten1,
        success: colors.green.accent4,
        warning: colors.amber.darken2 */
        secondary: '#e91e63',
        accent: '#cca256',
        error: '#d50000',
        info: '#42a5f5',
        success: '#00c853',
        warning: '#ffa000'
      }
    }
  },
  options: {
    themeVariations: ['primary', 'secondary']
  },
  lang: {
    locales: { en, fr },
    current: 'fr'
  }
})
