export default [
  {
    title: 'customs.menu.dashboard',
    icon: 'dashboard',
    iconUrl: require('@/assets/dashboard.png'),
    color: 'primary',
    nameAccess: 'canAccessDashboard',
    urlName: 'Dashboard',
    accessForAdmin: false
  },
  {
    title: 'customs.menu.administration',
    icon: 'supervisor_account',
    iconUrl: require('@/assets/user.png'),
    color: 'primary lighten-1',
    nameAccess: 'canAccessAdministration',
    urlName: 'Users',
    accessForAdmin: true
  },
  {
    title: 'customs.menu.inventory',
    icon: 'store',
    iconUrl: require('@/assets/inventory.png'),
    color: 'primary',
    nameAccess: 'canAccessInventory',
    urlName: 'Inventory',
    accessForAdmin: false
  },
  {
    title: 'customs.menu.valorization',
    icon: 'mdi-basket',
    iconUrl: require('@/assets/basket.png'),
    color: 'primary lighten-1',
    nameAccess: 'canAccessValorization',
    urlName: 'ValorizationRuleList',
    accessForAdmin: true
  },
  /* {
    title: 'customs.menu.reporting',
    icon: 'mdi-finance',
    iconUrl: require('@/assets/reporting.png'),
    color: 'primary lighten-1',
    nameAccess: 'canAccessReporting',
    urlName: 'Reporting',
    accessForAdmin: true
  }, */
  {
    title: 'customs.menu.releaseNotes',
    icon: 'update',
    iconUrl: require('@/assets/release-notes.png'),
    color: 'primary',
    urlName: 'ReleaseNotes',
    accessForAdmin: false
  }
]
