<template>
  <v-row>
    <v-col col="12">
      <v-card>
        <reporting-list :loading="loading" :reportingList="reporting"></reporting-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { reportingService } from '@/services/reporting.service'
  import ReportingList from '@/components/Reporting/ReportingList'

  export default {
    name: 'GetReporting',
    components: {
      ReportingList
    },
    data() {
      return {
        reporting: [],
        loading: true
      }
    },
    async mounted() {
      try {
        this.reporting = await reportingService.getReporting()
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }
  }
</script>

<style></style>
