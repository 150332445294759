import { API } from './core/api-core.service'

export const saleService = {
  /**
   * Get sales
   * @param params
   */
  getSales(params) {
    let query = {
      store_ids: params.selectedStores
    }
    if (params.dateRangeStart) {
      query.startDate = params.dateRangeStart
    }
    if (params.dateRangeEnd) {
      query.endDate = params.dateRangeEnd
    }
    if (params.previousPeriodStartDate) {
      query.startDate = params.previousPeriodStartDate
    }
    if (params.previousPeriodEndDate) {
      query.endDate = params.previousPeriodEndDate
    }
    if (params.selectedSellersIds && params.selectedSellersIds.length > 0) {
      query.seller_ids = params.selectedSellersIds
    }
    if (typeof params.selectedStatus !== 'undefined' && params.selectedStatus !== null) {
      query.status = params.selectedStatus
    }
    if (params.selectedCurrencies && params.selectedCurrencies.length > 0) {
      query.currency_ids = params.selectedCurrencies
    }
    if (params.newCurrency) {
      query.newCurrency = params.newCurrency
    }
    if (params.selectedCountries && params.selectedCountries.length > 0) {
      query.country_ids = params.selectedCountries
    }
    return API.request('get', 'sso/sales', query, null)
  },

  /**
   * Get sales stats
   * @param params
   */
  getSalesStats(params) {
    let query = {
      store_ids: params.selectedStores
    }
    if (params.dateRangeStart) {
      query.startDate = params.dateRangeStart
    }
    if (params.dateRangeEnd) {
      query.endDate = params.dateRangeEnd
    }
    if (params.previousPeriodStartDate) {
      query.startDate = params.previousPeriodStartDate
    }
    if (params.previousPeriodEndDate) {
      query.endDate = params.previousPeriodEndDate
    }
    if (params.selectedSellersIds && params.selectedSellersIds.length > 0) {
      query.seller_ids = params.selectedSellersIds
    }
    if (typeof params.selectedStatus !== 'undefined' && params.selectedStatus !== null) {
      query.status = params.selectedStatus
    }
    if (params.selectedCurrencies && params.selectedCurrencies.length > 0) {
      query.currency_ids = params.selectedCurrencies
    }
    if (params.newCurrency) {
      query.newCurrency = params.newCurrency
    }
    if (params.selectedCountries && params.selectedCountries.length > 0) {
      query.country_ids = params.selectedCountries
    }
    return API.request('get', 'sso/sales/stats', query, null)
  }
}
