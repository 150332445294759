import { API } from './core/api-core.service'

export const productService = {
  /**
   * Get configuration
   */
  async getScannedProducts(params) {
    let query = {
      store_ids: params.selectedStores
    }
    if (params.dateRangeStart) {
      query.startDate = params.dateRangeStart
    }
    if (params.dateRangeEnd) {
      query.endDate = params.dateRangeEnd
    }
    if (params.selectedSellersIds && params.selectedSellersIds.length > 0) {
      query.sellers_id = params.selectedSellersIds
    }
    if (params.selectedCountries && params.selectedCountries.length > 0) {
      query.country_ids = params.selectedCountries
    }

    let scannedProducts = await API.request('GET', 'sso/scanned-products', query)
    return scannedProducts
  }
}
