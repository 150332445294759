<template>
  <div id="full-loader">
    <div class="loader-container">
      <div class="loader-items">
        <div class="loader-svg">
          <svg class="svg-primary fade-1">
            <ellipse cx="30" cy="20" rx="12" ry="15" stroke="black" stroke-width="4" fill="white" />
            <rect width="59" height="31" fill="black" stroke-width="1" stroke="black" y="19" />
          </svg>
          <svg class="svg-secondary fade-2">
            <rect width="59" height="6" fill="black" stroke-width="1" stroke="black" />
          </svg>
          <svg class="svg-secondary fade-3">
            <rect width="59" height="6" fill="black" stroke-width="1" stroke="black" />
          </svg>
          <svg class="svg-secondary fade-4">
            <rect width="59" height="6" fill="black" stroke-width="1" stroke="black" />
          </svg>
        </div>
        <div class="loader-text">
          <h1>Octipas</h1>
          <h5 class="primary--text">{{ $t('customs._loader.waitingText') }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppLoader',
    data() {
      return {
        loader: false
      }
    }
  }
</script>

<style>
  .loader-container {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    min-width: 100vw;
    z-index: 10;
  }

  .loader-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: relative;
    top: 50vh;
    transform: translateY(-50%);
  }

  .loader-svg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: fit-content;
    margin: auto;
  }

  .svg-primary {
    width: 60px;
    height: 50px;
    margin-bottom: 5px;
  }

  .svg-secondary {
    width: 60px;
    height: 5px;
    margin-bottom: 5px;
  }

  .loader-text {
    text-align: center;
    width: fit-content;
    margin: auto;
  }

  .fade-1 {
    -webkit-animation-name: fadeInAndOutFromLeft4;
    animation-name: fadeInAndOutFromLeft1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .fade-2 {
    -webkit-animation-name: fadeInAndOutFromLeft3;
    animation-name: fadeInAndOutFromLeft2;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .fade-3 {
    -webkit-animation-name: fadeInAndOutFromLeft2;
    animation-name: fadeInAndOutFromLeft3;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .fade-4 {
    -webkit-animation-name: fadeInAndOutFromLeft1;
    animation-name: fadeInAndOutFromLeft4;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @keyframes fadeInAndOutFromLeft1 {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    25% {
      opacity: 1;
      transform: translateX(0);
    }
    65% {
      opacity: 1;
      transform: translateX(0);
    }
    83% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      transform: translateX(60px);
    }
  }

  @keyframes fadeInAndOutFromLeft2 {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    30% {
      opacity: 1;
      transform: translateX(0);
    }
    70% {
      opacity: 1;
      transform: translateX(0);
    }
    88% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      transform: translateX(60px);
    }
  }

  @keyframes fadeInAndOutFromLeft3 {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    35% {
      opacity: 1;
      transform: translateX(0);
    }
    75% {
      opacity: 1;
      transform: translateX(0);
    }
    93% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      transform: translateX(60px);
    }
  }

  @keyframes fadeInAndOutFromLeft4 {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    40% {
      opacity: 1;
      transform: translateX(0);
    }
    80% {
      opacity: 1;
      transform: translateX(0);
    }
    98% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      transform: translateX(60px);
    }
  }
</style>
