const usersAdministrationMixins = {
  methods: {
    administrationSnackbar: function (self, fctToEmit, type, msg, icon) {
      self.loader = false
      self.show = false
      self.$emit(fctToEmit, {
        display: true,
        type: type,
        msg: msg,
        icon: icon
      })
    }
  }
}

export default usersAdministrationMixins
