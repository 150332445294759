<template>
  <v-app :dark="colorScheme.dark" :class="colorScheme.scheme" style="font-family: 'Malgun', sans-serif">
    <AppLoader v-if="globalLoader" />
    <v-progress-linear
      v-if="$store.state.appLoader"
      fixed
      height="5"
      indeterminate
      active
      background-color="transparent"
      top
      style="z-index: 100"
    />
    <v-snackbar v-model="snackbar.display" :color="snackbar.color" bottom right multi-line :timeout="6000">
      {{ $t(`customs.snackbarMessages.${snackbar.textToTranslate}`) }}
      {{ snackbar.text }}
      <v-btn text @click="snackbar.display = false">
        {{ $t('customs.menu.close') }}
      </v-btn>
    </v-snackbar>
    <router-view v-if="!globalLoader" name="header" />
    <router-view v-if="!globalLoader" name="sidebar" />
    <v-main v-if="!globalLoader">
      <router-view />
    </v-main>
    <!--<router-view name="footer" />-->
    <!--<app-settings :drawer="subDrawer" @toggleSubDrawer="handleSubdrawer"></app-settings>-->
    <!-- Toggle settings button -->
    <!--<v-btn fixed class="settings-btn" @click.stop="subDrawer = !subDrawer">
      <v-icon light>settings</v-icon>
    </v-btn>-->
  </v-app>
</template>
<script>
  import { mapGetters } from 'vuex'
  /* import AppSettings from './components/AppSettings' */
  import AppLoader from './components/AppLoader'
  import { usersService } from './services/sso-users.service.js'
  import { generalService } from './services/general.service.js'
  import { EventBus } from './event-bus'

  export default {
    data() {
      return {
        subDrawer: false,
        globalLoader: true,
        snackbar: {
          display: false,
          text: '',
          textToTranslate: '',
          color: ''
        }
      }
    },
    computed: {
      ...mapGetters(['colorScheme'])
    },
    components: {
      /* AppSettings, */
      AppLoader
    },
    methods: {
      handleSubdrawer(value) {
        this.subDrawer = value
      },
      async login() {
        let token = await usersService.requestToken({
          client_id: process.env.VUE_APP_SSO_APP_ID,
          code: this.$router.currentRoute.query.code,
          grant_type: 'authorization_code',
          redirect_uri: process.env.VUE_APP_SSO_REDIRECT_URL
        })

        if (typeof token === 'object' || !JSON.parse(token).error) {
          await this.$store.dispatch('setFusionAuthToken', token)
        } else {
          await usersService.logout()
        }

        return token
      }
    },
    async created() {
      EventBus.$on('displaySnackbar', payload => {
        this.snackbar = payload
      })
      try {
        await usersService.validateToken()

        await usersService.logUser()

        if (!this.$store.state.userHasMultiGroups) {
          await generalService.getAPIPartnerInfos()
        }

        this.globalLoader = false
      } catch (e) {
        console.error(e)
        this.globalLoader = false
      }
    }
  }
</script>
