<template>
  <v-card class="elevation-4 border-radius6">
    <v-card-title>
      <div class="title">{{ $t('customs.scannedProducts.scannedProducts') }}</div>
      <v-spacer />
      <export-as-csv
        :headers="headersForCSV"
        :data="dataForCSV"
        :file-name="`scanned-products-list-${startDate}-to-${endDate}`"
      />
    </v-card-title>

    <v-container fluid>
      <v-row>
        <v-col lg="1" md="3" sm="6">
          <v-btn color="primary" @click="expandedFilters = !expandedFilters">
            {{ $t('customs.salesBoard.filters') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="filters" :class="{ 'expand-filters': expandedFilters }">
      <v-row align="center">
        <!-- Ref filter -->
        <v-col lg="3" md="6" sm="12" cols="12">
          <v-text-field v-model="scannedProductsFilters.barcodeFilter" :label="$t('customs.salesBoard.searchByRef')" />
        </v-col>

        <!-- Seller filter -->
        <v-col lg="3" md="6" sm="12" cols="12">
          <v-text-field
            v-model="scannedProductsFilters.sellerFilter"
            :label="$t('customs.salesBoard.searchBySeller')"
          />
        </v-col>

        <!-- Store filter -->
        <v-col lg="3" md="6" sm="12" cols="12">
          <v-text-field v-model="scannedProductsFilters.storeFilter" :label="$t('customs.salesBoard.searchByStore')" />
        </v-col>
      </v-row>
    </v-container>

    <!-- Top products board -->
    <v-card-text class="pa-0">
      <template>
        <v-data-table
          :headers="headers"
          :items="filteredScannedProducts"
          :loading="loading"
          :footer-props="{
            'items-per-page-options': rowsPerPage,
            'items-per-page-text': $t('customs.salesBoard.rowsPerPage')
          }"
          class="elevation-0 table-striped border-radius6"
        >
          <template v-slot:item="{ item }">
            <!-- For each product line -->
            <tr>
              <td class="text-center">{{ item.product.barcode_product }}</td>
              <td class="text-center">{{ item.scan_count_scanned_product }}</td>
              <td class="text-center">{{ item.user ? item.user.fullname_user : '' }}</td>
              <td class="text-center">{{ item.store.name_store | capitalize() }}</td>
            </tr>
          </template>
        </v-data-table>
      </template>
      <v-divider />
    </v-card-text>
  </v-card>
</template>

<script>
  import { Helpers } from '@/mixins/Helpers.js'
  import ExportAsCsv from '@/components/ExportAsCsv.vue'
  const _ = require('lodash')

  export default {
    name: 'ScannedProductsListIndicator',
    props: {
      scannedProducts: Array,
      startDate: String,
      endDate: String,
      loading: Boolean
    },
    components: {
      ExportAsCsv
    },
    mixins: [Helpers],
    data() {
      return {
        expandedFilters: false,
        filteredScannedProducts: [],
        scannedProductsFilters: {
          barcodeFilter: '',
          sellerFilter: '',
          storeFilter: ''
        },
        rowsPerPage: [10, 20, 30, 40, 50, { text: 'All', value: -1 }],
        headers: [
          {
            text: this.$t('customs.scannedProducts.barcode'),
            value: 'product.barcode_product',
            sortable: false,
            align: 'center'
          },
          {
            text: this.$t('customs.scannedProducts.scanNumber'),
            value: 'scan_count_scanned_product',
            sortable: true,
            align: 'center'
          },
          {
            text: this.$t('customs.scannedProducts.seller'),
            value: 'user.fullname_user',
            sortable: true,
            align: 'center'
          },
          {
            text: this.$t('customs.scannedProducts.store'),
            value: 'store.name_store',
            sortable: true,
            align: 'center'
          }
        ],
        headersForCSV: ['Barcode', 'ScanNumber', 'Seller', 'Store']
      }
    },
    computed: {
      dataForCSV() {
        let data = []
        for (let product of this.scannedProducts) {
          let productData = []
          productData.push(product.product.barcode_product)
          productData.push(product.scan_count_scanned_product)
          productData.push(product.user ? product.user.fullname_user : null)
          productData.push(product.store.name_store)
          data.push(productData)
        }
        return data
      }
    },
    methods: {
      formatScannedProducts(newScannedProducts) {
        if (newScannedProducts.length > 0) {
          this.filteredScannedProducts = _.cloneDeep(this.scannedProducts)
        } else {
          this.filteredScannedProducts = []
        }
      }
    },
    watch: {
      scannedProducts(newScannedProducts) {
        this.formatScannedProducts(newScannedProducts)
      },
      scannedProductsFilters: {
        handler(newFilters) {
          this.filteredScannedProducts = this.scannedProducts.filter(scannedProduct => {
            if (newFilters.barcodeFilter !== '') {
              if (!scannedProduct.product.barcode_product.includes(newFilters.barcodeFilter)) {
                return false
              }
            }
            if (newFilters.sellerFilter !== '') {
              if (!scannedProduct.user.fullname_user.toLowerCase().includes(newFilters.sellerFilter.toLowerCase())) {
                return false
              }
            }
            if (newFilters.storeFilter !== '') {
              if (!scannedProduct.store.name_store.toLowerCase().includes(newFilters.storeFilter.toLowerCase())) {
                return false
              }
            }
            return true
          })
        },
        deep: true
      }
    }
  }
</script>

<style>
  .filters {
    overflow: hidden;
    max-height: 0;
    transition-property: max-height;
    transition-duration: 0.5s;
  }

  .expand-filters {
    height: auto;
    max-height: 400px;
    overflow-y: auto;
    transition-property: max-height;
    transition-duration: 0.5s;
  }
</style>
