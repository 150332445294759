import { API } from './core/api-core.service'

export const countryService = {
  /**
   * Get all currencies
   */
  getAllCountries() {
    return API.request('get', 'sso/countries')
  }
}
