<template>
  <v-container class="homeContent">
    <v-container class="homeMenu">
      <router-link :to="{ name: 'CreateInventory' }">
        <button class="buttonCreate">{{ $t('customs.inventory.create_inventory') }}</button>
      </router-link>

      <router-link :to="{ name: 'InventoryInProgress' }">
        <button>{{ $t('customs.inventory.inventory_in_progess') }}</button>
      </router-link>

      <router-link :to="{ name: 'InventoryHistory' }">
        <button>{{ $t('customs.inventory.history') }}</button>
      </router-link>
      <button>{{ $t('customs.inventory.stat') }} (A venir...)</button>
    </v-container>

    <v-snackbar v-model="snackbar" bottom>
      {{ text }}
      <v-btn color="green" @click="snackbar = false"> Fermer </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        snackbar: false,
        mode: '',
        text: ''
      }
    },
    methods: {
      getText(time) {
        switch (time) {
          case 0:
            this.text = "Inventaire en cours de création il sera visible d'ici quelques secondes"
            break
          case 1:
            this.text = "Inventaire en cours de création il sera visible d'ici quelques minutes"
            break
          case time > 2:
            this.text = "Inventaire en cours de création il sera visible d'ici plusieurs minutes"
            break
        }
      }
    },
    mounted() {
      let isInventoryCreated = this.$route.params.created
      if (isInventoryCreated) {
        this.getText(isInventoryCreated)
        this.snackbar = true
      }
    }
  }
</script>

<style scoped lang="scss">
  .homeContent {
    .homeMenu {
      button {
        width: 100%;
        padding: 10% 0 10% 0;
        font-size: 17px;
      }
    }
  }
</style>
