<template>
  <v-container mt-10>
    <v-row v-if="getUserHasChosenGroup" justify="center">
      <v-col v-for="(tile, key) in allowedTiles" :key="key" lg="3" md="4" sm="6" style="display: flex">
        <v-card class="menu-tile" :color="tile.color" hover @click="$router.push({ name: tile.urlName })">
          <v-card-title>
            <h1 class="white--text text-center app-card-title">{{ $t(tile.title) }}</h1>
          </v-card-title>
          <v-card-text>
            <v-img :src="tile.iconUrl" min-height="100" max-height="200" contain />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else align="center" justify="center">
      <v-col cols="6" lg="4" md="8" sm="8">
        <v-select
          v-model="selectedGroup"
          :items="userGroups"
          item-text="name"
          :label="$t('customs.menu.groups')"
          :loading="loading"
          :disabled="loading"
          return-object
        />
      </v-col>
      <v-col cols="4" lg="1" md="2" sm="2">
        <v-btn color="primary" @click="setGroup" :disabled="!selectedGroup" :loading="loading">{{
          $t('customs.menu.validate')
        }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import menuTiles from '../data/menuTiles'
  import { usersService } from '../services/sso-users.service.js'
  import { storeService } from '../services/store.service'
  import { sellerService } from '../services/seller.service'
  import { generalService } from '../services/general.service.js'
  import { mapActions } from 'vuex'

  export default {
    name: 'Home',
    data() {
      return {
        menuTiles: menuTiles,
        selectedGroup: null,
        userGroups: [],
        loading: false,
        displayTiles: false
      }
    },
    computed: {
      getUserData() {
        return this.$store.state.currentUser.data
      },
      allowedTiles() {
        if (this.getUserData && this.getUserData.dataBackoffice) {
          return this.menuTiles.filter(tile => {
            if (tile.hasOwnProperty('nameAccess')) {
              return (
                this.getUserData.dataBackoffice &&
                this.getUserData.dataBackoffice[tile.nameAccess] === true &&
                (!tile.accessForAdmin || this.$store.state.isAdmin === tile.accessForAdmin)
              )
            } else {
              return true
            }
          })
        } else {
          return false
        }
      },
      userHasMultiGroups() {
        return this.$store.state.userHasMultiGroups
      },
      getCurrentGroup() {
        return this.$store.state.currentGroup
      },
      getUserHasChosenGroup() {
        return this.$store.state.userHasChosenGroup
      }
    },
    methods: {
      ...mapActions(['setPartnerId', 'setCurrentUser']),
      async setGroup() {
        this.loading = true
        try {
          await this.$store.dispatch('setCurrentGroup', this.selectedGroup)
          await this.getConfigAndStores()
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },
      async getConfigAndStores() {
        // Get and set partner config
        await this.setPartnerId(require('../config/partnerIds.json')[this.getCurrentGroup.name])
        await generalService.getPartnerConfig()

        if (
          this.$store.state.currentPartnerConfig &&
          this.$store.state.currentPartnerConfig.hasOwnProperty('apiLink') &&
          this.$store.state.currentPartnerConfig.apiLink !== null &&
          this.$store.state.currentPartnerConfig.apiLink !== ''
        ) {
          let stores = await storeService.getAllStores()
          await this.$store.dispatch('setAllStores', stores)
          let sellers = await sellerService.getAllSellers()
          await this.$store.dispatch('setAllSellers', sellers)
        }

        if (this.$store.state.isOctipasAdmin) {
          let user = this.$store.state.currentUser
          user.data = {}
          user.data.dataBackoffice = {
            ...this.$store.state.currentPartnerConfig.defaultUserConfiguration,
            userStores: [
              ...this.$store.state.allStores.map(store => {
                return { id_store: store.id_store, name_store: store.name_store, external_id: store.external_id }
              })
            ]
          }
          user = await usersService.updateUser({ user: user, registration: user.registrations[0] })
          await this.$store.dispatch('setCurrentUser', user.user)
        } else {
          let user = this.$store.state.currentUser
          user.data = {}
          user.data.dataBackoffice = {
            ...this.$store.state.currentPartnerConfig.defaultUserConfiguration
          }
          user = await usersService.updateUser({ user: user, registration: user.registrations[0] })
          await this.$store.dispatch('setCurrentUser', user.user)
        }

        await this.$store.dispatch('setUserHasChosenGroup', true)
        this.displayTiles = true
      }
    },
    async mounted() {
      if (this.userHasMultiGroups) {
        let groups = []
        for (let group of this.$store.state.currentUser.memberships) {
          let g = await usersService.getGroupById(group.groupId)
          groups.push(g.group)
        }
        this.userGroups = groups
      } else {
        this.displayTiles = true
        if (!this.$store.state.currentUser.hasOwnProperty('data') || !this.$store.state.currentUser.data) {
          let user = this.$store.state.currentUser
          user.data = {}
          user.data.dataBackoffice = {
            ...this.$store.state.currentPartnerConfig.defaultUserConfiguration
          }
          user = await usersService.updateUser({ user: user, registration: user.registrations[0] })
          await this.setCurrentUser(user.user)
        }
      }
    }
  }
</script>

<style scoped>
  .menu-tile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .app-card-title {
    width: 100%;
  }

  @media screen and (max-width: 360px) and (orientation: portrait) {
    .menu-tile {
      min-height: 40vh;
    }
  }

  @media screen and (min-width: 361px) and (orientation: portrait) {
    .menu-tile {
      min-height: 35vh;
    }
  }

  @media screen and (min-width: 600px) and (orientation: portrait) {
    .menu-tile {
      min-height: 30vh;
    }
  }

  @media screen and (min-width: 900px) and (orientation: portrait) {
    .menu-tile {
      min-height: 25vh;
    }
  }

  @media screen and (min-width: 1200px) and (orientation: portrait) {
    .menu-tile {
      min-height: 20vh;
      background-color: red;
    }
  }

  @media screen and (min-width: 1800px) and (orientation: portrait) {
    .menu-tile {
      min-height: 15vh;
    }
  }

  @media screen and (max-width: 360px) and (orientation: landscape) {
    .menu-tile {
      min-height: 55vh;
    }
  }

  @media screen and (min-width: 361px) and (orientation: landscape) {
    .menu-tile {
      min-height: 50vh;
    }
  }

  @media screen and (min-width: 600px) and (orientation: landscape) {
    .menu-tile {
      min-height: 45vh;
    }
  }

  @media screen and (min-width: 900px) and (orientation: landscape) {
    .menu-tile {
      min-height: 40vh;
    }
  }

  @media screen and (min-width: 1200px) and (orientation: landscape) {
    .menu-tile {
      min-height: 35vh;
    }
  }

  @media screen and (min-width: 1800px) and (orientation: landscape) {
    .menu-tile {
      min-height: 30vh;
    }
  }
</style>
