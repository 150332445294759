<template>
  <!--:class="[navDrawerAlphaMod ? 'alpha' : '', navDrawerScheme, navDrawerVariant ? 'google-material-effect' : '']"-->
  <v-navigation-drawer
    class="pb-0 vuse-sidebar google-material background-light"
    :class="[navDrawerAlphaMod ? 'alpha' : '', navDrawerVariant ? 'google-material-effect' : '']"
    persistent
    fixed
    width="260"
    :mini-variant.sync="navMiniVarient"
    :clipped="clipped"
    v-model="drawer"
    :left="navDrawerPosition === 'left'"
    :right="navDrawerPosition === 'right'"
    enable-resize-watcher
    floating
    id="app-drawer"
    app
    dark
    style="border-right: solid #e6e6e6 1px; background-color: #363636"
  >
    <!-- Drawer Toolbar -->
    <v-img
      :style="navMiniVarient ? 'margin: 10px' : 'margin: 25px'"
      class="octi-logo"
      :src="logo"
      @click="$router.push({ name: 'Home' })"
    >
    </v-img>
    <v-divider></v-divider>
    <!-- Drawer Items -->
    <v-row justify="space-between" column>
      <v-list dense="dense" expand="expand" id="app-sidebar">
        <template v-for="(item, i) in allowedTiles">
          <!--group with subitems-->
          <v-list-group
            v-if="item.items"
            :group="item.group"
            :prepend-icon="item.icon"
            no-action
            :key="`${i}-item`"
            active-class="act-menu"
          >
            <v-list-item slot="activator" ripple>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-for="(subItem, i) in item.items">
              <!--sub group-->
              <v-list-group v-if="subItem.items" :group="subItem.group" sub-group :key="`${i}-subitems`">
                <v-list-item slot="activator" ripple class="list__tile-inner-group">
                  <v-list-item-action v-if="subItem.icon">
                    <v-icon>{{ subItem.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-action v-else>
                    <v-avatar size="28">
                      <span>{{ subItem.title | first2Char }}</span>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-for="(grand, i) in subItem.items"
                  :key="i"
                  :to="genChildTarget(item, grand)"
                  :href="grand.href"
                  ripple
                >
                  <v-list-item-action v-if="grand.icon">
                    <v-icon>{{ grand.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-action v-else>
                    <v-avatar size="28">
                      <span>{{ grand.title | first2Char }}</span>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ grand.title }}</v-list-item-title>
                  </v-list-item-content>
                  <v-chip v-if="grand.badge" color="primary" disabled class="white--text pa-0 chip--x-small">{{
                    grand.badge
                  }}</v-chip>
                </v-list-item>
              </v-list-group>

              <!--child item-->
              <v-list-item
                v-else
                :key="i"
                :to="genChildTarget(item, subItem)"
                :href="subItem.href"
                :disabled="subItem.disabled"
                :target="subItem.target"
                ripple
              >
                <v-list-item-action>
                  <v-icon v-if="subItem.prependIcon">{{ subItem.prependIcon }}</v-icon>
                  <v-avatar size="28" v-else>
                    <span>{{ subItem.title | first2Char }}</span>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content class>
                  <v-list-item-title>
                    <span>{{ subItem.title }}</span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-chip v-if="subItem.badge" color="primary" disabled class="white--text pa-0 chip--x-small">{{
                  subItem.badge
                }}</v-chip>
                <v-list-item-action v-if="subItem.action">
                  <v-icon :class="[subItem.actionClass || 'success--text']">{{ subItem.action }}</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-group>
          <v-subheader
            v-else-if="item.header"
            class="heading-text"
            :key="`${i}-item`"
            :class="[i !== 0 ? 'mt-3' : '']"
            >{{ item.header }}</v-subheader
          >
          <v-divider v-else-if="item.divider" :key="`${i}-item`"></v-divider>
          <!--top-level link-->
          <v-list-item
            v-else
            :to="!item.href ? { name: item.urlName } : null"
            :href="item.href"
            ripple
            :disabled="item.disabled"
            :target="item.target"
            rel="noopener"
            :key="`${i}-item`"
            class="list__tile-solo"
          >
            <v-list-item-action>
              <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
              <v-avatar size="28" v-else>
                <span>{{ item.title | first2Char }}</span>
              </v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
            <v-chip
              v-if="item.badge"
              :color="item.color || 'primary'"
              disabled
              class="white--text pa-0 chip--x-small"
              >{{ item.badge }}</v-chip
            >
            <v-list-item-action v-if="item.subAction">
              <v-icon class="success--text">{{ item.subAction }}</v-icon>
            </v-list-item-action>
            <v-chip v-else-if="item.chip" label="label" small="small" class="caption blue lighten-2 white--text mx-0">{{
              item.chip
            }}</v-chip>
          </v-list-item>
        </template>
        <v-spacer class="mb-5"></v-spacer>
      </v-list>
    </v-row>
  </v-navigation-drawer>
</template>
<script>
  import { mapGetters } from 'vuex'
  import appDrawerItems from '@/data/menuTiles'
  import { camel } from '@/utils/helpers'

  export default {
    data() {
      return {
        items: appDrawerItems,
        dark: false,
        logo: '/static/logo-octipas.png'
      }
    },
    mounted() {
      const ps = document.getElementById('app-drawer')
      ps.addEventListener('mouseenter', this.miniEnterVariantHandler)
      ps.addEventListener('mouseleave', this.miniLeaveVariantHandler)
      // If user is admin add the Administration components in the navbar
      /* if (this.$store.state.isAdmin) {
      this.items.push({
        name: 'Users',
        title: 'Administration',
        icon: 'supervisor_account'
      })
    } */
    },
    beforeDestroy() {
      const ps = document.getElementById('app-drawer')
      if (ps) {
        ps.removeEventListener('mouseenter', this.miniEnterVariantHandler)
        ps.removeEventListener('mouseleave', this.miniVariantHandler)
      }
      this.items.map((item, index) => {
        if (item.name === 'Users') {
          this.items.splice(index, 1)
        }
      })
    },
    computed: {
      ...mapGetters({
        clipped: 'navClipped',
        navDrawer: 'navDrawer',
        navMiniVarient: 'navMiniVarient',
        navDrawerPosition: 'navDrawerPosition',
        navDrawerScheme: 'navDrawerScheme',
        navDrawerAlphaMod: 'navDrawerAlphaMod',
        navDrawerAlphaImg: 'navDrawerAlphaImg',
        navDrawerVariant: 'navDrawerVariant',
        fixedFooter: 'fixedFooter',
        showFooter: 'showFooter',
        miniview: 'miniview'
      }),
      getUserData() {
        return this.$store.state.currentUser.data
      },
      allowedTiles() {
        return this.items.filter(tile => {
          if (tile.hasOwnProperty('nameAccess')) {
            return (
              this.getUserData.dataBackoffice &&
              this.getUserData.dataBackoffice[tile.nameAccess] === true &&
              (!tile.accessForAdmin || this.$store.state.isAdmin === tile.accessForAdmin)
            )
          } else {
            return true
          }
        })
      },
      activemenu() {
        return this.$vuetify.theme.activemenu
      },
      drawer: {
        get() {
          return this.navDrawer
        },
        set(value) {
          this.$store.dispatch('toggleDrawer', value)
        }
      }
    },
    methods: {
      miniEnterVariantHandler() {
        // if it's tab or small devive then it won't listen this event
        if (this.miniview && this.navMiniVarient === true && this.$vuetify.breakpoint.mdAndDown === false) {
          this.$store.dispatch('toggleMiniVarient', false)
          // const container = document.querySelector('#app-sidebar')
          // container.scrollTop = 0
        }
      },
      miniLeaveVariantHandler() {
        // if it's tab or small devive then it won't listen this event
        if (this.miniview && this.navMiniVarient === false && this.$vuetify.breakpoint.mdAndDown === false) {
          this.$store.dispatch('toggleMiniVarient', true)
          // const container = document.querySelector('#app-sidebar')
          // container.scrollTop = 0
        }
      },
      genChildTarget(item, subItem) {
        if (subItem.href) return
        if (item.component) {
          return {
            name: item.component,
            params: {
              section: item.group,
              component: subItem.name
            }
          }
        }
        return { name: `${item.group}/${camel(subItem.name)}` }
      }
    }
  }
</script>
<style scoped lang="scss">
  .octi-logo {
    cursor: pointer;
  }
  .background-light {
    background-color: white;
  }

  // .no-overflow
  // overflow: hidden
  #app-drawer {
    img.logo {
      margin: 40px 0 15px;
    }

    .chip--x-small {
      font-size: 10px;
      height: 16px;

      .chip__content {
        line-height: 1;
        padding: 8px;
      }
    }

    .diamond-sponsor {
      // todo trim down actual image file dimensions
      height: 35px;
      margin-bottom: 0.25em;

      aside.navigation-drawer ul li {
        font-size: 14px;
        color: #373737;
      }

      &-label {
        color: #676767;
        margin: 24px 0 16px 0;
        font-size: 13px;
      }
    }
  }
</style>
