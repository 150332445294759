<template>
  <div class="reporting">
    <v-container>
      <v-row align="center">
        <v-tabs v-model="tabs" align="center" fixed-tabs>
          <v-tab><h2>Envoyer un reporting</h2></v-tab>
          <v-tab><h2>Liste de distribution</h2></v-tab>
        </v-tabs>
      </v-row>
    </v-container>
    <div>
      <v-tabs-items v-model="tabs" align="center">
        <v-tab-item>
          <v-container>
            <send-reporting></send-reporting>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <get-reporting></get-reporting>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
  import SendReporting from '../../components/Reporting/SendReporting.vue'
  import GetReporting from '../../components/Reporting/GetReporting.vue'
  export default {
    name: 'Reporting',
    components: {
      SendReporting,
      GetReporting
    },
    data() {
      return {
        tabs: null
      }
    }
  }
</script>

<style></style>
