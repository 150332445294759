import { API } from './core/api-core.service'

export const currencyService = {
  /**
   * Get all currencies
   */
  getAllCurrencies() {
    return API.request('get', 'sso/currencies')
  }
}
