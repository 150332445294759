<template>
  <div class="sendReporting">
    <v-alert colored-border type="info" color="primary" elevation="2">
      {{ $t('customs.reporting.sendReportingInfo') }}
    </v-alert>
    <v-container>
      <v-form ref="emailForm" @submit.prevent="addEmail()">
        <!-- List of email -->
        <v-row class="d-flex align-center my-2">
          <v-col class="d-flex align-center">
            <v-text-field
              label="Entrer une adresse email à ajouter"
              v-model="inputEmail"
              :rules="emailRules"
              prepend-icon="mdi-email"
            ></v-text-field>
            <v-btn class="ml-2 white--text" tile color="primary" small rounded @click="addEmail()">
              <v-icon left> add </v-icon>
              Ajouter
            </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex align-center">
            <v-sheet elevation="2" class="py-4 px-4" rounded width="100%">
              <h4 class="mb-2">Liste des destinataires:</h4>
              <p v-if="listEmails.length == 0">Aucun destinataire</p>
              <v-chip
                v-else
                v-for="(email, index) in listEmails"
                :key="email"
                class="ma-2"
                color="primary"
                outlined
                close
                @click:close="removeEmail(index)"
                >{{ email }}</v-chip
              >
            </v-sheet>
          </v-col>
        </v-row>

        <!-- Select stores -->
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="userStores"
              :items="storeList"
              :label="$t('customs.ssoUsers.usersTabModal.userStoresLabel')"
              item-text="name_store"
              :no-data-text="$t('customs.ssoUsers.usersTabModal.noStoresAvailable')"
              :hint="$t('customs.ssoUsers.usersTabModal.userStoresHint')"
              append-icon="arrow_drop_down"
              prepend-icon="store"
              multiple
              item-value="id_store"
              :disabled="loader"
              chips
            >
              <v-list-item slot="prepend-item" ripple @click="selectAllStore()">
                <v-list-item-action>
                  <v-icon v-if="!selectedAll" color="primary darken-2">select_all</v-icon>
                  <v-icon v-else color="primary darken-1">remove</v-icon>
                </v-list-item-action>
                <v-list-item-title v-if="!selectedAll">{{
                  $t('customs.ssoUsers.usersTabModal.userStoresSelectAll')
                }}</v-list-item-title>
                <v-list-item-title v-else>{{
                  $t('customs.ssoUsers.usersTabModal.userStoresRemoveAll')
                }}</v-list-item-title>
              </v-list-item>
              <template slot="selection" slot-scope="{ item, index }">
                <v-chip v-if="index < 4" outlined color="primary lighten-1">
                  <span>{{ item.name_store }}</span>
                </v-chip>
                <span v-if="index === 4" class="grey--text text-caption"
                  >(+{{ userStores.length - 4 }} {{ $t('customs.ssoUsers.usersTab.others') }})</span
                >
              </template>
            </v-select>
          </v-col>
        </v-row>

        <!-- Select date -->
        <v-row>
          <v-col cols="12" md="6">
            <v-menu
              ref="menu"
              v-model="startDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Date du début"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="startDate" no-title scrollable :allowed-dates="allowedStartDates">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu
              ref="menu"
              v-model="endDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  label="Date de fin"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="endDate" no-title scrollable :allowed-dates="allowedEndDates"> </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <!-- Resume and send btn -->
        <v-row>
          <v-col cols="12">
            <v-btn
              class="ml-2 white--text sendBtn"
              :disabled="sendBtnDisabled"
              tile
              color="primary"
              rounded
              large
              @click="sendReporting()"
            >
              <v-icon left> {{ isSending ? 'more_horiz' : 'send' }} </v-icon>
              {{ isSending ? "En cours d'envoi..." : 'Envoyer le reporting' }}
            </v-btn>
            <v-tooltip right v-if="sendBtnDisabled">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-5" color="grey" dark v-bind="attrs" v-on="on"> info </v-icon>
              </template>
              <p v-if="this.listEmails.length === 0">Aucune adresse mail renseignée</p>
              <p v-if="this.userStores.length === 0">Aucun magasin sélectionné</p>
              <p v-if="!this.startDate">Aucune date de début sélectionnée</p>
              <p v-if="!this.endDate">Aucune date de fin sélectionnée</p>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
  import { reportingService } from '@/services/reporting.service'

  const _ = require('lodash')
  const moment = require('moment')

  export default {
    name: 'sendReporting',
    data() {
      return {
        inputEmail: '',
        emailRules: [
          v =>
            /^$|(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "L'email est incorrect"
        ],
        listEmails: [],
        userStores: this.user != null ? this.user.data.dataBackoffice.userStores : [],
        loader: false,
        selectedAll: false,
        startDateMenu: null,
        endDateMenu: null,
        startDate: '',
        endDate: '',
        allowedStartDates: val => val <= moment().format('YYYY-MM-DD') && (this.endDate ? val <= this.endDate : true),
        allowedEndDates: val => val <= moment().format('YYYY-MM-DD') && (this.startDate ? val >= this.startDate : true),
        isSending: false,
        isValidated: false
      }
    },
    methods: {
      addEmail() {
        if (this.$refs.emailForm.validate() && !this.listEmails.includes(this.inputEmail) && this.inputEmail !== '') {
          this.listEmails.push(this.inputEmail)
        }
      },
      removeEmail(index) {
        this.listEmails.splice(index, 1)
      },
      selectAllStore() {
        this.selectedAll = !this.selectedAll
        if (this.selectedAll) {
          this.userStores = this.storeList
        } else {
          this.userStores = []
        }
      },
      async sendReporting() {
        this.isSending = true
        await reportingService.sendReporting(this.listEmails, this.userStores, this.startDate, this.endDate)
        this.isSending = false
      }
    },
    computed: {
      storeList() {
        let storeList = _.cloneDeep(this.$store.state.allStores)
        storeList.sort((a, b) => {
          if (a.name_store > b.name_store) {
            return 1
          }
          if (b.name_store > a.name_store) {
            return -1
          }
        })
        return storeList
      },
      sendBtnDisabled() {
        return !(this.startDate && this.endDate && this.listEmails.length > 0 && this.userStores.length > 0)
      }
    }
  }
</script>

<style>
  .sendBtn {
    letter-spacing: 0;
  }
</style>
