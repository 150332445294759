import { API } from './core/api-core.service'

export const connectedDevicesService = {
  /**
   * Get the number of users who connected between 2 dates
   * @param params
   */
  getAllConnectedDevices(params) {
    let query = {}
    if (params.start) {
      query.startDate = params.start
    }
    if (params.end) {
      query.endDate = params.end
    }
    return API.request('get', 'sso/connected-devices', query, null)
  }
}
