<template>
  <div>
    <v-card-title>
      <v-spacer />
      <export-as-csv :data="dataForCSV" :file-name="`sales-list-${startDate}-to-${endDate}`" :headers="headersForCSV" />
    </v-card-title>

    <v-container fluid>
      <v-row>
        <v-col lg="1" md="3" sm="6">
          <v-btn color="primary" @click="expandedFilters = !expandedFilters">
            {{ $t('customs.salesBoard.filters') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container :class="{ 'expand-filters': expandedFilters }" class="filters">
      <v-row align="center">
        <!-- Ref filter -->
        <v-col cols="6" lg="3" md="6" sm="6">
          <v-text-field v-model="salesFilters.refFilter" :label="$t('customs.salesBoard.searchByRef')" />
        </v-col>

        <!-- Status filter -->
        <v-col cols="6" lg="3" md="6" sm="6">
          <v-select
            v-model="salesFilters.statusFilter"
            :items="statuses"
            :label="$t('customs.salesBoard.searchByStatus')"
            multiple
          />
        </v-col>

        <!-- Date filter -->
        <v-col cols="6" lg="3" md="6" sm="6">
          <v-menu
            ref="menu"
            v-model="dateFilterWindowOpened"
            :close-on-content-click="false"
            min-width="290px"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-text-field v-model="dateFilter" v-on="on" :label="$t('customs.salesBoard.searchByDate')" readonly />
            </template>
            <v-date-picker v-model="salesFilters.dateFilterBeforeFormat" :locale="navigatorLang" no-title scrollable>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="
                  dateFilterWindowOpened = false
                  salesFilters.dateFilterBeforeFormat = ''
                "
                >Cancel
              </v-btn>
              <v-btn color="primary" text @click="dateFilterWindowOpened = false">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- Seller filter -->
        <v-col cols="6" lg="3" md="6" sm="6">
          <v-text-field v-model="salesFilters.sellerFilter" :label="$t('customs.salesBoard.searchBySeller')" />
        </v-col>

        <!-- Store filter -->
        <v-col cols="12" lg="3" md="6" sm="12">
          <v-text-field v-model="salesFilters.storeFilter" :label="$t('customs.salesBoard.searchByStore')" />
        </v-col>

        <!-- Price filter -->
        <v-col cols="12" lg="6" md="12" sm="12">
          <v-row align="center">
            <v-col cols="3" lg="2" md="2" sm="2">
              <!-- eslint-disable -->
              <v-text-field v-model="salesFilters.priceFilter[0] / 100" readonly suffix="€" type="number" />
              <!-- eslint-enable -->
            </v-col>
            <v-col class="px-3" cols="6" lg="8" md="8" sm="8">
              <v-range-slider v-model="salesFilters.priceFilter" :max="minMaxPrice[1]" :min="minMaxPrice[0]" />
            </v-col>
            <v-col cols="3" lg="2" md="2" sm="2">
              <!-- eslint-disable -->
              <v-text-field v-model="salesFilters.priceFilter[1] / 100" readonly suffix="€" type="number" />
              <!-- eslint-enable -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- Sales board -->
    <v-card-text class="pa-0">
      <template>
        <v-data-table
          :expanded.sync="expanded"
          :footer-props="{
            'items-per-page-options': rowsPerPage,
            'items-per-page-text': $t('customs.salesBoard.rowsPerPage')
          }"
          :headers="headers"
          :items="filteredSales"
          :loading="loading"
          :options="{ sortBy: ['date_sale'], sortDesc: [true] }"
          :single-expand="true"
          class="elevation-0 table-striped border-radius6 plain-order-table"
          item-key="reference_sale"
        >
          <template v-slot:item="{ item, expand, isExpanded }">
            <!-- For each sale line -->
            <tr class="sale-line" @click="expand(!isExpanded)">
              <td class="text-center">{{ item.reference_partner_sale || item.reference_sale }}</td>
              <td class="text-center">
                <v-chip :class="formatStatus(item.validate_sale).color" label text-color="white">
                  {{ formatStatus(item.validate_sale).text }}
                </v-chip>
              </td>
              <td class="text-center">
                {{ formatPrice(item.basket.total_price_order, item.basket.currency.code_currency) }}
                <template v-if="item.basket.old_total_price_order">
                  ({{ formatPrice(item.basket.old_total_price_order, item.basket.currency.old_code_currency) }})
                </template>
              </td>
              <td class="text-center">
                {{ formatPrice(item.basket.total_price_takeaway, item.basket.currency.code_currency) }}
                <template v-if="item.basket.old_total_price_takeaway">
                  ({{ formatPrice(item.basket.old_total_price_takeaway, item.basket.currency.old_code_currency) }})
                </template>
              </td>
              <td class="text-center">
                {{ formatPrice(item.basket.price_discount_include_tax_basket, item.basket.currency.code_currency) }}
                <template v-if="item.basket.old_price_discount_include_tax_basket">
                  ({{
                    formatPrice(
                      item.basket.old_price_discount_include_tax_basket,
                      item.basket.currency.old_code_currency
                    )
                  }})
                </template>
              </td>
              <td class="text-center">{{ $d(new Date(item.date_sale), 'short') }}</td>
              <td class="text-center">{{ item.user.fullname_user }}</td>
              <td class="text-center">{{ item.store.name_store | capitalize() }}</td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ item, headers }">
            <!-- Sale details -->
            <td :colspan="headers.length" class="sale-details">
              <v-container>
                <v-row>
                  <v-col cols="12" lg="3" md="4" sm="6">
                    <h3 class="text-center">{{ $t('customs.salesBoard.refOcti') }}</h3>
                    <p class="primary--text text-center mt-2">{{ item.reference_sale }}</p>
                  </v-col>
                  <v-col v-if="item.customer_firstname && item.customer_name" cols="12" lg="3" md="4" sm="6">
                    <h3 class="text-center">{{ $t('customs.salesBoard.customerName') }}</h3>
                    <p class="primary--text text-center mt-2">{{ item.customer_firstname }} {{ item.customer_name }}</p>
                  </v-col>
                  <v-col v-else-if="item.customer_id" cols="12" lg="3" md="4" sm="6">
                    <h3 class="text-center">{{ $t('customs.salesBoard.customerId') }}</h3>
                    <p class="primary--text text-center mt-2">{{ item.customer_id }}</p>
                  </v-col>
                  <v-col v-if="item.origin_payment !== null" cols="12" lg="3" md="4" sm="6">
                    <h3 class="text-center">{{ $t('customs.salesBoard.paymentOrigin') }}</h3>
                    <p class="primary--text text-center mt-2">
                      {{ $t('customs.originPayment.' + item.origin_payment.name_origin_payment) }}
                    </p>
                  </v-col>
                  <v-col v-if="item.basket !== null && item.basket.currency !== null" cols="12" lg="3" md="4" sm="6">
                    <h3 class="text-center">{{ $t('customs.salesBoard.currency') }}</h3>
                    <p class="primary--text text-center mt-2">
                      {{ item.basket.currency.old_code_currency || item.basket.currency.code_currency }}
                    </p>
                  </v-col>
                  <v-col v-if="item.basket !== null && item.basket.currency !== null" cols="12" lg="3" md="4" sm="6">
                    <h3 class="text-center">{{ $t('customs.salesBoard.totalPriceWithoutTaxWithoutDiscount') }}</h3>
                    <p class="primary--text text-center mt-2">
                      {{
                        formatPrice(
                          item.basket.price_no_discount_exclude_tax_basket,
                          item.basket.currency.code_currency
                        )
                      }}
                      <template v-if="item.basket.old_price_no_discount_exclude_tax_basket">
                        ({{
                          formatPrice(
                            item.basket.old_price_no_discount_exclude_tax_basket,
                            item.basket.currency.old_code_currency
                          )
                        }})
                      </template>
                    </p>
                  </v-col>
                  <v-col v-if="item.basket !== null && item.basket.currency !== null" cols="12" lg="3" md="4" sm="6">
                    <h3 class="text-center">{{ $t('customs.salesBoard.totalPriceWithoutTaxWithDiscount') }}</h3>
                    <p class="primary--text text-center mt-2">
                      {{
                        formatPrice(item.basket.price_discount_exclude_tax_basket, item.basket.currency.code_currency)
                      }}
                      <template v-if="item.basket.old_price_discount_exclude_tax_basket">
                        ({{
                          formatPrice(
                            item.basket.old_price_discount_exclude_tax_basket,
                            item.basket.currency.old_code_currency
                          )
                        }})
                      </template>
                    </p>
                  </v-col>
                  <v-col v-if="item.basket !== null && item.basket.currency !== null" cols="12" lg="3" md="4" sm="6">
                    <h3 class="text-center">{{ $t('customs.salesBoard.totalPriceWithTaxWithoutDiscount') }}</h3>
                    <p class="primary--text text-center mt-2">
                      {{
                        formatPrice(
                          item.basket.price_no_discount_include_tax_basket,
                          item.basket.currency.code_currency
                        )
                      }}
                      <template v-if="item.basket.old_price_no_discount_include_tax_basket">
                        ({{
                          formatPrice(
                            item.basket.old_price_no_discount_include_tax_basket,
                            item.basket.currency.old_code_currency
                          )
                        }})
                      </template>
                    </p>
                  </v-col>
                  <v-col v-if="item.basket !== null && item.basket.currency !== null" cols="12" lg="3" md="4" sm="6">
                    <h3 class="text-center">{{ $t('customs.salesBoard.totalPriceWithTaxWithDiscount') }}</h3>
                    <p class="primary--text text-center mt-2">
                      {{
                        formatPrice(item.basket.price_discount_include_tax_basket, item.basket.currency.code_currency)
                      }}
                      <template v-if="item.basket.old_price_discount_include_tax_basket">
                        ({{
                          formatPrice(
                            item.basket.old_price_discount_include_tax_basket,
                            item.basket.currency.old_code_currency
                          )
                        }})
                      </template>
                    </p>
                  </v-col>
                  <v-col
                    v-if="item.basket.samples_basket !== undefined && item.basket.samples_basket !== null"
                    cols="12"
                    lg="3"
                    md="4"
                    sm="6"
                  >
                    <h3 class="text-center">{{ $t('customs.salesBoard.samples') }}</h3>
                    <p class="primary--text text-center mt-2">{{ item.basket.samples_basket }}</p>
                  </v-col>
                  <v-col v-if="item.device" cols="12" lg="3" md="4" sm="6">
                    <h3 class="text-center">{{ $t('customs.salesBoard.deviceName') }}</h3>
                    <p class="primary--text text-center mt-2">{{ item.device.name_device }}</p>
                  </v-col>
                  <v-col v-if="item.device" cols="12" lg="3" md="4" sm="6">
                    <h3 class="text-center">{{ $t('customs.salesBoard.deviceSerial') }}</h3>
                    <p class="primary--text text-center mt-2">{{ item.device.serial_device }}</p>
                  </v-col>
                  <v-col v-if="item.transactions.length > 0" cols="12">
                    <v-row>
                      <v-col sm="12">
                        <h4>{{ $t('customs.salesBoard.paymentTypeTable') }}</h4>
                        <v-divider />
                        <v-col sm="12">
                          <v-card outlined>
                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-center">
                                      <h3>{{ $t('customs.salesBoard.paymentType') }}</h3>
                                    </th>
                                    <th class="text-center">
                                      <h3>{{ $t('customs.salesBoard.referencePayment') }}</h3>
                                    </th>
                                    <th class="text-center">
                                      <h3>{{ $t('customs.salesBoard.paymentAmount') }}</h3>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(transaction, key) in item.transactions" :key="key">
                                    <td class="text-center">
                                      {{ transaction.types_payments.name_type_payment }}
                                    </td>
                                    <td class="text-center">
                                      {{ transaction.reference_transaction }}
                                    </td>
                                    <td class="text-center">
                                      {{
                                        formatPrice(transaction.amount_transaction, item.basket.currency.code_currency)
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-card>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-for="(subBasket, key) in item.basket.subs_baskets" :key="key" sm="12">
                    <h4>{{ $t('customs.salesBoard.subBasket') }} {{ key + 1 }} :</h4>
                    <v-divider />
                    <v-col v-if="subBasket.types_delivery !== null" sm="12">
                      <p>
                        {{ $t('customs.salesBoard.deliveryType') }} :
                        {{ $t('customs.salesBoard.' + subBasket.types_delivery.name_type_delivery) }}
                      </p>
                    </v-col>
                    <v-col sm="12">
                      <v-card outlined>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-center">
                                  <h3>{{ $t('customs.salesBoard.productName') }}</h3>
                                </th>
                                <th class="text-center">
                                  <h3>{{ $t('customs.salesBoard.productBarcode') }}</h3>
                                </th>
                                <th class="text-center">
                                  <h3>{{ $t('customs.salesBoard.productVariantId') }}</h3>
                                </th>
                                <th class="text-center">
                                  <h3>{{ $t('customs.salesBoard.productPrice') }}</h3>
                                </th>
                                <th class="text-center">
                                  <h3>{{ $t('customs.salesBoard.quantity') }}</h3>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(subBasketLine, keyLine) in subBasket.sub_basket_lines" :key="keyLine">
                                <td class="text-center">{{ subBasketLine.product.title_product }}</td>
                                <td class="text-center">{{ subBasketLine.product.barcode_product }}</td>
                                <td class="text-center">{{ subBasketLine.product.variant_id_product }}</td>
                                <td class="text-center">
                                  {{
                                    formatPrice(
                                      subBasketLine.price_product_no_discount_include_tax_sub_basket_line,
                                      item.basket.currency.code_currency
                                    )
                                  }}
                                  <template
                                    v-if="subBasketLine.old_price_product_no_discount_include_tax_sub_basket_line"
                                  >
                                    ({{
                                      formatPrice(
                                        subBasketLine.old_price_product_no_discount_include_tax_sub_basket_line,
                                        item.basket.currency.old_code_currency
                                      )
                                    }})
                                  </template>
                                </td>
                                <td class="text-center">{{ subBasketLine.quantity_sub_basket_line }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                  </v-col>
                </v-row>
              </v-container>
            </td>
          </template>
        </v-data-table>
      </template>
      <v-divider />
    </v-card-text>
  </div>
</template>

<script>
  import { Helpers } from '../mixins/Helpers.js'
  import ExportAsCsv from './ExportAsCsv.vue'

  const _ = require('lodash')

  export default {
    name: 'SalesList',
    components: {
      ExportAsCsv
    },
    props: {
      formattedSales: Array,
      startDate: String,
      endDate: String,
      loading: Boolean
    },
    mixins: [Helpers],
    data() {
      return {
        expandedSale: null,
        expandedFilters: false,
        expanded: [],
        filteredSales: [],
        salesFilters: {
          refFilter: '',
          statusFilter: [],
          priceFilter: [0, 0],
          sellerFilter: '',
          storeFilter: '',
          dateFilterBeforeFormat: ''
        },
        minMaxPrice: [0, 0],
        dateFilterWindowOpened: false,
        rowsPerPage: [10, 20, 30, 40, 50, { text: 'All', value: -1 }],
        headers: [
          {
            text: this.$t('customs.salesBoard.ref'),
            value: 'reference_partner_sale',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.status'),
            value: 'validate_sale',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.orderPrice'),
            value: 'basket.total_price_order',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.takeawayPrice'),
            value: 'basket.total_price_takeaway',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.totalPrice'),
            value: 'basket.price_discount_include_tax_basket',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.date'),
            value: 'date_sale',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.seller'),
            value: 'user.fullname_user',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.store'),
            value: 'store.name_store',
            align: 'center'
          }
        ],
        headersSubBasketLine: [
          {
            text: this.$t('customs.salesBoard.productName'),
            value: 'product.title_product',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.productBarcode'),
            value: 'product.barcode_product',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.productVariantId'),
            value: 'product.variant_id_product',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.productPrice'),
            value: 'price_product_no_discount_include_tax_sub_basket_line',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.quantity'),
            value: 'quantity_sub_basket_line',
            align: 'center'
          }
        ],
        statuses: [
          {
            text: this.$t('customs.salesBoard.validatedStatus'),
            value: 'validatedStatus',
            color: 'box-green-500-glow'
          },
          {
            text: this.$t('customs.salesBoard.notFinishedStatus'),
            value: 'notFinishedStatus',
            color: 'box-red-500-glow'
          }
        ],
        headersForCSV: ['Reference', 'Status', 'OrderPrice', 'TakeawayPrice', 'TotalPrice', 'Date', 'Seller', 'Store']
      }
    },
    computed: {
      dataForCSV() {
        let data = []
        for (let sale of this.formattedSales) {
          let saleData = []
          saleData.push(sale.reference_partner_sale || sale.reference_sale)
          saleData.push(sale.validate_sale ? 'validated' : 'not validated')
          saleData.push(this.formatPriceWithoutCurrency(sale.basket.total_price_order))
          saleData.push(this.formatPriceWithoutCurrency(sale.basket.total_price_takeaway))
          saleData.push(this.formatPriceWithoutCurrency(sale.basket.price_discount_include_tax_basket))
          saleData.push(this.$d(new Date(sale.date_sale), 'short'))
          saleData.push(sale.user.fullname_user)
          saleData.push(sale.store.name_store)
          data.push(saleData)
        }
        return data
      },
      dateFilter() {
        return this.salesFilters.dateFilterBeforeFormat.split('-').reverse().join('/')
      },
      navigatorLang() {
        return navigator.language
      }
    },
    methods: {
      formatSales(newSales) {
        if (newSales.length > 0) {
          this.filteredSales = _.cloneDeep(this.formattedSales)
        }
      },
      formatStatus(status) {
        if (status) {
          return {
            text: this.$t('customs.salesBoard.validatedStatus'),
            value: 'validatedStatus',
            color: 'light-green elevation-0'
          }
        } else {
          return {
            text: this.$t('customs.salesBoard.notFinishedStatus'),
            value: 'notFinishedStatus',
            color: 'red lighten-1 elevation-0'
          }
        }
      },
      customSort(items, index, isDesc) {
        items.sort((a, b) => {
          if (index === 'basket.price_discount_include_tax_basket') {
            if (!isDesc) {
              return a.basket.price_discount_include_tax_basket > b.basket.price_discount_include_tax_basket
            } else {
              return b.basket.price_discount_include_tax_basket > a.basket.price_discount_include_tax_basket
            }
          }
        })
        return items
      },
      getMinMaxPrice() {
        if (this.filteredSales.length > 0) {
          let min = this.filteredSales[0].basket.price_discount_include_tax_basket
          let max = this.filteredSales[0].basket.price_discount_include_tax_basket

          for (let sale of this.filteredSales) {
            min =
              sale.basket.price_discount_include_tax_basket < min ? sale.basket.price_discount_include_tax_basket : min
            max =
              sale.basket.price_discount_include_tax_basket > max ? sale.basket.price_discount_include_tax_basket : max
          }
          return [min - 1, max + 1]
        }
        return [0, 0]
      }
    },
    watch: {
      formattedSales(newSales) {
        this.formatSales(newSales)
        this.minMaxPrice = this.getMinMaxPrice()
        this.salesFilters.priceFilter = this.minMaxPrice
      },
      salesFilters: {
        handler(newFilters) {
          this.filteredSales = this.formattedSales.filter(sale => {
            if (newFilters.refFilter !== '') {
              if (
                !(
                  (sale.reference_partner_sale && sale.reference_partner_sale.includes(newFilters.refFilter)) ||
                  (sale.reference_sale && sale.reference_sale.includes(newFilters.refFilter))
                )
              ) {
                return false
              }
            }
            if (newFilters.statusFilter.length > 0) {
              if (!newFilters.statusFilter.includes(this.formatStatus(sale.validate_sale).value)) {
                return false
              }
            }
            if (
              newFilters.priceFilter[0] !== this.minMaxPrice[0] ||
              newFilters.priceFilter[1] !== this.minMaxPrice[1]
            ) {
              let price = sale.basket.price_discount_include_tax_basket
              if (!(price / 100 >= newFilters.priceFilter[0] / 100 && price / 100 <= newFilters.priceFilter[1] / 100)) {
                return false
              }
            }
            if (newFilters.dateFilterBeforeFormat !== '') {
              if (
                !(
                  sale.date_sale.includes(newFilters.dateFilterBeforeFormat) ||
                  this.$d(new Date(sale.date_sale), 'short').includes(this.dateFilter)
                )
              ) {
                return false
              }
            }
            if (newFilters.sellerFilter !== '') {
              if (!sale.user.fullname_user.toLowerCase().includes(newFilters.sellerFilter.toLowerCase())) {
                return false
              }
            }
            if (newFilters.storeFilter !== '') {
              if (!sale.store.name_store.toLowerCase().includes(newFilters.storeFilter.toLowerCase())) {
                return false
              }
            }
            return true
          })
        },
        deep: true
      }
    }
  }
</script>

<style>
  .plain-order-table .v-table thead tr {
    height: 48px;
  }

  .basket-details td,
  .basket-details th {
    padding: 0 10px !important;
    border: 1px rgba(0, 0, 0, 0.12) solid;
  }

  p {
    margin: 0 !important;
  }

  .filters {
    overflow: hidden;
    max-height: 0;
    transition-property: max-height;
    transition-duration: 0.5s;
  }

  .expand-filters {
    height: auto;
    max-height: 400px;
    overflow-y: auto;
    transition-property: max-height;
    transition-duration: 0.5s;
  }

  .dropdown-button span {
    position: absolute;
    top: 7px;
    right: 20px;
    font-size: 1.7rem;
  }

  .sale-line {
    cursor: pointer;
  }

  .sale-details:hover {
    background: none !important;
  }
</style>
