<template>
  <div>
    <v-card-title>
      <v-spacer />
      <export-as-csv
        :headers="headersForCSV"
        :data="dataForCSV"
        :file-name="`stores-stats-${startDate}-to-${endDate}`"
      />
    </v-card-title>

    <v-container fluid>
      <v-row>
        <v-col lg="1" md="3" sm="6">
          <v-btn color="primary" @click="expandedFilters = !expandedFilters">
            {{ $t('customs.salesBoard.filters') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="filters" :class="{ 'expand-filters': expandedFilters }">
      <v-row align="center">
        <!-- Store filter -->
        <v-col lg="3" md="6" sm="12" cols="12">
          <v-text-field v-model="salesFilters.storeFilter" :label="$t('customs.salesBoard.searchByStore')" />
        </v-col>

        <!-- Price filter -->
        <v-col lg="6" md="12" sm="12" cols="12">
          <v-row align="center">
            <v-col lg="2" md="2" sm="2" cols="3">
              <!-- eslint-disable -->
              <v-text-field v-model="salesFilters.priceFilter[0] / 100" type="number" suffix="€" readonly />
              <!-- eslint-enable -->
            </v-col>
            <v-col lg="8" md="8" sm="8" class="px-3" cols="6">
              <v-range-slider v-model="salesFilters.priceFilter" :max="minMaxPrice[1]" :min="minMaxPrice[0]" />
            </v-col>
            <v-col lg="2" md="2" sm="2" cols="3">
              <!-- eslint-disable -->
              <v-text-field v-model="salesFilters.priceFilter[1] / 100" type="number" suffix="€" readonly />
              <!-- eslint-enable -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- Sales board -->
    <v-card-text class="pa-0">
      <v-data-table
        :headers="headers"
        :items="filteredSales"
        item-key="sellerId"
        :loading="loading"
        :options="{ sortBy: ['storeName'], sortDesc: [false] }"
        :footer-props="{
          'items-per-page-options': rowsPerPage,
          'items-per-page-text': $t('customs.salesBoard.rowsPerPage')
        }"
        class="elevation-0 table-striped border-radius6 plain-order-table"
      >
        <template v-slot:item="{ item }">
          <!-- For each sale line -->
          <tr class="sale-line">
            <td class="text-center">{{ item.storeName }}</td>
            <td class="text-center">{{ item.salesNumber }}</td>
            <td class="text-center">
              {{ formatPrice(item.totalOrderPrice, item.currency) }}
              <template v-if="item.oldOrderPrice"> ({{ formatPrice(item.oldOrderPrice, item.oldCurrency) }}) </template>
            </td>
            <td class="text-center">
              {{ formatPrice(item.totalTakeawayPrice, item.currency) }}
              <template v-if="item.oldTakeawayPrice">
                ({{ formatPrice(item.oldTakeawayPrice, item.oldCurrency) }})
              </template>
            </td>
            <td class="text-center">
              {{ formatPrice(item.totalPrice, item.currency) }}
              <template v-if="item.oldTotalPrice"> ({{ formatPrice(item.oldTotalPrice, item.oldCurrency) }}) </template>
            </td>
            <td class="text-center">
              {{ formatPrice(item.averageBasket, item.currency) }}
              <template v-if="item.oldAverageBasket">
                ({{ formatPrice(item.oldAverageBasket, item.oldCurrency) }})
              </template>
            </td>
            <td class="text-center">{{ item.totalSoldProducts }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
  import { Helpers } from '../mixins/Helpers.js'
  import ExportAsCsv from './ExportAsCsv.vue'

  const _ = require('lodash')

  export default {
    name: 'SalesListByStore',
    components: {
      ExportAsCsv
    },
    props: {
      formattedSales: Array,
      startDate: String,
      endDate: String,
      loading: Boolean
    },
    mixins: [Helpers],
    data() {
      return {
        expandedFilters: false,
        filteredSales: [],
        salesFilters: {
          priceFilter: [0, 0],
          sellerFilter: '',
          storeFilter: ''
        },
        minMaxPrice: [0, 0],
        rowsPerPage: [10, 20, 30, 40, 50, { text: 'All', value: -1 }],
        headers: [
          {
            text: this.$t('customs.salesBoard.store'),
            value: 'storeName',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.salesNumber'),
            value: 'salesNumber',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.orderPrice'),
            value: 'totalOrderPrice',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.takeawayPrice'),
            value: 'totalTakeawayPrice',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.totalPrice'),
            value: 'totalPrice',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.averageBasket'),
            value: 'averageBasket',
            align: 'center'
          },
          {
            text: this.$t('customs.salesBoard.totalSoldProducts'),
            value: 'totalSoldProducts',
            align: 'center'
          }
        ],
        headersForCSV: [
          'Store',
          'SalesNumber',
          'TotalOrderPrice',
          'TotalTakeawayPrice',
          'TotalPrice',
          'AverageBasket',
          'TotalSoldProducts'
        ]
      }
    },
    computed: {
      salesStatsByStore() {
        if (this.formattedSales && this.formattedSales.length > 0) {
          let salesStats = []
          for (let sale of this.formattedSales) {
            let orderPrice = 0
            let oldOrderPrice = 0
            let takeawayPrice = 0
            let oldTakeawayPrice = 0
            let quantity = 0
            let totalPrice = 0
            let oldTotalPrice = 0

            for (let subBasket of sale.basket.subs_baskets) {
              for (let line of subBasket.sub_basket_lines) {
                if (subBasket.types_delivery.name_type_delivery === 'order') {
                  orderPrice += line.price_include_tax_sub_basket_lines
                  if (line.old_price_include_tax_sub_basket_lines) {
                    oldOrderPrice += line.old_price_include_tax_sub_basket_lines
                    oldTotalPrice += line.old_price_include_tax_sub_basket_lines
                  }
                } else if (subBasket.types_delivery.name_type_delivery === 'takeaway') {
                  takeawayPrice += line.price_include_tax_sub_basket_lines
                  if (line.old_price_include_tax_sub_basket_lines) {
                    oldTakeawayPrice += line.old_price_include_tax_sub_basket_lines
                    oldTotalPrice += line.old_price_include_tax_sub_basket_lines
                  }
                }
                totalPrice += line.price_include_tax_sub_basket_lines
                if (line.product.variant_id_product !== this.$store.state.currentPartnerConfig.shippingCostsVariantId) {
                  quantity += line.quantity_sub_basket_line
                }
              }
            }

            let storeIndex = salesStats.findIndex(saleStat => saleStat.storeId === sale.id_store)
            if (storeIndex >= 0) {
              salesStats[storeIndex].salesNumber++
              salesStats[storeIndex].totalOrderPrice += orderPrice
              salesStats[storeIndex].totalTakeawayPrice += takeawayPrice
              salesStats[storeIndex].totalPrice += totalPrice
              salesStats[storeIndex].averageBasket =
                salesStats[storeIndex].totalPrice / salesStats[storeIndex].salesNumber
              if (salesStats[storeIndex].oldTotalPrice && salesStats[storeIndex].salesNumber) {
                salesStats[storeIndex].oldAverageBasket =
                  salesStats[storeIndex].oldTotalPrice / salesStats[storeIndex].salesNumber
              }
              salesStats[storeIndex].totalSoldProducts += quantity
            } else {
              salesStats.push({
                storeId: sale.id_store,
                currency: sale.basket.currency.code_currency,
                oldCurrency: sale.basket.currency.old_code_currency || null,
                storeName: sale.store.name_store,
                salesNumber: 1,
                totalOrderPrice: orderPrice,
                oldOrderPrice: oldOrderPrice,
                totalTakeawayPrice: takeawayPrice,
                oldTakeawayPrice: oldTakeawayPrice,
                totalPrice,
                oldTotalPrice: oldTotalPrice,
                averageBasket: totalPrice,
                oldAverageBasket: oldTotalPrice,
                totalSoldProducts: quantity
              })
            }
          }
          return salesStats
        }
        return []
      },
      dataForCSV() {
        let data = []
        for (let stat of this.salesStatsByStore) {
          let saleData = []
          saleData.push(stat.storeName)
          saleData.push(stat.salesNumber)
          saleData.push(this.formatPriceWithoutCurrency(stat.totalOrderPrice))
          saleData.push(this.formatPriceWithoutCurrency(stat.totalTakeawayPrice))
          saleData.push(this.formatPriceWithoutCurrency(stat.totalPrice))
          saleData.push(this.formatPriceWithoutCurrency(stat.averageBasket))
          saleData.push(stat.totalSoldProducts)
          data.push(saleData)
        }
        return data
      }
    },
    methods: {
      formatSales(newSales) {
        if (newSales.length > 0) {
          this.filteredSales = _.cloneDeep(this.salesStatsByStore)
        }
      },
      getMinMaxPrice() {
        if (this.filteredSales.length > 0) {
          let min = this.filteredSales[0].totalPrice
          let max = this.filteredSales[0].totalPrice

          for (let sale of this.filteredSales) {
            min = sale.totalPrice < min ? sale.totalPrice : min
            max = sale.totalPrice > max ? sale.totalPrice : max
          }
          return [min, max]
        }
        return [0, 0]
      }
    },
    mounted() {
      this.formatSales(this.formattedSales)
    },
    watch: {
      formattedSales(newSales) {
        this.formatSales(newSales)
        this.minMaxPrice = this.getMinMaxPrice()
        this.salesFilters.priceFilter = this.minMaxPrice
      },
      salesFilters: {
        handler(newFilters) {
          this.filteredSales = this.salesStatsByStore.filter(sale => {
            if (
              newFilters.priceFilter[0] !== this.minMaxPrice[0] ||
              newFilters.priceFilter[1] !== this.minMaxPrice[1]
            ) {
              let price = sale.totalPrice
              if (!(price / 100 >= newFilters.priceFilter[0] / 100 && price / 100 <= newFilters.priceFilter[1] / 100)) {
                return false
              }
            }
            if (newFilters.storeFilter !== '') {
              if (!sale.storeName.toLowerCase().includes(newFilters.storeFilter.toLowerCase())) {
                return false
              }
            }
            return true
          })
        },
        deep: true
      }
    }
  }
</script>

<style>
  .plain-order-table .v-table thead tr {
    height: 48px;
  }

  .basket-details td,
  .basket-details th {
    padding: 0 10px !important;
    border: 1px rgba(0, 0, 0, 0.12) solid;
  }

  p {
    margin: 0 !important;
  }

  .filters {
    overflow: hidden;
    max-height: 0;
    transition-property: max-height;
    transition-duration: 0.5s;
  }

  .expand-filters {
    height: auto;
    max-height: 400px;
    overflow-y: auto;
    transition-property: max-height;
    transition-duration: 0.5s;
  }

  .dropdown-button span {
    position: absolute;
    top: 7px;
    right: 20px;
    font-size: 1.7rem;
  }

  .sale-line {
    cursor: pointer;
  }

  .sale-details:hover {
    background: none !important;
  }
</style>
