export default {
  resetPersistedState(state) {
    state.currentUser = null
    state.allStores = []
    state.allSellers = []
    state.fusionAuthToken = null
    state.currentPartnerConfig = null
    state.currentGroup = null
    state.userHasChosenGroup = false
  },
  setPartnerId(state, partnerId) {
    state.partnerId = partnerId
  },
  setCurrentUser(state, payload) {
    state.currentUser = payload
  },
  setAllStores(state, payload) {
    state.allStores = payload
  },
  setAllSellers(state, payload) {
    state.allSellers = payload
  },
  setFusionAuthToken(state, payload) {
    state.fusionAuthToken = payload
  },
  setCurrentPartnerConfig(state, payload) {
    state.currentPartnerConfig = payload
  },
  setCurrentGroup(state, payload) {
    state.currentGroup = payload
  },
  setIsAdmin(state, payload) {
    state.isAdmin = payload
  },
  setIsOctipasAdmin(state, payload) {
    state.isOctipasAdmin = payload
  },
  setUserHasMultiGroups(state, payload) {
    state.userHasMultiGroups = payload
  },
  setUserHasChosenGroup(state, payload) {
    state.userHasChosenGroup = payload
  },
  setAppLoader(state, payload) {
    state.appLoader = payload
  }
}
