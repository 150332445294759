<template>
  <v-app-bar :fixed="fixedToolbar" v-if="toolbar" :class="navToolbarScheme" :clipped-left="toolbarClippedLeft" app flat>
    <v-app-bar-nav-icon
      v-if="$route.name !== 'Home'"
      class="hidden-lg-and-up octi-logo"
      @click.stop="$store.dispatch('toggleDrawer', !navDrawer)"
    />
    <v-toolbar-title class="ml-0 hidden-lg-and-up" @click="$router.push({ name: 'Home' })">{{ title }}</v-toolbar-title>
    <!--<v-tooltip bottom v-if="navMiniVarient && $route.name !== 'Home'" color="sidebar">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on"
               fab
               depressed
               color="primary"
               outlined
               @click.stop="toggleMiniVariantMode">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </template>
      <span>{{$t('customs.toolbar.expand')}}</span>
    </v-tooltip>
    <v-tooltip bottom v-else color="sidebar">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on"
               fab
               depressed
               color="primary"
               outlined
               @click.stop="toggleMiniVariantMode">
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <span>{{$t('customs.toolbar.collapse')}}</span>
    </v-tooltip>-->
    <v-btn
      v-if="$route.name !== 'Home'"
      icon
      light
      class="hidden-md-and-down"
      color="primary"
      @click.stop="toggleMiniVariantMode"
    >
      <v-tooltip bottom v-if="navMiniVarient" color="sidebar">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">fas fa-arrow-circle-right</v-icon>
        </template>
        <span>{{ $t('customs.toolbar.expand') }}</span>
      </v-tooltip>
      <v-tooltip bottom v-else color="sidebar">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">fas fa-arrow-circle-left</v-icon>
        </template>
        <span>{{ $t('customs.toolbar.collapse') }}</span>
      </v-tooltip>
    </v-btn>
    <!--<v-btn icon :to="{name: 'Contacts'}" flat>
      <v-icon>contacts</v-icon>
    </v-btn>
    <v-btn icon :to="{name: 'Chat'}" flat>
      <v-icon>chat</v-icon>
    </v-btn>-->
    <v-spacer />
    <v-btn class="mr-5" v-if="userHasMultiGroups && getUserHasChosenGroup" color="primary" @click="changeGroup">
      {{ $t('customs.toolbar.changeGroup') }}
    </v-btn>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" fab text color="primary">
          <v-icon>person</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-if="authUser">
          <v-list-item-content>
            <v-list-item-title>{{ `${authUser.firstName} ${authUser.lastName}` }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <!--<v-list-tile @click="() => {}">
          <v-list-tile-avatar>
            <v-icon>person</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Edit Profile</v-list-tile-title>
        </v-list-tile>
        <v-list-tile @click="() => {}">
          <v-list-tile-avatar>
            <v-icon>settings_applications</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Settings</v-list-tile-title>
        </v-list-tile>
        <v-list-tile @click="() => {}">
          <v-list-tile-avatar>
            <v-icon>mail</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Inbox</v-list-tile-title>
        </v-list-tile>
        <v-divider></v-divider>-->
        <v-list-item @click="logout">
          <v-list-item-avatar>
            <v-icon>power_settings_new</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('customs.toolbar.signOut') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-col class="mt-4" lg="2" md="3" cols="4">
      <v-select v-model="$root.$i18n.locale" :items="locales"></v-select>
    </v-col>
  </v-app-bar>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { usersService } from '../../services/sso-users.service.js'

  export default {
    data() {
      return {
        title: 'Octipas',
        locales: [
          { text: 'Français', value: 'fr-FR' },
          { text: 'English', value: 'en-US' }
        ]
      }
    },
    computed: {
      ...mapGetters({
        navDrawer: 'navDrawer',
        toolbarClippedLeft: 'toolbarClippedLeft',
        fixedToolbar: 'fixedToolbar',
        toolbar: 'toolbarVisibility',
        navToolbarScheme: 'navToolbarScheme',
        navMiniVarient: 'navMiniVarient'
      }),
      authUser() {
        return this.$store.state.currentUser
      },
      userHasMultiGroups() {
        return this.$store.state.userHasMultiGroups
      },
      getCurrentGroup() {
        return this.$store.state.currentGroup
      },
      getUserHasChosenGroup() {
        return this.$store.state.userHasChosenGroup
      }
    },
    methods: {
      async logout() {
        await usersService.logout()
      },
      toggleMiniVariantMode() {
        this.$store.dispatch('toggleMiniVariantMode')
        this.$store.dispatch('toggleMiniVarient')
      },
      async changeGroup() {
        await this.$store.dispatch('setUserHasChosenGroup', false)
        await this.$store.dispatch('setAllStores', [])
        if (this.$route.name !== 'Home') {
          await this.$router.push({ name: 'Home' })
        }
      }
    }
  }
</script>

<style scoped>
  .octi-logo {
    cursor: pointer;
  }
</style>
