import store from '../../store'
import { EventBus } from '../../event-bus'
import { usersService } from '../sso-users.service'
import https from 'https'

const qs = require('querystring')
const axios = require('axios')

export const API = {
  /**
   * Make the request according to the typeRequest parameter
   * Parameters query Params and body are null by default because they are optional during a query.
   * @param typeRequest (String) : GET, POST, PUT, DELETE, etc...
   * @param url (String) : the URL to access with the request
   * @param queryParams (Object) : parameters in the query (e.g. : "?firstname=Jean&lastname=Bon" will be { firstname: 'Jean', lastname: 'Bon' })
   * @param body (Object) : parameters in the body
   * @returns {Promise<any>}
   */
  async request(typeRequest, url, queryParams = null, body = null) {
    try {
      // If query parameters are sent, we create a new URLSearchParams
      let params = null
      if (queryParams !== null) {
        params = new URLSearchParams(queryParams)
      }

      // Define the default header
      let headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }

      if (store.state && store.state.fusionAuthToken && store.state.fusionAuthToken.access_token) {
        headers['Authorization'] = 'JWT ' + store.state.fusionAuthToken.access_token
        headers['partnerId'] = store.state.partnerId
      }

      let initAxios = {
        url: url,
        method: typeRequest,
        baseURL: process.env.VUE_APP_API_URL,
        params,
        headers,
        httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        responseType: 'json'
      }

      if (queryParams && params) {
        initAxios.params = params
      }

      if (body) {
        initAxios.data = body
      }

      return axios(initAxios)
        .then(response => {
          return response.data
        })
        .catch(async err => {
          if (err.response) {
            EventBus.$emit('displaySnackbar', {
              display: true,
              textToTranslate: err.response.data.error || 'errorOccurred',
              text: '',
              color: 'error'
            })
            if (err.response.data.error && err.response.data.error === 'notConnectedToSSO') {
              await usersService.logout()
            }
          }
        })
    } catch (error) {
      console.error(error)
    }
  },

  /**
   * Make the request to mongoServer
   * Parameters query Params and body are null by default because they are optional during a query.
   * @param typeRequest (String) : GET, POST, PUT, DELETE, etc...
   * @param url (String) : the URL to access with the request
   * @param queryParams (Object) : parameters in the query (e.g. : "?firstname=Jean&lastname=Bon" will be { firstname: 'Jean', lastname: 'Bon' })
   * @param body (Object) : parameters in the body
   * @returns {Promise<any>}
   */
  async requestMongoServer(typeRequest, url, queryParams = null, body = null) {
    try {
      url = process.env.VUE_APP_URL_INVENTORY + '/' + url
      // Create a new URL with the url parameters, and the origin url of the page
      let newUrl = new URL(url, process.env.VUE_APP_URL_INVENTORY)

      // If query parameters are sent, we create a new URLSearchParams and give it to newUrl
      if (queryParams !== null) {
        newUrl.search = new URLSearchParams(queryParams)
      }
      // Define the default header
      let headers = new Headers()
      headers.append('Content-Type', 'application/json')
      headers.append('Accept', 'application/json')

      return new Promise((resolve, reject) => {
        // Basic initialization of fetch
        let initFetch = {
          method: typeRequest,
          headers: headers,
          body: body ? JSON.stringify(body) : null
        }

        /**
         * Using fetch to launch an ajax request
         * @param newUrl is the url called
         * @param initFetch is the basic initialization of fetch
         */
        fetch(newUrl, initFetch)
          .then(response => {
            // If the return is a status 200
            if (response.ok) {
              response.json().then(jsonResponse => {
                resolve(jsonResponse)
              })

              // If not status 200
            } else {
              response
                .json()
                .then(async () => {})
                .catch(err => {
                  reject(err.message)
                })
            }
            // Catch only network problems
          })
          .catch(error => {
            reject(error.message)
          })
      })
    } catch (error) {
      console.error(error)
    }
  },

  /**
   * Make the request according to the typeRequest parameter
   * Parameters query Params and body are null by default because they are optional during a query.
   * @param typeRequest (String) : GET, POST, PUT, DELETE, etc...
   * @param url (String) : the URL to access with the request
   * @param queryParams (Object) : parameters in the query (e.g. : "?firstname=Jean&lastname=Bon" will be { firstname: 'Jean', lastname: 'Bon' })
   * @param apikey
   * @param body (Object) : parameters in the body
   * @param isBodyFormUrlEncoded
   * @returns {Promise<any>}
   */
  async requestFusionAuth(
    typeRequest,
    url,
    queryParams = null,
    apikey = null,
    body = null,
    isBodyFormUrlEncoded = false
  ) {
    try {
      // Create a new URL with the url parameters, and the origin url of the page
      let newUrl = new URL(url, process.env.VUE_APP_SSO_API_URL)
      // If query parameters are sent, we create a new URLSearchParams and give it to newUrl
      if (queryParams !== null) {
        newUrl.search = new URLSearchParams(queryParams)
      }
      // Define the default header
      let headers = new Headers()
      headers.append('Accept', 'application/json')
      if (body !== null) {
        if (isBodyFormUrlEncoded) {
          headers.append('Content-Type', 'application/x-www-form-urlencoded')
          body = qs.stringify(body)
        } else {
          headers.append('Content-Type', 'application/json')
          body = JSON.stringify(body)
        }
      }
      if (apikey !== null) {
        headers.append('Authorization', apikey)
      }
      return new Promise((resolve, reject) => {
        // Basic initialization of fetch
        let initFetch = {
          method: typeRequest,
          headers: headers,
          body: body || null,
          credentials: 'include'
        }

        /**
         * Using fetch to launch an ajax request
         * @param newUrl is the url called
         * @param initFetch is the basic initialization of fetch
         */
        fetch(newUrl, initFetch)
          .then(response => {
            if (typeRequest === 'GET' || typeRequest === 'POST') {
              if (response.ok) {
                response.json().then(jsonResponse => {
                  resolve(jsonResponse)
                })
              } else {
                response.text().then(textResponse => {
                  resolve(textResponse)
                })
              }
            } else if (typeRequest === 'PUT') {
              if (response.ok) {
                response.json().then(jsonResponse => {
                  resolve(jsonResponse)
                })
              } else {
                response.text().then(textResponse => {
                  resolve(textResponse)
                })
              }
            } else if (typeRequest === 'DELETE') {
              let newResponse = {
                deleted: true
              }
              if (response.ok) {
                resolve(newResponse)
              } else {
                newResponse.deleted = false
                newResponse.errors = response
                resolve(newResponse)
              }
            } else {
              response
                .text()
                .then(async () => {})
                .catch(error => {
                  reject(error.message)
                })
            }
            // Catch only network problems
          })
          .catch(error => {
            reject(error)
          })
      })
    } catch (error) {
      console.error(error)
    }
  }
}
