import Vue from 'vue'
import Router from 'vue-router'
import { routes } from './routes'
import Store from '../store/index'
import { usersService } from '../services/sso-users.service'
import { generalService } from '../services/general.service'

Vue.use(Router)

const router = new Router({
  routes,
  scrollBehaviour(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'Home' && to.path !== '/') {
    await Store.dispatch('setAppLoader', true)
    await usersService.validateToken()

    if (!Store.state.currentUser) {
      await usersService.logUser()
    }

    if (!Store.state.currentGroup || !Store.state.currentPartnerConfig) {
      await generalService.getAPIPartnerInfos()
    }
    await Store.dispatch('setAppLoader', false)
  }
  next()
})

export default router
