<template>
  <div id="custom-error-page">
    <div class="error-container">
      <div class="error-items">
        <a href="http://www.octipas.com/"
          ><img
            class=""
            src="https://s3.eu-west-3.amazonaws.com/octipas-backoffices/logos/octipas.png"
            alt="octipas.png"
        /></a>
        <div class="error-text">
          <h1 class="primary--text">{{ code }}</h1>
          <h2 class="">{{ message }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'custom-error-page',
    props: ['code', 'message']
  }
</script>

<style>
  .error-container {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    min-width: 100vw;
  }
  .error-items {
    text-align: center;
    position: absolute;
    width: 50%;
    margin: 0 auto;
    top: 50vh;
    transform: translateY(-50%);
  }
  .error-text h1 {
    display: inline;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid grey;
  }
  .error-text h2 {
    display: inline;
  }
</style>
