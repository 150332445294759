<template>
  <v-container>
    <v-card>
      <v-img v-if="product.images[0]" class="productImage" :src="product.images[0]" aspect-ratio="1.75"></v-img>
      <v-card-title primary-title>
        <v-container>
          <v-row justify="center">
            <v-col>
              <input
                v-model="productSelected"
                type="checkbox"
                :id="product.id"
                :value="product.variants[0].name[0].value"
              />
              <h4>{{ product.variants[0].name[0].value }}</h4>
              <!-- <div v-if="product.variants[0].short_description[0]"> {{ product.variants[0].short_description[0].value }} </div> -->
              <span class="descrption"> {{ $t('customs.inventory.sku') }}: {{ product.sku }} </span>
              <!-- <div>CODE BARRE: {{ product.barcode }} </div> -->
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'ProductCard',
    props: {
      product: Object
    },
    data() {
      return {
        productSelected: ''
      }
    },
    watch: {
      productSelected() {
        if (this.productSelected) {
          this.$emit('checked', this.product)
        } else {
          this.$emit('unchecked', this.product)
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .description {
    font-size: 5px;
  }
</style>
