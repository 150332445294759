<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-alert text type="info">{{ $t('customs.valorization.infoRulesApplyOnStagingAndProd') }}</v-alert>
      </v-col>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="rules" :loading="loading" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t('customs.valorization.basketRules') }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="addRule">{{ $t('customs.valorization.addRule') }}</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td v-if="item.status === 0">
                <v-chip label class="orange elevation-0" text-color="white">
                  {{ $t('customs.valorization.disabled') }}
                </v-chip>
              </td>
              <td v-else>
                <v-chip label class="light-green elevation-0" text-color="white">
                  {{ $t('customs.valorization.enabled') }}
                </v-chip>
              </td>
              <td>
                <v-icon small class="mr-2" @click="editRule(item)">mdi-pencil</v-icon>
                <v-icon small @click="dialogDelete = !dialogDelete">mdi-delete</v-icon>
              </td>
            </tr>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">{{ $t('customs.valorization.deleteRuleQuestion') }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogDelete = !dialogDelete">
                    {{ $t('customs.valorization.cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="removeRule(item.id)">{{
                    $t('customs.valorization.ok')
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:no-data>
            <div>{{ $t('customs.valorization.noData') }}</div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { valorizationService } from '@/services/valorization.service'

  export default {
    name: 'RulesList',
    data() {
      return {
        headers: [
          {
            text: this.$t('customs.valorization.name'),
            value: 'name',
            width: '50%'
          },
          {
            text: this.$t('customs.valorization.status'),
            value: 'status',
            width: '40%'
          },
          {
            text: this.$t('customs.valorization.actions'),
            width: '10%'
          }
        ],
        dialogDelete: false,
        user: this.$store.state.currentUser,
        isActiveA: false,
        rules: [],
        loading: true
      }
    },
    mounted() {
      this.getRules()
    },
    methods: {
      async getRules() {
        this.loading = true
        this.rules = await valorizationService.getRules(this.$store.state.partnerId)
        this.loading = false
      },
      async removeRule(ruleId) {
        this.loading = true
        let response = await valorizationService.removeRule(ruleId)
        if (response === true) {
          await this.getRules()
        }
        this.loading = false
        this.dialogDelete = false
      },
      addRule() {
        this.$router.push({ name: 'ValorizationAddRule' })
      },
      editRule(rule) {
        this.$router.push({ name: 'ValorizationEditRule', params: { id: rule.id } })
      }
    }
  }
</script>

<style scoped></style>
