<template>
  <v-container grid-list-md>
    <v-row>
      <v-col>
        <h1>{{ $t('customs.valorization.addRule') }}</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="10" lg="10" xl="8">
        <v-form>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>{{ $t('customs.valorization.general') }}</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" sm="6" md="4" lg="4" xl="4">
                      <v-text-field v-model="ruleName" :label="$t('customs.valorization.ruleName')"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="4" lg="4" xl="4">
                      <v-select
                        v-model="ruleStatus"
                        :items="ruleStatusSelect"
                        :label="$t('customs.valorization.status')"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" sm="6" md="4" lg="4" xl="4">
                      <v-text-field
                        v-model="priority"
                        type="number"
                        min="0"
                        :label="$t('customs.valorization.rulePriority')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card>
                <v-card-title>{{ $t('customs.valorization.conditions') }}</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="3">
                      <v-text-field v-model="ruleCoupon" :label="$t('customs.valorization.ruleCoupon')"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="3">
                      <v-text-field
                        v-model="ruleMaxUseCustomer"
                        type="number"
                        min="1"
                        :label="$t('customs.valorization.ruleMaxUseCustomer')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="3">
                      <v-menu :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :label="$t('customs.valorization.ruleFromDate')"
                            readonly
                            :value="`${ruleFromDate.date} ${ruleFromDate.time}`"
                            v-on="on"
                            append-icon="mdi-calendar-start"
                          ></v-text-field>
                        </template>
                        <v-card>
                          <v-date-picker
                            v-model="ruleFromDate.date"
                            first-day-of-week="1"
                            :locale="$root.$i18n.locale"
                          ></v-date-picker>
                          <v-time-picker v-model="ruleFromDate.time" format="24hr"></v-time-picker>
                        </v-card>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="3">
                      <v-menu :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :label="$t('customs.valorization.ruleToDate')"
                            readonly
                            :value="`${ruleToDate.date} ${ruleToDate.time}`"
                            v-on="on"
                            append-icon="mdi-calendar-end"
                          ></v-text-field>
                        </template>
                        <v-card>
                          <v-date-picker
                            v-model="ruleToDate.date"
                            first-day-of-week="1"
                            :locale="$root.$i18n.locale"
                          ></v-date-picker>
                          <v-time-picker v-model="ruleToDate.time" format="24hr"></v-time-picker>
                        </v-card>
                      </v-menu>
                    </v-col>

                    <v-row justify="center">
                      <v-col v-for="(rule, index) in extraRules" :key="index" cols="10" sm="10" md="10" lg="10" xl="10">
                        <v-row align="center">
                          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-select
                              v-model="rule.type"
                              @change="setDefaultProductsInListValue(index)"
                              :items="extraRulesSelect"
                              :placeholder="$t('customs.valorization.ruleConditionType')"
                            >
                              <template v-slot:append>
                                <v-btn icon @click.prevent="removeCondition(index)">
                                  <v-icon color="primary">delete</v-icon>
                                </v-btn>
                              </template>
                            </v-select>
                          </v-col>

                          <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="rule.type === 'basket_price'">
                            <v-text-field
                              v-model="rule.value"
                              type="number"
                              min="0"
                              :label="$t('customs.valorization.basketAmount')"
                            >
                              <template v-slot:append> € </template>
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="rule.type === 'loyalty_points'">
                            <v-text-field
                              v-model="rule.value"
                              type="number"
                              min="0"
                              :label="$t('customs.valorization.loyaltyPoints')"
                            >
                              <template v-slot:append>{{ $t('customs.valorization.points') }}</template>
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="rule.type === 'min_amount'">
                            <v-text-field
                              v-model="rule.value"
                              type="number"
                              min="0"
                              :label="$t('customs.valorization.ruleMinAmount')"
                            >
                              <template v-slot:append>€</template>
                            </v-text-field>
                          </v-col>

                          <v-col cols="6" sm="6" md="6" lg="6" xl="6" v-if="rule.type === 'specific_product'">
                            <v-text-field
                              v-model="rule.value"
                              type="number"
                              min="1"
                              :label="$t('customs.valorization.minProductsInList')"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="6" sm="6" md="6" lg="6" xl="6" v-if="rule.type === 'specific_product'">
                            <v-text-field
                              v-model="rule.productList"
                              @paste.prevent="formatListProducts"
                              :label="$t('customs.valorization.ruleSkusList')"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="rule.type === 'specific_product'">
                            {{ $t('customs.valorization.or') }}
                          </v-col>

                          <v-col cols="6" sm="6" md="6" lg="6" xl="6" v-if="rule.type === 'specific_product'">
                            <v-file-input
                              truncate-length="15"
                              @change="addFile($event, index)"
                              :placeholder="$t('customs.valorization.ruleSkusList')"
                            ></v-file-input>
                          </v-col>
                          <v-col
                            cols="6"
                            sm="6"
                            md="6"
                            lg="6"
                            xl="6"
                            v-if="rule.type === 'specific_product' && rule.file_selected"
                          >
                            {{ $t('customs.valorization.fileSelected') }} ({{ getNumberOfSkusInFile(index) }} SKUs)
                          </v-col>

                          <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="rule.type === 'specific_stores'">
                            <v-text-field
                              v-model="rule.value"
                              :label="$t('customs.valorization.specificStoresInput')"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-col cols="12">
                      <v-btn rounded @click.prevent="addCondition()">
                        <v-icon color="primary">mdi-plus</v-icon> {{ $t('customs.valorization.ruleAddCondition') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card>
                <v-card-title>{{ $t('customs.valorization.effects') }}</v-card-title>
                <v-card-text>
                  <v-row>
                    <!--
                  <label for="rule-percent-product">{{$t('customs.valorization.rulePercentDiscountProduct')}} : </label> <input v-model="productPercentDiscount" type="number" min="0" id="rule-percent-product" name="rule-percent-product"/> %<br/>
                  <label for="rule-discount-product">{{$t('customs.valorization.ruleFixDiscountProduct')}} : </label> <input v-model="productFixDiscount" type="number" min="0" id="rule-discount-product" name="rule-discount-product"/> €<br/>
                -->
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <v-select
                        v-model="applyType"
                        :items="applyTypeSelect"
                        :placeholder="$t('customs.valorization.ruleConditionType')"
                      ></v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                      xl="4"
                      v-if="applyType === 'rule_buy_x_products_get_specific_product_free'"
                    >
                      <v-text-field v-model="freeProductSKU" label="SKU (Y): "></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                      xl="4"
                      v-else-if="applyType === 'rule_buy_x_products_get_free_product_in_list'"
                    >
                      <v-text-field
                        v-model="freeProductList"
                        label="SKUs : "
                        :placeholder="$t('customs.valorization.ruleSkusList')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" v-else>
                      <v-text-field
                        v-model="discount"
                        type="number"
                        min="0"
                        :label="$t('customs.valorization.discount')"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                      <v-text-field
                        v-model="quantityForDiscount"
                        type="number"
                        min="0"
                        :label="$t('customs.valorization.ruleQtyForDiscount')"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                      <v-text-field
                        v-model="maxProductDiscountCountToApply"
                        type="number"
                        min="0"
                        :label="$t('customs.valorization.ruleMaxProductDiscountCount')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card>
                <v-card-title>{{ $t('customs.valorization.applyProductsRulesLabel') }}</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      v-for="(rule, index) in productsApplyRules"
                      :key="index"
                      class="extra-rules"
                    >
                      <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                          <v-select
                            v-model="rule.type"
                            @change="setDefaultProductsInListValue(index)"
                            :items="productsApplyRulesSelect"
                            :placeholder="$t('customs.valorization.ruleConditionType')"
                          >
                            <template v-slot:append>
                              <v-btn icon @click.prevent="removeApplyCondition(index)">
                                <v-icon color="primary">delete</v-icon>
                              </v-btn>
                            </template>
                          </v-select>
                        </v-col>

                        <template v-if="rule.type === 'product_option' || rule.type === 'product_info'">
                          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                            <v-text-field v-model="rule.key" :label="$t('customs.valorization.key')"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                            <v-text-field v-model="rule.value" :label="$t('customs.valorization.value')"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                            <v-select
                              v-model="rule.operator"
                              :items="[
                                { text: $t('customs.valorization.true'), value: 'yes' },
                                { text: $t('customs.valorization.false'), value: 'no' }
                              ]"
                              :placeholder="$t('customs.valorization.value')"
                            ></v-select>
                          </v-col>
                        </template>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <v-btn rounded @click.prevent="addApplyCondition()">
                        <v-icon color="primary">mdi-plus</v-icon> {{ $t('customs.valorization.ruleAddCondition') }}
                      </v-btn>
                    </v-col>

                    <!--<label for="rule-discount-basket">{{$t('customs.valorization.ruleBasketDiscount')}} : </label> <input v-model="basketFixDiscount" type="number" min="0" id="rule-discount-basket" name="rule-discount-basket"/> €<br/>
                    <label for="rule-discount-basket-percent">{{$t('customs.valorization.ruleBasketPercentDiscount')}} : </label> <input v-model="basketPercentDiscount" type="number" min="0" id="rule-discount-basket-percent" name="rule-discount-basket-percent"/> %<br/>
                -->

                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <v-text-field
                        v-model="maxDiscount"
                        type="number"
                        min="0"
                        :label="$t('customs.valorization.ruleMaxDiscount')"
                      >
                        <template v-slot:append> € </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn outlined @click="submitRule()">{{ $t('customs.valorization.submit') }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { valorizationService } from '@/services/valorization.service'
  import { EventBus } from '@/event-bus'
  const moment = require('moment')

  export default {
    name: 'NewRule',
    data() {
      return {
        action: this.$route.name === 'ValorizationEditRule' ? 'edit' : 'add',
        ruleId: this.$route.params.id,
        ruleName: '',
        ruleStatusSelect: [
          { text: this.$t('customs.valorization.disabled'), value: 0 },
          { text: this.$t('customs.valorization.enabled'), value: 1 }
        ],
        ruleStatus: 1,
        ruleCoupon: '',
        ruleMaxUseCustomer: 1,
        ruleFromDate: {
          date: '',
          time: ''
        },
        ruleToDate: {
          date: '',
          time: ''
        },
        extraRulesSelect: [
          { text: this.$t('customs.valorization.ruleProductsInList'), value: 'specific_product' },
          { text: this.$t('customs.valorization.ruleMinAmount'), value: 'min_amount' },
          { text: this.$t('customs.valorization.ruleBasketMinAmount'), value: 'basket_price' },
          { text: this.$t('customs.valorization.specificStores'), value: 'specific_stores' },
          { text: this.$t('customs.valorization.loyaltyPoints'), value: 'loyalty_points' }
        ],
        extraRules: [],
        productsApplyRulesSelect: [
          { text: this.$t('customs.valorization.productsOption'), value: 'product_option' },
          { text: this.$t('customs.valorization.productInfo'), value: 'product_info' }
        ],
        productsApplyRules: [],
        productPercentDiscount: 0,
        productFixDiscount: 0,
        basketPercentDiscount: 0,
        basketFixDiscount: 0,
        maxProductDiscountCountToApply: 999,
        maxDiscount: '',
        discount: '',
        freeProductSKU: '',
        freeProductList: '',
        applyTypeSelect: [
          {
            text: this.$t('customs.valorization.ruleDiscountPercentProductPrice'),
            value: 'discount_percent_product_price'
          },
          {
            text: this.$t('customs.valorization.ruleDiscountFixedAmountBasket'),
            value: 'discount_fixed_amount_basket'
          },
          {
            text: this.$t('customs.valorization.ruleBuyXProductGetTheCheapestAtYPercent'),
            value: 'buy_x_product_get_the_cheapest_at_y_percent'
          },
          {
            text: this.$t('customs.valorization.ruleBuyXProductsGetSpecificProductFree'),
            value: 'rule_buy_x_products_get_specific_product_free'
          },
          {
            text: this.$t('customs.valorization.ruleBuyXProductsGetFreeProductInList'),
            value: 'rule_buy_x_products_get_free_product_in_list'
          }
        ],
        applyType: 'discount_percent_product_price',
        quantityForDiscount: '',
        applyRules: [],
        priority: 1,
        alertMessageContent: '',
        alertMessageClass: 'error',
        loading: false,
        loading_post: false,
        checkSkus: false,
        erroredSkus: false,
        showModalError: false
      }
    },
    async mounted() {
      if (this.action === 'edit') {
        // Get rule
        this.loading = true
        let rule = await valorizationService.getRule(this.ruleId, this.$store.state.partnerId)
        if (rule) {
          // Load rule
          this.ruleName = rule.name
          this.ruleStatus = rule.status
          this.ruleCoupon = rule.coupon_code
          this.ruleMaxUseCustomer = rule.max_use_by_customer

          let dateStart = moment(rule.from_date)
          let dateStartComponent = dateStart.format('YYYY-MM-DD')
          let timeStartComponent = dateStart.format('HH:mm')
          this.ruleFromDate.date = dateStartComponent
          this.ruleFromDate.time = timeStartComponent
          let dateEnd = moment(rule.to_date)
          let dateEndComponent = dateEnd.format('YYYY-MM-DD')
          let timeEndComponent = dateEnd.format('HH:mm')
          this.ruleToDate.date = dateEndComponent
          this.ruleToDate.time = timeEndComponent

          this.extraRules = JSON.parse(rule.extra_rules)
          this.productsApplyRules = JSON.parse(rule.products_apply_rules)
          this.productPercentDiscount = rule.product_discount_percent
          this.productFixDiscount = rule.product_fix_discount
          this.maxProductDiscountCountToApply = rule.max_product_discount_count
          this.maxDiscount = rule.max_discount
          this.priority = rule.priority
          this.basketPercentDiscount = rule.basket_percent_discount
          this.basketFixDiscount = rule.basket_fix_discount

          if (rule.product_discount_percent !== 0) {
            this.discount = rule.product_discount_percent
          } else if (rule.basket_fix_discount !== 0) {
            this.discount = rule.basket_fix_discount
          }

          this.applyRules = JSON.parse(rule.apply_rules)

          if (this.applyRules !== undefined && this.applyRules.length !== 0) {
            if (
              this.applyRules[0].type === 'buy_x_product_get_the_cheapest_at_y_percent' ||
              this.applyRules[0].type === 'rule_buy_x_products_get_specific_product_free' ||
              this.applyRules[0].type === 'rule_buy_x_products_get_free_product_in_list'
            ) {
              if (this.applyRules[0].type === 'rule_buy_x_products_get_free_product_in_list') {
                this.freeProductList = this.applyRules[0].free_product_list
              }
              if (this.applyRules[0].type === 'rule_buy_x_products_get_specific_product_free') {
                this.freeProductSKU = rule.free_product
              }
              this.quantityForDiscount = this.applyRules[0].qty_to_apply
              this.applyType = this.applyRules[0].type
            }
          } else if (this.basketFixDiscount !== 0) {
            this.applyType = 'discount_fixed_amount_basket'
          } else if (this.productPercentDiscount !== 0) {
            this.applyType = 'discount_percent_product_price'
          }

          this.loading = false
        }
      }
    },
    computed: {
      // enable min_amount only if specific products applied
      minAmountEnabled() {
        return this.extraRules.find(rule => rule.type === 'specific_product') !== undefined
      }
    },
    methods: {
      getNumberOfSkusInFile(index) {
        // Count the number of skus in the file
        return this.extraRules[index].file.match(/[^\r\n]+/g).length
      },
      chooseFile(index) {
        // Trigger click on file input
        this.$refs['files' + index][0].click()
      },
      addFile(file, index) {
        // Store file content
        this.extraRules[index].file_selected = true // Just to change the button text
        let reader = new FileReader()
        reader.onloadend = () => {
          this.extraRules[index].file = reader.result
        }
        reader.readAsText(file)
      },
      setDefaultProductsInListValue(index) {
        // If set extra conditions to specific_product -> set default value to 1
        if (this.extraRules[index].type === 'specific_product' && this.extraRules[index].value === '') {
          this.extraRules[index].value = 1
        }
      },
      hideAlertMessage() {
        this.alertMessageClass = ''
        this.alertMessageContent = ''
        this.erroredSkus = false
      },
      addCondition() {
        // Add new empty rule
        this.extraRules.push({
          type: '',
          value: '',
          productList: '',
          file: '',
          file_selected: false
        })
      },
      addApplyCondition() {
        // Add new empty rule
        this.productsApplyRules.push({
          type: '',
          operator: 'yes',
          key: '',
          value: ''
        })
      },
      removeCondition(index) {
        // Remove rule at index
        this.extraRules.splice(index, 1)
      },
      removeApplyCondition(index) {
        // Remove rule at index
        this.productsApplyRules.splice(index, 1)
      },
      formatListProducts(evt) {
        // Format paste from table editor into products list
        let clipboard = evt.clipboardData.getData('Text')
        clipboard = clipboard.replace(/(?:\r\n|\r|\n)/g, ',') // Replace new line

        // Update value
        let index = evt.target.getAttribute('data-index')
        this.extraRules[index]['productList'] = clipboard
      },
      validateForm() {
        // Check not empty name
        if (this.ruleName.length === 0) {
          this.alertMessageContent = this.$t('customs.valorization.ruleInvalidRuleName')
          this.alertMessageClass = 'error'
          this.erroredSkus = false
          EventBus.$emit('displaySnackbar', {
            display: true,
            text: this.alertMessageContent,
            color: this.alertMessageClass
          })
          return false
        }

        return true
      },
      showErroredSkus() {
        this.showModalError = true
      },
      hideModal() {
        this.showModalError = false
      },
      async submitRule() {
        // Validate form
        if (!this.validateForm()) return false

        this.loading_post = true

        // Convert date to utc
        let fullFromDate, fullToDate
        fullFromDate =
          this.ruleFromDate.date.length === 0 && this.ruleFromDate.time.length === 0
            ? ''
            : moment(`${this.ruleFromDate.date} ${this.ruleFromDate.time}`).toISOString()
        fullToDate =
          this.ruleToDate.date.length === 0 && this.ruleToDate.time.length === 0
            ? ''
            : moment(`${this.ruleToDate.date} ${this.ruleToDate.time}`).toISOString()

        // build rule json
        let rule = {
          merchantId: this.$store.state.partnerId,
          name: this.ruleName,
          status: this.ruleStatus,
          priority: this.priority,
          couponCode: this.ruleCoupon,
          maxUseByCustomer: this.ruleMaxUseCustomer,
          fromDate: fullFromDate,
          toDate: fullToDate,
          extraRules: this.extraRules,
          productsApplyRules: this.productsApplyRules,
          productDiscountPercent: this.productPercentDiscount,
          productFixDiscount: this.productFixDiscount,
          maxProductDiscountCount: this.maxProductDiscountCountToApply,
          basketFixDiscount: this.basketFixDiscount,
          basketPercentDiscount: this.basketPercentDiscount,
          maxDiscount: this.maxDiscount,
          checkSkus: false, // this.checkSkus,
          discount: this.discount,
          freeProduct: this.freeProductSKU,
          applyType: this.applyType,
          applyRules: this.applyRules
        }

        if (this.applyType === 'discount_percent_product_price') {
          rule.productDiscountPercent = this.discount
        } else if (this.applyType === 'discount_fixed_amount_basket') {
          rule.basketFixDiscount = this.discount
        } else if (
          this.applyType === 'buy_x_product_get_the_cheapest_at_y_percent' ||
          this.applyType === 'rule_buy_x_products_get_specific_product_free' ||
          this.applyType === 'rule_buy_x_products_get_free_product_in_list'
        ) {
          rule.productDiscountPercent = this.discount
          let applyRule = {
            type: this.applyType,
            qty_to_apply: this.quantityForDiscount
          }
          if (this.freeProductList !== '') {
            applyRule.free_product_list = this.freeProductList
          }
          this.applyRules = [applyRule]
          rule.applyRules = this.applyRules
        }

        if (this.action === 'add') {
          // Add rule
          // Post rule

          let response = await valorizationService.addRule(rule)
          if (response === true) {
            await this.$router.push({ name: 'ValorizationRuleList' })
          }
        } else {
          // Edit rule
          rule.id = this.ruleId

          // Post rule
          let response = await valorizationService.editRule(rule)
          if (response === true) {
            await this.$router.push({ name: 'ValorizationRuleList' })
          }
        }
      }
    }
  }
</script>

<style scoped></style>
