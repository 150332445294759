export default {
  'en-US': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
  },
  'fr-FR': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
  }
}
