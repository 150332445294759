<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn
        :color="canDownload ? 'primary' : 'grey'"
        :style="canDownload ? {} : { cursor: 'not-allowed' }"
        text
        v-on="on"
        @click="canDownload ? download() : ''"
        >CSV</v-btn
      >
    </template>
    <span v-if="canDownload">{{ $t('customs.salesBoard.exportAsCSV') }}</span>
    <span v-else>{{ $t('customs.salesBoard.noDataToExportAsCSV') }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    name: 'ExportAsCsv',
    props: {
      headers: Array,
      data: Array,
      fileName: String
    },
    methods: {
      download() {
        let text = ''
        text += this.headers.join(';') + '\n'
        for (let line of this.data) {
          text += line.join(';') + '\n'
        }
        let a = document.createElement('a')
        a.style = 'display: none'
        document.body.appendChild(a)
        let url = 'data:text/csv;charset=utf-8,%EF%BB%BF' + text
        a.href = url
        a.download = this.fileName + '.csv'
        a.click()
        document.body.removeChild(a)
      }
    },
    computed: {
      canDownload() {
        if (this.data.length > 0) {
          return true
        }
        return false
      }
    }
  }
</script>

<style scoped></style>
