<template>
  <v-row justify="center">
    <v-col sm="12" md="8">
      <v-card>
        <v-card-title class="justify-center" primary-title>
          <h1 class="text-h1 mb-1 text-center">{{ $t('customs.releaseNote.title') }}</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="releases != null">
          <v-select
            v-model="selectedFile"
            :items="releases"
            prepend-icon="insert_drive_file"
            item-text="name"
            return-object
            outlined
          />
          <pre v-html="selectedFile.filePath.default" class="pa-4"></pre>
        </v-card-text>
        <v-card-text v-else>
          <p>{{ $t('customs.releaseNote.noReleaseNote') }}</p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { releases } from '@/data/releases/releases.js'
  import { marked } from 'marked'

  export default {
    name: 'release-notes',
    data() {
      return {
        releases: null,
        selectedFile: null
      }
    },
    computed: {
      compiledMarkdown() {
        return marked(this.selectedFile)
      }
    },
    mounted() {
      this.releases = releases
      this.selectedFile = this.releases[0]
      marked.setOptions({
        renderer: new marked.Renderer(),
        highlight: function (code) {
          return require('highlight.js').highlightAuto(code).value
        }
      })
    }
  }
</script>

<style scoped>
  :deep(li) {
    list-style: initial !important;
    white-space: normal !important;
  }
  pre {
    background-color: rgb(245, 245, 245);
    padding-right: 20px;
  }
  .v-card-title {
    text-align: center;
  }
</style>
