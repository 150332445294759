<template>
  <v-container>
    <inventoryList :list="filtre(inventoryList)"></inventoryList>
  </v-container>
</template>
<script>
  import { generalService } from '../../services/general.service'
  import inventoryList from '../../components/Inventory/InventoryList'

  export default {
    name: 'InventoryInProgress',
    data() {
      return {
        inventoryList: []
      }
    },
    components: {
      inventoryList
    },
    async mounted() {
      this.inventoryList = await generalService.getAllInventory()
    },
    methods: {
      filtre(list) {
        if (list.length > 0) {
          list = list.filter(function (el) {
            if (el.status === 'New' || el.status === 'NotDone') {
              return true
            } else {
              return false
            }
          })
        }
        return list
      }
    }
  }
</script>

<style scoped></style>
