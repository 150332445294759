<template>
  <v-card height="100%" class="flex-card">
    <v-card-title class="pa-1" style="width: 100%; height: 10%">
      <v-row ma-0 justify="end" pb-1 style="height: 100%">
        <v-col cols="3" class="text-right pa-1" style="height: 100%">
          <export-as-csv
            :headers="headersForCSV"
            :data="dataForCSV"
            :file-name="`total-scans-${startDate}-to-${endDate}`"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pt-4 pb-4" justify="center">
      <v-row align="center" justify="center">
        <v-btn style="pointer-events: none" color="primary" fab>
          <v-icon>local_offer</v-icon>
        </v-btn>
      </v-row>
      <v-row align="center" justify="center">
        <h2 class="text-h3">{{ totalScannedProducts }}</h2>
      </v-row>
      <v-row justify="center" align="center">
        <div class="title">{{ $t('customs.scannedProducts.scannedProducts') }}</div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import ExportAsCsv from '../ExportAsCsv'

  export default {
    name: 'TotalScannedProductsIndicator',
    components: {
      ExportAsCsv
    },
    props: {
      scannedProducts: Array,
      startDate: String,
      endDate: String
    },
    data() {
      return {
        headersForCSV: ['IdStore', 'Store', 'TotalScans']
      }
    },
    computed: {
      totalScannedProducts() {
        let total = 0
        for (let prod of this.scannedProducts) {
          total += prod.scan_count_scanned_product
        }
        return total
      },
      dataForCSV() {
        let csvRows = []
        for (let scan of this.scannedProducts) {
          let index = csvRows.findIndex(row => row[0] === scan.id_store)
          if (index >= 0) {
            csvRows[index][2] += scan.scan_count_scanned_product
          } else {
            csvRows.push([scan.id_store, scan.store.name_store, scan.scan_count_scanned_product])
          }
        }
        return csvRows
      }
    }
  }
</script>

<style scoped>
  .flex-card {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
