/**
 * Base color scheme for Vuse Admin UI
 * Setting it's titled as Theme builder
 * Basically refers to color scheme of 3 based navigation of an app.
 * Ref: store/modules/swatch.js
 */
export default {
  navDrawerScheme: 'md-sidebar-500',
  navToolbarScheme: 'md-white-500',
  navFooterScheme: 'md-black-500',
  alpha: false, // Transparent Background of the sidebar navigation
  variant: false
}
