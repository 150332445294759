import store from '../store'
import { generalService } from '../services/general.service'
import AppSidebar from '../layouts/App/NavDrawer'
import AppToolbar from '../layouts/App/Toolbar'
import AppFooter from '../layouts/App/Footer'
import Home from '../views/Home'

// Users Administration for SSO
import UsersAdministration from '../views/UsersAdministration/UsersAdministration'

// Release Note
import ReleaseNotes from '../views/ReleaseNotes/ReleaseNotes'

// Inventory
import HomeInventory from '../views/Inventory/HomeInventory'
import CreateInventory from '../views/Inventory/CreateInventory'
import InventoryInProgress from '../views/Inventory/InventoryInProgress'
import InventoryHistory from '../views/Inventory/InventoryHistory'

// Valorization
import RulesList from '@/views/Valorization/RulesList'

// Reporting
import Reporting from '@/views/Reporting/Reporting'

// Error page
import CustomErrorPage from '../views/Pages/Errors/CustomErrorPage.vue'
import Dashboard from '../views/Dashboard/Dashboard'
import NewRule from '@/views/Valorization/NewRule'

async function APIPartnerInfosHook(to, from, next) {
  await store.dispatch('setAppLoader', true)
  if (
    (store.state.allStores && store.state.allStores.length === 0) ||
    (store.state.allSellers && store.state.allSellers.length === 0)
  ) {
    await generalService.getAPIPartnerInfos()
  }
  await store.dispatch('setAppLoader', false)
  next()
}

export const routes = [
  { path: '/', redirect: '/home' },
  {
    path: '/home',
    name: 'Home',
    components: {
      default: Home,
      sidebar: null,
      header: AppToolbar,
      footer: null
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      default: Dashboard,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    beforeEnter: APIPartnerInfosHook
  },
  {
    path: '/users',
    name: 'Users',
    components: {
      default: UsersAdministration,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    beforeEnter: APIPartnerInfosHook
  },
  {
    path: '/releaseNotes',
    name: 'ReleaseNotes',
    components: {
      default: ReleaseNotes,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: '/Inventory',
    name: 'Inventory',
    components: {
      default: HomeInventory,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: '/createInventory',
    name: 'CreateInventory',
    components: {
      default: CreateInventory,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: '/InventoryInProgress',
    name: 'InventoryInProgress',
    components: {
      default: InventoryInProgress,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: '/InventoryHistory',
    name: 'InventoryHistory',
    components: {
      default: InventoryHistory,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: '/valorization',
    name: 'ValorizationRuleList',
    components: {
      default: RulesList,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: '/valorization/add',
    name: 'ValorizationAddRule',
    components: {
      default: NewRule,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: '/valorization/edit/:id',
    name: 'ValorizationEditRule',
    components: {
      default: NewRule,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: '/reporting',
    name: 'Reporting',
    components: {
      default: Reporting,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: CustomErrorPage,
    props: true
  }
]
