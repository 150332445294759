export const Helpers = {
  methods: {
    formatPrice(price, currency) {
      return new Intl.NumberFormat(navigator.language, { style: 'currency', currency: currency }).format(price / 100)
    },
    formatPriceWithoutCurrency(price, useGrouping = true) {
      return new Intl.NumberFormat(navigator.language, { minimumFractionDigits: 2, useGrouping }).format(price / 100)
    }
  }
}
