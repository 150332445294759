<template>
  <v-card>
    <v-card-title>
      <v-row align="center">
        <v-col cols="8">
          <h2 class="title mb-2">{{ $t('customs.dashboard.totalSalesDetails') }}</h2>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="localScale"
            :items="scaleList"
            item-text="label"
            item-value="value"
            :label="$t('customs.dashboard.scale')"
            single-line
          ></v-select>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <bar-chart class="revenues-chart" :options="options" :data="revenueData"></bar-chart>
    </v-card-text>
  </v-card>
</template>

<script>
  import BarChart from '@/components/ChartJs/BarChart'

  const moment = require('moment')

  export default {
    name: 'RevenuesDetailsIndicator',
    components: {
      BarChart
    },
    props: {
      sales: Array,
      dateRange: Object
    },
    data() {
      return {
        options: {
          legend: false,
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
            callbacks: {
              label: function (tooltipItems) {
                return new Intl.NumberFormat(navigator.language, {
                  style: 'currency',
                  currency: 'EUR'
                }).format(parseInt(tooltipItems.yLabel))
              }
            }
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 15,
                  fontSize: 17
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  color: 'rgba(0, 0, 0, 0.05)',
                  drawTicks: false,
                  drawBorder: false
                },
                ticks: {
                  beginAtZero: true,
                  padding: 10
                }
              }
            ]
          }
        },
        scaleList: [
          {
            label: this.$t('customs.dashboard.day'),
            value: 'd'
          },
          {
            label: this.$t('customs.dashboard.month'),
            value: 'm'
          },
          {
            label: this.$t('customs.dashboard.year'),
            value: 'y'
          }
        ],
        localScale: undefined
      }
    },
    computed: {
      revenueData() {
        return {
          labels: this.labelsToDisplay,
          datasets: [
            {
              label: this.$t('customs.dashboard.revenues'),
              data: this.dataToDisplay,
              borderWidth: 1,
              backgroundColor: '#313131',
              maxBarThickness: 50
            }
          ]
        }
      },
      labelsToDisplay() {
        let labels = []
        for (let key in this.chartData) {
          labels.push(key)
        }
        return labels
      },
      dataToDisplay() {
        let data = []
        for (let key in this.chartData) {
          data.push(this.chartData[key] / 100)
        }
        return data
      },
      chartData() {
        let data = {}
        let dateStart = moment(this.dateRange.start)
        let dateEnd = moment(this.dateRange.end)
        let duration = moment.duration(moment(this.dateRange.end).diff(moment(this.dateRange.start)))
        if (this.localScale !== undefined) {
          // If custom scale is set
          if (this.localScale === 'd') {
            // Days display
            // eslint-disable-next-line
            while (dateEnd >= dateStart) {
              data[dateStart.locale(this.$root.$i18n.locale).format('DD MMMM')] = 0
              dateStart.add(1, 'day')
            }

            for (let sale of this.sales) {
              if (sale.validate_sale) {
                data[moment(sale.date_sale).locale(this.$root.$i18n.locale).format('DD MMMM')] +=
                  sale.basket.price_discount_include_tax_basket
              }
            }
          } else if (this.localScale === 'w') {
            // Days display
            // eslint-disable-next-line
            while (dateEnd >= dateStart) {
              data[dateStart.locale(this.$root.$i18n.locale).format('DD MMMM')] = 0
              dateStart.add(1, 'week')
            }

            for (let sale of this.sales) {
              if (sale.validate_sale) {
                data[moment(sale.date_sale).locale(this.$root.$i18n.locale).format('DD MMMM')] +=
                  sale.basket.price_discount_include_tax_basket
              }
            }
          } else if (this.localScale === 'y') {
            // Years display
            // eslint-disable-next-line
            while (dateEnd >= dateStart) {
              data[dateStart.year()] = 0
              dateStart.add(1, 'year')
            }

            for (let sale of this.sales) {
              if (sale.validate_sale) {
                data[moment(sale.date_sale).year()] += sale.basket.price_discount_include_tax_basket
              }
            }
          } else {
            // Monthly per year display
            // eslint-disable-next-line
            while (dateEnd >= dateStart) {
              data[dateStart.locale(this.$root.$i18n.locale).format('MMMM YYYY')] = 0
              dateStart.add(1, 'month')
            }

            for (let sale of this.sales) {
              if (sale.validate_sale) {
                data[moment(sale.date_sale).locale(this.$root.$i18n.locale).format('MMMM YYYY')] +=
                  sale.basket.price_discount_include_tax_basket
              }
            }
          }
        } else {
          if (duration.months() < 4 && duration.years() < 1) {
            // Days display
            // eslint-disable-next-line
            while (dateEnd >= dateStart) {
              data[dateStart.locale(this.$root.$i18n.locale).format('DD MMMM')] = 0
              dateStart.add(1, 'day')
            }

            for (let sale of this.sales) {
              if (sale.validate_sale) {
                data[moment(sale.date_sale).locale(this.$root.$i18n.locale).format('DD MMMM')] +=
                  sale.basket.price_discount_include_tax_basket
              }
            }
            // eslint-disable-next-line
            this.localScale = 'd'
          } else if (duration.years() >= 4) {
            // Years display
            // eslint-disable-next-line
            while (dateEnd >= dateStart) {
              data[dateStart.year()] = 0
              dateStart.add(1, 'year')
            }

            for (let sale of this.sales) {
              if (sale.validate_sale) {
                data[moment(sale.date_sale).year()] += sale.basket.price_discount_include_tax_basket
              }
            }
            // eslint-disable-next-line
            this.localScale = 'y'
          } else if (duration.years() >= 1) {
            // Monthly per year display
            // eslint-disable-next-line
            while (dateEnd >= dateStart) {
              data[dateStart.locale(this.$root.$i18n.locale).format('MMMM YYYY')] = 0
              dateStart.add(1, 'month')
            }

            for (let sale of this.sales) {
              if (sale.validate_sale) {
                data[moment(sale.date_sale).locale(this.$root.$i18n.locale).format('MMMM YYYY')] +=
                  sale.basket.price_discount_include_tax_basket
              }
            }
            // eslint-disable-next-line
            this.localScale = 'm'
          } else {
            // Months display
            // eslint-disable-next-line
            while (dateEnd >= dateStart) {
              data[dateStart.locale(this.$root.$i18n.locale).format('MMMM YYYY')] = 0
              dateStart.add(1, 'month')
            }

            for (let sale of this.sales) {
              if (sale.validate_sale) {
                data[moment(sale.date_sale).locale(this.$root.$i18n.locale).format('MMMM YYYY')] +=
                  sale.basket.price_discount_include_tax_basket
              }
            }
            // eslint-disable-next-line
            this.localScale = 'm'
          }
          // eslint-disable-next-line
          this.localScale = undefined
        }
        return data
      }
    },
    watch: {
      dateRange: {
        deep: true,
        handler() {
          this.localScale = undefined
        }
      }
    }
  }
</script>

<style scoped>
  .revenues-chart,
  .revenues-chart > div,
  .revenues-chart canvas {
    height: 100% !important;
  }
</style>
