import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/lang'
import dates from '@/lang/dates'

Vue.use(VueI18n)

export function createI18n() {
  let locale =
    navigator.language.length === 2 ? `${navigator.language}-${navigator.language.toUpperCase()}` : navigator.language

  return new VueI18n({
    fallbackLocale: 'en-US',
    locale: locale,
    messages,
    dateTimeFormats: dates,
    silentFallbackWarn: true,
    silentTranslationWarn: true
  })
}
