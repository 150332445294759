import Meta from './meta'
import validation from './validation'
import messages from './messages'
import labels from './labels'
import customs from './customs'
export default {
  Meta,
  validation,
  messages,
  labels,
  customs
}
