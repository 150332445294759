<template>
  <v-card height="100%" class="flex-card">
    <v-card-text class="pt-4 pb-4">
      <v-row align="center" justify="center">
        <v-btn style="pointer-events: none" color="primary" fab>
          <v-icon>shopping_cart</v-icon>
        </v-btn>
      </v-row>
      <v-row align="center" justify="center">
        <h2 class="text-h3">{{ totalPurchasedScannedProducts }}</h2>
      </v-row>
      <v-row justify="center" align="center">
        <div class="title">{{ $t('customs.scannedProducts.purchasedScannedProducts') }}</div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'TotalPurchasedScannedProductsIndicator',
    props: {
      sales: Array
    },
    computed: {
      totalPurchasedScannedProducts() {
        let total = 0
        for (let sale of this.sales) {
          if (sale.validate_sale) {
            for (let subBasket of sale.basket.subs_baskets) {
              for (let line of subBasket.sub_basket_lines) {
                total += line.scanned_products_quantity_sub_basket_line
              }
            }
          }
        }
        return total
      }
    }
  }
</script>

<style scoped>
  .flex-card {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
