exports.releases = [
  {
    name: 'Version 2.11',
    filePath: require('./releasenote_2.11.md')
  },
  {
    name: 'Version 2.10',
    filePath: require('./releasenote_2.10.md')
  },
  {
    name: 'Version 2.9.3',
    filePath: require('./releasenote_2.9.3.md')
  },
  {
    name: 'Version 2.9.2',
    filePath: require('./releasenote_2.9.2.md')
  },
  {
    name: 'Version 2.9.1',
    filePath: require('./releasenote_2.9.1.md')
  },
  {
    name: 'Version 2.9',
    filePath: require('./releasenote_2.9.md')
  },
  {
    name: 'Version 2.8.1',
    filePath: require('./releasenote_2.8.1.md')
  },
  {
    name: 'Version 2.8',
    filePath: require('./releasenote_2.8.md')
  },
  {
    name: 'Version 2.7',
    filePath: require('./releasenote_2.7.md')
  },
  {
    name: 'Version 2.6',
    filePath: require('./releasenote_2.6.md')
  },
  {
    name: 'Version 2.5',
    filePath: require('./releasenote_2.5.md')
  },
  {
    name: 'Version 2.4',
    filePath: require('./releasenote_2.4.md')
  },
  {
    name: 'Version 2.3',
    filePath: require('./releasenote_2.3.md')
  },
  {
    name: 'Version 2.2',
    filePath: require('./releasenote_2.2.md')
  },
  {
    name: 'Version 2.1',
    filePath: require('./releasenote_2.1.md')
  },
  {
    name: 'Version 2.0',
    filePath: require('./releasenote_2.0.md')
  },
  {
    name: 'Version 1.0',
    filePath: require('./releasenote_1.0.md')
  }
]
