<template>
  <v-dialog v-model="show" width="850px" persistent>
    <v-card>
      <template slot="progress">
        <v-progress-linear v-if="loader" :indeterminate="true"></v-progress-linear>
      </template>
      <v-card-title>
        <v-row justify="center">
          <v-col>
            <p class="primary--text text-h2 font-weight-light text-center">{{ title }}</p>
          </v-col>
        </v-row>
      </v-card-title>
      <v-form ref="userForm" v-model="userForm" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col sm="12" md="6">
                <v-text-field
                  v-model="firstName"
                  :rules="firstNameRules"
                  prepend-icon="person"
                  :label="this.$t('customs.ssoUsers.usersTabModal.firstNameLabel')"
                  required
                  :readonly="readOnly"
                  :disabled="loader"
                ></v-text-field>
              </v-col>
              <v-col sm="12" md="6">
                <v-text-field
                  v-model="lastName"
                  :rules="lastNameRules"
                  :label="this.$t('customs.ssoUsers.usersTabModal.lastNameLabel')"
                  required
                  :readonly="readOnly"
                  :disabled="loader"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  prepend-icon="email"
                  :label="this.$t('customs.ssoUsers.usersTabModal.emailLabel')"
                  required
                  :readonly="readOnly"
                  :disabled="loader"
                ></v-text-field>
              </v-col>
              <v-col sm="12" md="6">
                <v-text-field
                  v-model="passwd"
                  autocomplete="new-password"
                  prepend-icon="lock"
                  :type="showPasswd ? 'text' : 'password'"
                  :rules="passwdRules"
                  :label="this.$t('customs.ssoUsers.usersTabModal.passwdLabel')"
                  :required="!user"
                  :append-icon="showPasswd ? 'visibility' : 'visibility_off'"
                  @click:append="showPasswd = !showPasswd"
                  :hint="this.$t('customs.ssoUsers.usersTabModal.passwdHint')"
                  :error-messages="samePasswdRules()"
                  counter
                  :readonly="readOnly"
                  :disabled="loader"
                  v-if="!readOnly"
                ></v-text-field>
              </v-col>
              <v-col sm="12" md="6">
                <v-text-field
                  v-model="confirmPasswd"
                  :type="showPasswd ? 'text' : 'password'"
                  :rules="confirmPasswdRules"
                  :label="this.$t('customs.ssoUsers.usersTabModal.passwdConfirmLabel')"
                  :hint="this.$t('customs.ssoUsers.usersTabModal.passwdConfirmHint')"
                  :required="!user"
                  :append-icon="showPasswd ? 'visibility' : 'visibility_off'"
                  @click:append="showPasswd = !showPasswd"
                  :error-messages="samePasswdRules()"
                  counter
                  autocomplete="new-password"
                  :readonly="readOnly"
                  :disabled="loader"
                  v-if="!readOnly"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-if="!readOnly"
                  v-model="userStores"
                  :items="storeList"
                  :label="$t('customs.ssoUsers.usersTabModal.userStoresLabel')"
                  item-text="name_store"
                  :no-data-text="$t('customs.ssoUsers.usersTabModal.noStoresAvailable')"
                  :hint="$t('customs.ssoUsers.usersTabModal.userStoresHint')"
                  append-icon="arrow_drop_down"
                  prepend-icon="store"
                  multiple
                  return-object
                  :persistent-hint="!readOnly"
                  :disabled="loader"
                  chips
                >
                  <v-list-item slot="prepend-item" ripple @click="selectAllStore()">
                    <v-list-item-action>
                      <v-icon v-if="!selectedAll" color="primary darken-2">select_all</v-icon>
                      <v-icon v-else color="primary darken-1">remove</v-icon>
                    </v-list-item-action>
                    <v-list-item-title v-if="!selectedAll">{{
                      $t('customs.ssoUsers.usersTabModal.userStoresSelectAll')
                    }}</v-list-item-title>
                    <v-list-item-title v-else>{{
                      $t('customs.ssoUsers.usersTabModal.userStoresRemoveAll')
                    }}</v-list-item-title>
                  </v-list-item>
                  <template slot="selection" slot-scope="{ item, index }">
                    <v-chip v-if="index < 4" outlined color="primary lighten-1">
                      <span>{{ item.name_store }}</span>
                    </v-chip>
                    <span v-if="index === 4" class="grey--text text-caption"
                      >(+{{ userStores.length - 4 }} {{ $t('customs.ssoUsers.usersTab.others') }})</span
                    >
                  </template>
                </v-select>
                <div v-else>
                  <h3 class="pr-2 pl-2 pt-2 pb-2" style="color: #616161">
                    {{ $t('customs.ssoUsers.usersTabModal.userStoresLabel') }}:
                  </h3>
                  <v-chip
                    v-for="store in this.userStores"
                    :key="store.id_store"
                    outlined
                    color="primary lighten-1 ml-1 mr-1"
                    >{{ store.name_store }}</v-chip
                  >
                  <v-divider class="mt-2"></v-divider>
                </div>
              </v-col>
              <v-col sm="12" md="12">
                <v-tooltip bottom v-if="!readOnly">
                  <template v-slot:activator="{ on }">
                    <v-checkbox v-model="adminCheckbox" prepend-icon="verified_user" color="green lighten-1">
                      <span slot="label" class="">{{ $t('customs.ssoUsers.usersTabModal.adminCheckboxLabel') }}</span>
                      <span slot="append" v-on="on"><v-icon color="green lighten-3">help_outline</v-icon></span>
                    </v-checkbox>
                  </template>
                  <span>{{ $t('customs.ssoUsers.usersTabModal.adminCheckboxTooltip') }}</span>
                </v-tooltip>
                <div v-else>
                  <v-icon v-if="adminCheckbox" color="green lighten-1">check</v-icon
                  ><v-icon v-else color="red lighten-1">clear</v-icon>
                  {{ $t('customs.ssoUsers.usersTabModal.adminCheckboxLabel') }}
                </div>
              </v-col>
              <!--
                <v-col sm="12" md="6">
                  <v-select
                    v-model="userRoles"
                    color="cyan"
                    :items="rolesList"
                    item-text="name"
                    return-object
                    :label="this.$t('customs.ssoUsers.usersTabModal.rolesLabel')"
                    :hint="this.$t('customs.ssoUsers.usersTabModal.rolesHint')"
                    :no-data-text="this.$t('customs.ssoUsers.usersTabModal.noRoleAvailable')"
                    persistent-hint
                    multiple
                    chips
                    :readonly="readOnly"
                    :disabled="loader"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip color="cyan lighten-4">
                        <span>{{ item.name }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>
                -->
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!readOnly && user !== null"
            color="red darken-1"
            class="white--text"
            @click.native="confirmDialog = true"
            large
            :disabled="loader"
            >{{ this.$t('customs.ssoUsers.usersTabModal.deleteUserBtn') }}</v-btn
          >
          <v-dialog v-if="confirmDialog" v-model="confirmDialog" max-width="400">
            <v-card>
              <v-card-title class="text-h5 text-center">{{
                this.$t('customs.ssoUsers.usersTabModal.confirmDeleteModal.modalTitle')
              }}</v-card-title>
              <v-card-text class="text-center font-weight-bold">{{
                this.$t('customs.ssoUsers.usersTabModal.confirmDeleteModal.modalText')
              }}</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" class="white--text" @click="deleteUser">{{
                  this.$t('customs.ssoUsers.usersTabModal.confirmDeleteModal.confirmBtn')
                }}</v-btn>
                <v-btn color="red darken-1" class="white--text" @click="confirmDialog = false">{{
                  this.$t('customs.ssoUsers.usersTabModal.confirmDeleteModal.cancelBtn')
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer />
          <v-btn
            v-if="!readOnly && user === null"
            color="green"
            class="white--text mr-5"
            @click.native="sendNewUser"
            large
            :disabled="loader"
            >{{ this.$t('customs.ssoUsers.usersTabModal.createUserBtn') }}</v-btn
          >
          <v-btn
            v-if="!readOnly && user !== null"
            color="green"
            class="white--text mr-5"
            @click.native="sendUpdatedUser"
            large
            :disabled="loader"
            >{{ this.$t('customs.ssoUsers.usersTabModal.createUserBtn') }}</v-btn
          >
          <v-btn color="red" class="white--text" @click.native="show = false" large :disabled="loader">{{
            this.$t('customs.ssoUsers.usersTabModal.cancelUserBtn')
          }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { usersService } from '../../../services/sso-users.service.js'
  import usersAdministrationMixins from '../../../components/UsersAdministration/UsersAdministrationMixins/UsersAdmininstrationMixins.js'
  const _ = require('lodash')

  export default {
    name: 'UsersAdministrationDialog',
    props: {
      user: Object, // The user in the modal
      title: String, // The fullname of the user if user is not null or 'create a new user'
      value: Boolean, // Modal process stuff
      readOnly: {
        // Is the dialog is on read only or not (false by default)
        default: false,
        type: Boolean
      }
    },
    data() {
      return {
        userForm: true,
        firstName: this.user !== null ? this.user.firstName : '',
        firstNameRules: [
          v => !!v || this.$t('customs.ssoUsers.usersTabModal.firstNameValidationMsg'),
          v =>
            (v && /^[A-Za-z][A-Za-z\u00C0-\u00FF\s-']+$/.test(v)) ||
            this.$t('customs.ssoUsers.usersTabModal.incorrectInput')
        ],
        lastName: this.user !== null ? this.user.lastName : '',
        lastNameRules: [
          v => !!v || this.$t('customs.ssoUsers.usersTabModal.lastNameValidationMsg'),
          v =>
            (v && /^[A-Za-z][A-Za-z\u00C0-\u00FF\s-']+$/.test(v)) ||
            this.$t('customs.ssoUsers.usersTabModal.incorrectInput')
        ],
        email: this.user != null ? (this.user.email != null ? this.user.email : '') : '',
        emailRules: [
          v => !!v || this.$t('customs.ssoUsers.usersTabModal.emailValidationMsg'),
          v =>
            // eslint-disable-next-line
            /^((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/.test(
              v
            ) || this.$t('customs.ssoUsers.usersTabModal.incorrectInput')
        ],
        passwd: '',
        passwdRules: [
          v => !!v || this.user !== null || this.$t('customs.ssoUsers.usersTabModal.passwdValidationMsg'),
          v => v.length >= 8 || this.user !== null || this.$t('customs.ssoUsers.usersTabModal.passwdNotEnoughCarac')
        ],
        confirmPasswd: '',
        confirmPasswdRules: [
          v => !!v || this.user !== null || this.$t('customs.ssoUsers.usersTabModal.passwdConfirmValidationMsg'),
          v =>
            v.length >= 8 || this.user !== null || this.$t('customs.ssoUsers.usersTabModal.passwdConfirmNotEnoughCarac')
        ],
        userStores:
          this.user != null && this.user?.data?.dataBackoffice?.userStores
            ? this.user.data.dataBackoffice.userStores
            : [],
        adminCheckbox: this.user != null ? this.isUserAdmin(this.user) : false,
        selectedAll: false,
        showPasswd: false,
        confirmDialog: false, // Modal confirmation when deleting object
        loader: false
      }
    },
    mixins: [usersAdministrationMixins],
    methods: {
      /**
       * Validate the form, create an user object and send it to the user service for the creation process
       */
      async sendNewUser() {
        if (this.$refs.userForm.validate()) {
          if (await this.checkIfEmailExist(this.email)) {
            this.$emit('showUserAlertEvent', {
              display: true,
              type: 'error',
              msg: this.$t('customs.ssoUsers.usersTab.snackbar.userAlreadyExist'),
              icon: 'error'
            })
          } else {
            let user = {
              registration: {
                applicationId: process.env.VUE_APP_SSO_APP_ID
              },
              sendSetPasswordEmail: false,
              skipVerification: true,
              user: {
                firstName: this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1),
                lastName: this.lastName.toUpperCase(),
                fullName:
                  this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1) + ' ' + this.lastName.toUpperCase(),
                email: this.email,
                data: {
                  dataBackoffice: {
                    ...this.$store.state.currentPartnerConfig.defaultUserConfiguration,
                    userStores:
                      this.userStores.length <= 0
                        ? null
                        : this.userStores.map(store => {
                            return {
                              id_store: store.id_store,
                              name_store: store.name_store,
                              external_id: store.external_id
                            }
                          })
                  }
                },
                memberships: [
                  {
                    groupId: this.$store.state.currentGroup.id
                  }
                ]
              }
            }
            if (this.user == null) {
              if (this.passwd === this.confirmPasswd) {
                user.user.password = this.passwd
              } else {
                return
              }
            }
            if (this.adminCheckbox) {
              user.registration.roles = ['backoffice-admin']
            } else {
              user.registration.roles = ['defaut-user']
            }
            this.loader = true
            let self = this
            let timeout = setTimeout(function () {
              self.administrationSnackbar(
                self,
                'showUserAlertEvent',
                'error',
                self.$t('customs.ssoUsers.globalErrors.serverNotResponding'),
                'error'
              )
            }, 15000)
            usersService.createUser(user).then(response => {
              clearTimeout(timeout)
              if (response.hasOwnProperty('user')) {
                this.loader = false
                this.show = false
                self.administrationSnackbar(
                  this,
                  'showUserAlertEvent',
                  'success',
                  this.$t('customs.ssoUsers.usersTab.snackbar.userCreated'),
                  'done'
                )
                this.$emit('addUserInList', response)
              } else {
                self.administrationSnackbar(
                  this,
                  'showUserAlertEvent',
                  'error',
                  this.$t('customs.ssoUsers.usersTab.snackbar.error'),
                  'error'
                )
              }
            })
          }
        }
      },
      /**
       * Validate the form, create an user object and send it to the user service for the update process
       */
      async sendUpdatedUser() {
        if (this.$refs.userForm.validate()) {
          let token = false
          if (this.email !== this.user.email) {
            if (await this.checkIfEmailExist(this.email)) {
              this.$emit('showUserAlertEvent', {
                display: true,
                type: 'error',
                msg: this.$t('customs.ssoUsers.usersTab.snackbar.userAlreadyExist'),
                icon: 'error'
              })
            } else {
              token = true
            }
          } else {
            token = true
          }

          if (token) {
            let user = {
              user: {
                id: this.user.id,
                firstName: this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1),
                lastName: this.lastName.toUpperCase(),
                fullName:
                  this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1) + ' ' + this.lastName.toUpperCase(),
                email: this.email,
                data: {
                  dataBackoffice: {
                    ...this.$store.state.currentPartnerConfig.defaultUserConfiguration,
                    ...(this.user?.data?.dataBackoffice && { ...this.user.data.dataBackoffice }),
                    userStores:
                      this.userStores.length <= 0
                        ? null
                        : this.userStores.map(store => {
                            return {
                              id_store: store.id_store,
                              name_store: store.name_store,
                              external_id: store.external_id
                            }
                          })
                  }
                },
                memberships: this.user.memberships
              },
              registration: {
                applicationId: process.env.VUE_APP_SSO_APP_ID
              }
            }

            if (
              this.passwd !== '' &&
              this.passwd !== null &&
              this.confirmPasswd !== '' &&
              this.confirmPasswd !== null
            ) {
              if (this.passwd === this.confirmPasswd) {
                user.user.password = this.passwd
              } else {
                return
              }
            }

            if (this.adminCheckbox) {
              user.registration.roles = ['backoffice-admin']
            } else {
              user.registration.roles = ['defaut-user']
            }

            this.loader = true
            let self = this
            let timeout = setTimeout(function () {
              self.administrationSnackbar(
                self,
                'showUserAlertEvent',
                'error',
                self.$t('customs.ssoUsers.globalErrors.serverNotResponding'),
                'error'
              )
            }, 15000)
            await usersService.updateUser(user).then(response => {
              clearTimeout(timeout)
              if (response.hasOwnProperty('user')) {
                this.loader = false
                this.show = false
                this.$emit('showUserAlertEvent', {
                  display: true,
                  type: 'success',
                  msg: this.$t('customs.ssoUsers.usersTab.snackbar.userUpdated'),
                  icon: 'done'
                })
                this.$emit('updateUserInList', response.user)
              } else {
                self.administrationSnackbar(
                  this,
                  'showUserAlertEvent',
                  'error',
                  this.$t('customs.ssoUsers.usersTab.snackbar.error'),
                  'error'
                )
              }
            })
          }
        }
      },
      /**
       * Check if the email exist before creating or updating a user
       */
      async checkIfEmailExist(email) {
        let response = await usersService.getUserByEmail(email)
        if (response.hasOwnProperty('user')) {
          return true
        }
        return false
      },
      /**
       * Send the original user object to the user service for the delete process
       */
      async deleteUser() {
        this.loader = true
        let self = this
        let timeout = setTimeout(function () {
          self.administrationSnackbar(
            self,
            'showGroupAlertEvent',
            'error',
            self.$t('customs.ssoUsers.globalErrors.serverNotResponding'),
            'error'
          )
        }, 15000)
        await usersService.deleteUserById(this.user.id).then(response => {
          clearTimeout(timeout)
          if (response.deleted) {
            this.loader = false
            this.show = false
            this.$emit('showUserAlertEvent', {
              display: true,
              type: 'success',
              msg: this.$t('customs.ssoUsers.usersTab.snackbar.userDeleted'),
              icon: 'done'
            })
            this.$emit('deleteUserInList', this.user.id)
          } else {
            self.administrationSnackbar(
              this,
              'showUserAlertEvent',
              'error',
              this.$t('customs.ssoUsers.usersTab.snackbar.error'),
              'error'
            )
          }
        })
      },
      samePasswdRules() {
        if (this.passwd !== this.confirmPasswd) {
          return this.$t('customs.ssoUsers.usersTabModal.passwdConfirmNotSamePasswd')
        } else {
          return ''
        }
      },
      selectAllStore() {
        this.selectedAll = !this.selectedAll
        if (this.selectedAll) {
          this.userStores = this.storeList
        } else {
          this.userStores = []
        }
      },
      isUserAdmin(user) {
        for (let index = 0; index < user.registrations.length; index++) {
          if (user.registrations[index].applicationId === process.env.VUE_APP_SSO_APP_ID) {
            if (user.registrations[index].hasOwnProperty('roles')) {
              for (let j = 0; j < user.registrations[index].roles.length; j++) {
                if (
                  user.registrations[index].roles[j] === 'backoffice-admin' ||
                  user.registrations[index].roles[j] === 'octipas-admin'
                ) {
                  return true
                }
              }
            }
          }
        }
        return false
      }
    },
    computed: {
      storeList() {
        let storeList = _.cloneDeep(this.$store.state.allStores)
        storeList.sort((a, b) => {
          if (a.name_store > b.name_store) {
            return 1
          }
          if (b.name_store > a.name_store) {
            return -1
          }
        })
        return storeList
      },
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    mounted() {
      if (this.userStores && this.userStores.length > 0) {
        this.userStores.sort((a, b) => {
          if (a.name_store > b.name_store) {
            return 1
          }
          if (b.name_store > a.name_store) {
            return -1
          }
        })
      }
    }
  }
</script>

<style></style>
