<template>
  <circle-statistic
    icon="user"
    :title="$t('customs.dashboard.customersCreations')"
    :sub-title="subtitle"
    :value="periodDiffPositiveValue"
    :text="customersCreationsNumber.toString(10)"
    :color="color"
    :caption="
      periodDiffIsInfinity ? '' : $t('customs.dashboard.previousPeriod') + ' ' + customersCreationsNumberPrevious
    "
    :is-negative="Math.sign(percentagePeriodDiff) !== 1"
    :loading="loading"
  >
  </circle-statistic>
</template>

<script>
  import CircleStatistic from '../../components/Widgets/statistic/CircleStatistic'

  export default {
    name: 'CustomersCreationsIndicator',
    components: {
      CircleStatistic
    },
    props: {
      loading: Boolean,
      customersCreationsNumber: Number,
      customersCreationsNumberPrevious: Number
    },
    computed: {
      percentagePeriodDiff() {
        let diff =
          ((this.customersCreationsNumber - this.customersCreationsNumberPrevious) /
            this.customersCreationsNumberPrevious) *
          100
        if (!isNaN(diff)) {
          return Math.round(diff)
        } else {
          return 0
        }
      },
      periodDiffPositiveValue() {
        let diff = this.percentagePeriodDiff
        if (diff < 0) {
          return -diff
        } else {
          return diff
        }
      },
      subtitle() {
        return this.percentagePeriodDiff === Infinity
          ? this.$t('customs.dashboard.unavailableDataForPreviousPeriod')
          : this.percentagePeriodDiff + '% ' + this.$t('customs.dashboard.vsPreviousPeriod')
      },
      periodDiffIsInfinity() {
        return this.percentagePeriodDiff === Infinity
      },
      color() {
        if (Math.sign(this.percentagePeriodDiff) === 1) {
          return 'green'
        } else if (Math.sign(this.percentagePeriodDiff) === -1) {
          return 'red'
        } else {
          return 'primary'
        }
      }
    }
  }
</script>

<style scoped></style>
