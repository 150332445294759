import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import navigation from './modules/navigation'
import swatch from './modules/swatch'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  storage: window.localStorage,
  reducer(state) {
    return {
      partnerId: state.partnerId,
      currentUser: state.currentUser,
      allStores: state.allStores,
      fusionAuthToken: state.fusionAuthToken,
      currentPartnerConfig: state.currentPartnerConfig,
      currentGroup: state.currentGroup,
      userHasChosenGroup: state.userHasChosenGroup
    }
  }
})

export const store = new Vuex.Store({
  state: {
    partnerId: null,
    currentUser: null,
    allStores: [],
    allSellers: [],
    isAdmin: false,
    isOctipasAdmin: false,
    fusionAuthToken: null,
    currentPartnerConfig: null,
    currentGroup: null,
    userHasMultiGroups: false,
    userHasChosenGroup: false,
    appLoader: false
  },
  getters,
  mutations,
  actions,
  modules: {
    navigation,
    swatch
  },
  plugins: [vuexLocalStorage.plugin]
})

export default store
