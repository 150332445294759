import { API } from './core/api-core.service'

export const storeService = {
  /**
   * Get all stores from user database
   */
  getAllStores() {
    return API.request('GET', 'sso/stores', null, null)
  }
}
