<template>
  <v-card height="100%" class="flex-card">
    <v-card-text class="pt-4 pb-4">
      <v-row align="center" justify="center">
        <v-btn style="pointer-events: none" color="primary" fab>
          <v-icon>mdi-tablet-android</v-icon>
        </v-btn>
      </v-row>
      <v-row align="center" justify="center">
        <h2 class="text-h3">{{ connectedDevices }}</h2>
      </v-row>
      <v-row justify="center" align="center">
        <div class="title">{{ $t('customs.devices.totalConnectedDevices') }}</div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'ConnectedDevicesIndicator',
    props: {
      connectedDevices: Number
    }
  }
</script>

<style scoped>
  .flex-card {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
