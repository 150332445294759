<template>
  <v-card height="100%" class="flex-card">
    <v-card-title class="ml-2" style="max-height: 20%">
      <v-row ma-0 justify="space-between" pb-1>
        <v-col cols="9">
          <h2 class="title">{{ title }}</h2>
        </v-col>
        <v-col cols="3" class="text-right">
          <export-as-csv v-if="csvEnabled" :headers="csvHeaders" :data="csvData" :file-name="csvFilename" />
          <v-icon v-else :color="color">{{ icon }}</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row justify="center" ma-0 class="pb-5">
        <v-progress-circular
          :size="200"
          :width="15"
          :rotate="-90"
          :value="value"
          :color="color"
          :class="{ rotate: isNegative }"
        >
          <h2 :class="{ 'no-rotate': isNegative }">{{ text }}</h2>
        </v-progress-circular>
      </v-row>
    </v-card-text>
    <v-card-text style="min-height: 6vh" class="transparent text-center primary white--text pa-2">
      <v-row justify="space-between" align="center">
        <v-col v-if="subTitle">
          <div class="title">{{ subTitle }}</div>
        </v-col>
        <v-col v-if="caption">
          <div class="text-caption">{{ caption }}</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import ExportAsCsv from '../../ExportAsCsv'

  export default {
    components: {
      ExportAsCsv
    },
    props: {
      icon: String,
      title: String,
      subTitle: String,
      caption: String,
      value: Number,
      text: String,
      color: String,
      isNegative: Boolean,
      csvEnabled: Boolean,
      csvHeaders: Array,
      csvData: Array,
      csvFilename: String
    }
  }
</script>

<style>
  .rotate {
    transform: rotateY(180deg);
  }

  .no-rotate {
    transform: rotateY(-180deg);
  }

  .flex-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
