export default {
  resetPersistedState(context) {
    context.commit('resetPersistedState')
  },
  setPartnerId(context, payload) {
    context.commit('setPartnerId', payload)
  },
  setCurrentUser(context, payload) {
    context.commit('setCurrentUser', payload)
  },
  setAllStores(context, payload) {
    context.commit('setAllStores', payload)
  },
  setAllSellers(context, payload) {
    context.commit('setAllSellers', payload)
  },
  setFusionAuthToken(context, payload) {
    context.commit('setFusionAuthToken', payload)
  },
  setCurrentPartnerConfig(context, payload) {
    context.commit('setCurrentPartnerConfig', payload)
  },
  setCurrentGroup(context, payload) {
    context.commit('setCurrentGroup', payload)
  },
  setIsAdmin(context, payload) {
    context.commit('setIsAdmin', payload)
  },
  setIsOctipasAdmin(context, payload) {
    context.commit('setIsOctipasAdmin', payload)
  },
  setUserHasMultiGroups(context, payload) {
    context.commit('setUserHasMultiGroups', payload)
  },
  setUserHasChosenGroup(context, payload) {
    context.commit('setUserHasChosenGroup', payload)
  },
  setAppLoader(context, payload) {
    context.commit('setAppLoader', payload)
  }
}
