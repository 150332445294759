// Transpiler for older browsers
import 'core-js'
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import './plugins/vuse'
import App from './App.vue'
import router from './router'
import { store } from './store'
import { createI18n } from './i18n/index'
import './registerServiceWorker'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import '@/css/app.css'

window.Vue = require('vue')

if (process.env.VUE_APP_SENTRY_DSN !== '') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  })
}

Vue.config.productionTip = false
Vue.config.devtools = process.env.VUE_APP_ENV === 'dev'

const i18n = createI18n()

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
