<template>
  <div id="users-table">
    <div>
      <h3 class="text-center pt-4 pb-2">{{ $t('customs.ssoUsers.usersTab.description') }}</h3>
    </div>
    <v-card>
      <v-card-title>
        <v-toolbar extend>
          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('customs.ssoUsers.usersTab.searchPlaceholder')"
            single-line
            hide-details
            class="no-padding"
          ></v-text-field>
          <v-btn class="mr-2 white--text" color="primary" @click.stop="showUserDialog = true" :disabled="loadingData">{{
            $t('customs.ssoUsers.usersTab.createUserBtn')
          }}</v-btn>
          <user-administration-dialog
            v-if="showUserDialog"
            v-model="showUserDialog"
            :user="selectedUser"
            :title="
              selectedUser !== null ? getNameUpperCase(selectedUser) : $t('customs.ssoUsers.usersTab.createUserBtn')
            "
            :read-only="readOnly"
            @showUserAlertEvent="showUserSnackbar($event)"
            @addUserInList="addUserInList($event)"
            @updateUserInList="updateUserInList($event)"
            @deleteUserInList="deleteUserInList($event)"
          ></user-administration-dialog>
        </v-toolbar>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="usersListDump"
        :search="search"
        :footer-props="{
          'items-per-page-text': $t('customs.salesBoard.rowsPerPage')
        }"
        :loading="loadingData"
      >
        <template v-slot:item="{ item }">
          <tr v-if="!isOctipasAdmin(item)">
            <td class="text-center">
              <v-tooltip left v-if="isUserAdmin(item)">
                <template v-slot:activator="{ on }">
                  <v-icon color="green lighten-1" v-on="on">mdi-shield-check</v-icon>
                </template>
                <span>Admin</span>
              </v-tooltip>
              {{ getNameUpperCase(item) }}
            </td>
            <td class="text-center">{{ item.email }}</td>
            <td class="text-center">
              <span v-if="!hasUserStores(item)">{{ $t('customs.ssoUsers.usersTab.noStoresAsigned') }}</span>
              <span v-else v-for="(store, index) in item.data.dataBackoffice.userStores" :key="index">
                <v-chip class="ma-1" outlined color="primary lighten-1" v-if="index < 4">{{ store.name_store }}</v-chip>
                <span v-if="index === 4" class="grey--text text-caption"
                  >(+{{ item.data.dataBackoffice.userStores.length - 4 }}
                  {{ $t('customs.ssoUsers.usersTab.others') }})</span
                >
              </span>
            </td>
            <td class="text-center">
              <template>
                <v-btn text icon @click.stop="editUser(item, true)" :disabled="loadingData">
                  <v-icon small>visibility</v-icon>
                </v-btn>
                <v-btn v-if="!isOctipasAdmin(item)" text icon @click.stop="editUser(item)" :disabled="loadingData">
                  <v-icon small>edit</v-icon>
                </v-btn>
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-if="userSnackbar.display"
      v-model="userSnackbar.display"
      :color="userSnackbar.type"
      multi-line
      bottom
      right
    >
      <v-icon color="white" class="mr-2">{{ userSnackbar.icon }}</v-icon>
      {{ userSnackbar.msg }}
      <v-btn icon @click="userSnackbar.display = false">
        <v-icon color="white">close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import UserAdministrationDialog from '@/components/UsersAdministration/UsersAdministrationDialog/UsersAdmininstrationDialog.vue'
  /*
import GroupAdministrationDialog from '@/components/UsersAdministration/UsersAdministrationDialog/GroupsAdministrationDialog.vue'
import RolesAdministrationDialog from '@/components/UsersAdministration/UsersAdministrationDialog/RolesAdministrationDialog.vue'
*/
  export default {
    name: 'users-table',
    // List of all SSO users, groups and roles get from the parent component + loading boolean
    props: {
      usersListDump: Array,
      loadingData: Boolean
    },
    components: {
      UserAdministrationDialog
      /*
    GroupAdministrationDialog,
    RolesAdministrationDialog
    */
    },
    data() {
      return {
        search: '', // the search input
        headers: [
          {
            text: this.$t('customs.ssoUsers.usersTab.userTable.name'),
            value: 'fullName',
            sortable: true,
            align: 'center'
          },
          {
            text: this.$t('customs.ssoUsers.usersTab.userTable.email'),
            value: 'email',
            sortable: true,
            align: 'center'
          },
          {
            text: this.$t('customs.ssoUsers.usersTab.userTable.store'),
            value: 'data.dataBackoffice.userStores',
            sortable: false,
            align: 'center'
          },
          {
            text: this.$t('customs.ssoUsers.usersTab.userTable.actions'),
            value: 'action',
            sortable: false,
            align: 'center'
          }
        ],
        rowsPerPageItems: ['10', '20', '50', { text: 'All', value: -1 }],
        selectedUser: null, // User to send to the user dialog component
        showUserDialog: false, // Boolean to show the user dialog
        readOnly: false, // Open the dialog on read only mode or not
        userSnackbar: {
          display: false,
          type: '',
          msg: '',
          icon: ''
        }
      }
    },
    methods: {
      /**
       * Open the user dialog, set the user and open it
       * @param {object} user the user to send to the dialog
       * @param {boolean} readOnly is the dialog is read only or the user information can be modified
       */
      editUser(user, readOnly = false) {
        this.selectedUser = user
        this.showUserDialog = true
        this.readOnly = readOnly
      },
      /**
       * Send the user object to the addUserInList method in the parent
       * @param {object} user the user to send to the parent
       */
      addUserInList(user) {
        this.$emit('addUserInList', user)
      },
      /**
       * Send the user object to the updateUserInList method in the parent
       * @param {object} user the user to send to the parent
       */
      updateUserInList(user) {
        this.$emit('updateUserInList', user)
      },
      /**
       * Send the id of the user to the deleteUserInList method in the parent
       * @param {string} id the user id to send to the parent
       */
      deleteUserInList(id) {
        this.$emit('deleteUserInList', id)
      },
      /**
       * Check if the user has the userStores and dataBackoffice in the data properties when retrieving a user from the sso
       * @param user the user that will be checked
       */
      hasUserStores(user) {
        return user?.data?.dataBackoffice?.userStores && user.data.dataBackoffice.userStores.length > 0
      },
      /**
       * Check if user got the backoffice-admin or octipas-admin role
       * @param the user that will be checked
       */
      isUserAdmin(user) {
        if (user.hasOwnProperty('registrations')) {
          for (let index = 0; index < user.registrations.length; index++) {
            if (user.registrations[index].applicationId === process.env.VUE_APP_SSO_APP_ID) {
              if (user.registrations[index].hasOwnProperty('roles')) {
                for (let j = 0; j < user.registrations[index].roles.length; j++) {
                  if (
                    user.registrations[index].roles[j] === 'backoffice-admin' ||
                    user.registrations[index].roles[j] === 'octipas-admin'
                  ) {
                    return true
                  }
                }
              }
            }
          }
        }
        return false
      },
      /**
       * Check if user got the octipas-admin role
       * @param the user that will be checked
       */
      isOctipasAdmin(user) {
        if (user.registrations) {
          let registration = user.registrations.find(reg => reg.applicationId === process.env.VUE_APP_SSO_APP_ID)
          if (registration) {
            return registration?.roles?.includes('octipas-admin')
          }
        }
        return false
      },
      /**
       * Display the name of the user with upper case
       * @param user the user
       */
      getNameUpperCase(user) {
        if (user.firstName && user.lastName) {
          return user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1) + ' ' + user.lastName.toUpperCase()
        } else if (user.firstName) {
          return user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)
        } else if (user.lastName) {
          return user.lastName.toUpperCase()
        } else {
          return user.email
        }
      },
      /**
       * Method to show the snackbar for display a notification
       */
      showUserSnackbar(args) {
        this.userSnackbar.display = args.display
        this.userSnackbar.type = args.type
        this.userSnackbar.msg = args.msg
        this.userSnackbar.icon = args.icon
      }
    },
    watch: {
      showUserDialog() {
        if (!this.showUserDialog) {
          this.selectedUser = null
          this.readOnly = false
        }
      }
    }
  }
</script>

<style scoped>
  .no-padding {
    padding-top: 0px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  #filter-container {
    padding: 0;
  }
  h3 {
    color: dimgray;
  }
</style>
