<template>
  <div id="users-administration">
    <v-container fluid grid-list-xl>
      <v-tabs fixed-tabs grow>
        <v-tab>
          <h2>{{ $t('customs.ssoUsers.titles.titleTabs.usersTab') }}</h2>
        </v-tab>
        <!--
        <v-tab>
          <h2>{{$t('customs.ssoUsers.titles.titleTabs.groupsTab')}}</h2>
        </v-tab>
        <v-tab>
          <h2>{{$t('customs.ssoUsers.titles.titleTabs.rolesTab')}}</h2>
        </v-tab>
        -->
        <v-tab-item>
          <v-card flat>
            <users-table
              v-bind:users-list-dump="userList"
              :loadingData="loadingData"
              @addUserInList="addUserInList($event)"
              @updateUserInList="updateUserInList($event)"
              @deleteUserInList="deleteUserInList($event)"
            ></users-table>
          </v-card>
        </v-tab-item>
        <!--
        <v-tab-item>
          <v-card flat>
            <groups-table v-bind:groups-list-dump="groupsList" :loadingData="loadingData" @updateGroupsList="updateGroupsList($event)" @deleteGroupInList="deleteGroupInList($event)"></groups-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <roles-table v-bind:roles-list-dump="rolesList" :loadingData="loadingData" @updateRolesList="updateRolesList($event)" @deleteRoleInList="deleteRoleInList($event)"></roles-table>
          </v-card>
        </v-tab-item>
      -->
      </v-tabs>
    </v-container>
    <v-snackbar v-if="snackbar.display" v-model="snackbar.display" :color="snackbar.type" multi-line bottom right>
      <v-icon color="white" class="mr-2">{{ snackbar.icon }}</v-icon>
      {{ snackbar.msg }}
      <v-btn icon @click="snackbar.display = false">
        <v-icon color="white">close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import { usersService } from '../../services/sso-users.service.js'
  import UsersTable from '../../components/UsersAdministration/UsersTable.vue'
  /*
import GroupsTable from '@/components/UsersAdministration/GroupsTable.vue'
import RolesTable from '@/components/UsersAdministration/RolesTable.vue'
*/
  export default {
    name: 'users-administration',
    data() {
      return {
        userList: [], // All the users retrieve from the SSO
        loadingData: true, // Boolean set to false when all the data has been loaded
        snackbar: {
          display: false,
          type: '',
          msg: '',
          icon: ''
        }
      }
    },
    components: {
      UsersTable
    },
    mounted() {
      if (this.$store.state.isAdmin) {
        this.getUserList()
      } else {
        this.$router.push({ name: 'Error', params: { code: 403, message: 'Not authorized' } })
        console.log("L'utilisateur n'a pas les privilèges pour être ici.")
      }
    },
    methods: {
      getUserList() {
        this.loadingData = true
        let self = this
        let timeout = setTimeout(function () {
          self.showSnackbar(
            self,
            'showUserAlertEvent',
            'error',
            self.$t('customs.ssoUsers.globalErrors.serverNotResponding'),
            'error'
          )
        }, 15000)
        // TODO: get the user groupId
        usersService.getUserList(this.$store.state.currentGroup.id).then(response => {
          clearTimeout(timeout)
          if (response.hasOwnProperty('users')) {
            this.userList = []
            for (let user of response.users) {
              if (!this.isOctipasAdmin(user)) {
                this.userList.push(user)
              }
            }
            for (let user of this.userList) {
              let userModified = false
              if (user?.data?.dataBackoffice?.userStores) {
                user.data.dataBackoffice.userStores.sort((a, b) => {
                  if (a.name_store > b.name_store) {
                    return 1
                  }
                  if (b.name_store > a.name_store) {
                    return -1
                  }
                })
                let storesIndexToDelete = []
                user.data.dataBackoffice.userStores.forEach((userStore, index) => {
                  let storeFound = false
                  if (this.$store.state.allStores && this.$store.state.allStores.length > 0) {
                    for (let store of this.$store.state.allStores) {
                      if (userStore.external_id === store.external_id) {
                        storeFound = true
                      }
                    }
                    if (!storeFound) {
                      storesIndexToDelete.push(index)
                    }
                  }
                })
                if (storesIndexToDelete.length > 0) {
                  for (let index of storesIndexToDelete) {
                    user.data.dataBackoffice.userStores.splice(index, 1)
                  }
                  userModified = true
                }
                if (userModified) {
                  usersService.updateUser({ registration: user.registrations[0], user: user })
                  this.showSnackbar(
                    'showUserAlertEvent',
                    'warning',
                    this.$t('customs.ssoUsers.globalErrors.userModifiedUnknownStore'),
                    'warning'
                  )
                }
              }
            }
          } else {
            this.showSnackbar(
              'showUserAlertEvent',
              'error',
              this.$t('customs.ssoUsers.globalErrors.serverNotResponding'),
              'error'
            )
          }
          this.loadingData = false
        })
      },
      addUserInList(user) {
        usersService.getUserById(user.user.id).then(response => {
          if (response.hasOwnProperty('user')) {
            this.userList.push(response.user)
          } else {
            this.showSnackbar(
              'showUserAlertEvent',
              'error',
              this.$t('customs.ssoUsers.globalErrors.serverNotResponding'),
              'error'
            )
          }
        })
      },
      updateUserInList(user) {
        usersService.getUserById(user.id).then(response => {
          if (response.hasOwnProperty('user')) {
            for (let i = 0; i < this.userList.length; i++) {
              if (this.userList[i].id === user.id) {
                this.userList.splice(i, 1)
                this.userList.push(user)
                break
              }
            }
            if (this.$store.state.currentUser.id === response.user.id) {
              this.$store.dispatch('setCurrentUser', response.user)
            }
          } else {
            this.showSnackbar(
              'showUserAlertEvent',
              'error',
              this.$t('customs.ssoUsers.globalErrors.serverNotResponding'),
              'error'
            )
          }
        })
      },
      deleteUserInList() {
        this.getUserList()
      },
      showSnackbar(display, type, msg, icon) {
        this.snackbar.display = display
        this.snackbar.type = type
        this.snackbar.msg = msg
        this.snackbar.icon = icon
      },
      isOctipasAdmin(user) {
        if (user.hasOwnProperty('registrations')) {
          for (let index = 0; index < user.registrations.length; index++) {
            if (user.registrations[index].applicationId === process.env.VUE_APP_SSO_APP_ID) {
              if (user.registrations[index].hasOwnProperty('roles')) {
                for (let j = 0; j < user.registrations[index].roles.length; j++) {
                  if (user.registrations[index].roles[j] === 'octipas-admin') {
                    return true
                  }
                }
              }
            }
          }
        }
        return false
      }
    }
  }
</script>

<style scoped>
  h2 {
    color: dimgray;
  }
</style>
