import { API } from './core/api-core.service'

export const valorizationService = {
  /**
   * @param params
   * @returns {Promise<*>}
   */
  addRule(params) {
    let body = {
      merchantId: params.merchantId,
      name: params.name,
      status: params.status,
      priority: params.priority,
      couponCode: params.couponCode,
      maxUseByCustomer: params.maxUseByCustomer,
      fromDate: params.fromDate,
      toDate: params.toDate,
      extraRules: params.extraRules,
      productsApplyRules: params.productsApplyRules,
      productDiscountPercent: params.productDiscountPercent,
      productFixDiscount: params.productFixDiscount,
      maxProductDiscountCount: params.maxProductDiscountCount,
      basketFixDiscount: params.basketFixDiscount,
      basketPercentDiscount: params.basketPercentDiscount,
      maxDiscount: params.maxDiscount,
      freeProduct: params.freeProduct,
      discount: params.discount,
      applyType: params.applyType,
      applyRules: params.applyRules
    }
    return API.request('POST', 'sso/valorization', null, body)
  },

  /**
   * @param params
   * @returns {Promise<*>}
   */
  editRule(params) {
    let body = {
      merchantId: params.merchantId,
      name: params.name,
      status: params.status,
      priority: params.priority,
      couponCode: params.couponCode,
      maxUseByCustomer: params.maxUseByCustomer,
      fromDate: params.fromDate,
      toDate: params.toDate,
      extraRules: params.extraRules,
      productsApplyRules: params.productsApplyRules,
      productDiscountPercent: params.productDiscountPercent,
      productFixDiscount: params.productFixDiscount,
      maxProductDiscountCount: params.maxProductDiscountCount,
      basketFixDiscount: params.basketFixDiscount,
      basketPercentDiscount: params.basketPercentDiscount,
      maxDiscount: params.maxDiscount,
      freeProduct: params.freeProduct,
      discount: params.discount,
      applyType: params.applyType,
      applyRules: params.applyRules
    }
    return API.request('PUT', `sso/valorization/${params.id}`, null, body)
  },

  /**
   * @param ruleId
   * @returns {Promise<*>}
   */
  removeRule(ruleId) {
    return API.request('DELETE', `sso/valorization/${ruleId}`)
  },

  /**
   * @param merchantId
   * @returns {Promise<*>}
   */
  getRules(merchantId) {
    return API.request('GET', 'sso/valorization', { merchantId })
  },

  /**
   * @param ruleId
   * @param merchantId
   * @returns {Promise<*>}
   */
  getRule(ruleId, merchantId) {
    return API.request('GET', `sso/valorization/${ruleId}`, { merchantId })
  }
}
